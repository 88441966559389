import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase-config/firebase";
import readXlsxFile from "read-excel-file";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import Footer from "./Footer";
import NavBar from "./NavBar";

const Members = () => {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [newMember, setNewMember] = useState("");
  const [deleteMember, setDeleteMember] = useState("");
  const [editMember, setEditMember] = useState("");
  const [newName, setNewName] = useState("");
  const [oldName, setOldName] = useState("");
  const [newMemberList, setNewMemberList] = useState([]);
  const [internet, setInternet] = useState(true);
  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
  const [openDeleteMemberDialog, setOpenDeleteMemberDialog] = useState(false);
  const [openEditMemberDialog, setOpenEditMemberDialog] = useState(false);
  const [openUploadMemberDialog, setOpenUploadMemberDialog] = useState(false);

  useEffect(() => {
    if (sessionStorage.length === 0) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getDocs(
      collection(db, `profile/${sessionStorage.getItem("mail")}/members`)
    ).then((members) => {
      if (members.docs.length === 0) {
        setFlag(true);
      }
    });
  });

  useEffect(() => {
    const collectionRef = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/members`
    );
    // getDocs(collectionRef).then((members) => {
    //   console.log(members.docs.length);
    //   if (members.docs.length === 0) {
    //     setFlag(true);
    //   } else {
    //     let arr = [];
    //     members.forEach((member) => {
    //       arr.push(member.data().member_name);
    //     });
    //     setMembersList(arr);
    //     console.log(arr);
    //   }
    // });
    onSnapshot(collectionRef, (docs) => {
      let arr = [];
      let full_data = [];
      docs.forEach((doc) => {
        arr.push(doc.data().member_name);
        full_data.push(doc.data());
      });
      sessionStorage.setItem("membersList", JSON.stringify(full_data));
      setMembersList(arr);
    });
  }, [db]);

  const handleChange = (e) => {
    const files = e.target.files;
    if (files.length === 0) return;
    else {
      const file = files[0];
      let arr = [];
      readXlsxFile(file).then((rows) => {
        rows.forEach((cell) => {
          cell.forEach((member) => {
            arr.push(member);
          });
        });
        setMembersList(arr);
      });
    }
  };

  const handleNewMembersChange = (e) => {
    const files = e.target.files;
    if (files.length === 0) return;
    else {
      const file = files[0];
      let arr = [];
      readXlsxFile(file).then((rows) => {
        rows.forEach((cell) => {
          cell.forEach((member) => {
            arr.push(member);
          });
        });
        setNewMemberList(arr);
      });
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    var increment = 0;
    membersList.forEach((member, i) => {
      const docRef = doc(
        db,
        `profile/${sessionStorage.getItem("mail")}/members/${member}`
      );
      setDoc(docRef, {
        member_name: member,
      })
        .then(() => {
          increment = increment + 1;
        })
        .finally(() => {
          if (increment === membersList.length - 1) {
            toast.success("All members uploaded", {
              autoClose: 1000,
            });
            window.location.reload();
          }
        });
    });
  };

  const handleUploadNew = () => {
    var increment = 0;
    newMemberList.forEach((member, i) => {
      if (membersList.indexOf(member) == -1) {
        const docRef = doc(
          db,
          `profile/${sessionStorage.getItem("mail")}/members/${member}`
        );
        setDoc(docRef, {
          member_name: member,
        })
          .then(() => {
            increment = increment + 1;
          })
          .finally(() => {
            if (increment === newMemberList.length - 1) {
              setOpenUploadMemberDialog(false);
              toast.success("All member uploaded");
              membersList.concat(newMemberList);
            }
          });
      }
    });
  };

  const handleAdd = () => {
    if (membersList.indexOf(newMember) === -1) {
      const docRef = doc(
        db,
        `profile/${sessionStorage.getItem("mail")}/members/${newMember}`
      );
      setDoc(docRef, {
        member_name: newMember,
      })
        .then(() => {
          setOpenAddMemberDialog(false);
          toast.success("Member Added Successfully!", {
            autoClose: 1000
          });
        })
        .catch((err) => console.log(err));
    } else {
      toast.warn("Member exists in the list!!", {
        autoClose: 1000,
      });
    }
  };
  const handleDelete = async (deleteId) => {
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem("mail")}/members/${deleteId}`
    );
    await deleteDoc(docRef)
      .then(() => {
        setOpenDeleteMemberDialog(false);
        toast.success("Member deleted successfully");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleEdit = async (oldName, newName) => {
    const docRef1 = doc(
      db,
      `profile/${sessionStorage.getItem("mail")}/members/${newName}`
    );
    await setDoc(docRef1, {
      member_name: newName,
    })
      .then(() => {
        deleteDoc(
          doc(
            db,
            `profile/${sessionStorage.getItem("mail")}/members/${oldName}`
          )
        );
      })
      .then(() => {
        setOpenEditMemberDialog(false);
        toast.success("Member name updated!!");
      });
  };

  setInterval(() => {
    if (navigator.onLine === true) {
      setInternet(true);
    } else {
      setInternet(false);
    }
  }, 3000);

  return (
    <>
      <NavBar />
      <section id="members" className="row">
        {flag === true ? (
          <div className="col-10 col-lg-10 mx-auto">
            <h5 className="text-center">No members found!!</h5>
            <form
              onSubmit={handleUpload}
              className="form-card border p-3 col-10 col-sm-8 col-md-6 col-lg-5 mx-auto"
            >
              <legend htmlFor="uploadExcel" className="border-bottom">
                Upload List of Members
              </legend>
              <br />
              <input
                type="file"
                name="uploadExcel"
                id="uploadExcel"
                className="form-control"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleChange}
              />
              <p className="align-items-left">
                You can upload only Excel Spreadsheet
              </p>
              <br />
              <button className="btn btn-orange">
                <i className="bi bi-cloud-upload"></i>&nbsp;Upload
              </button>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vcms-portal-751c0.appspot.com/o/samplelist.xlsx?alt=media&token=2ee2deac-e665-4f37-8495-2a5ef7fe442f"
                download
                className="btn btn-orange mt-1"
              >
                Download Sample File
              </a>
            </form>
            {membersList.length > 0 ? (
              <>
                <h3>The members to be uploaded are:</h3>
                <ol type={"1"}>
                  {membersList.map((member) => (
                    <li>{member}</li>
                  ))}
                </ol>
              </>
            ) : null}
          </div>
        ) : (
          <div className="col-10 col-lg-6 mx-auto text-center">
            <legend>Members</legend>
            <table className="table table-responsive-sm table-hover table-striped">
              <thead>
                <tr>
                  <th className="pb-3">No.</th>
                  <th className="pb-3">Member Name</th>
                  <th>
                    <div className="btn-group">
                      <button
                        // data-bs-toggle="modal"
                        // data-bs-target="#add_member_modal"
                        onClick={() => {
                          setOpenAddMemberDialog(true);
                        }}
                        type="button"
                        className="btn btn-outline-orange"
                      >
                        Add
                      </button>
                      <button
                        onClick={() => setOpenUploadMemberDialog(true)}
                        // data-bs-toggle="modal"
                        // data-bs-target="#upload_members_modal"
                        type="button"
                        className="btn btn-outline-orange"
                      >
                        <i className="bi bi-cloud-upload"></i>
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {membersList.map((member, i) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    <td>{member}</td>
                    <td>
                      <div className="btn-group" role={"group"}>
                        <button
                          type="button"
                          className="btn btn-outline-orange"
                          onClick={() => {
                            setOldName(member);
                            setEditMember(member);
                            setOpenEditMemberDialog(true);
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#edit_member_modal"
                        >
                          <i className="bi bi-pencil"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-orange"
                          onClick={() => {
                            setDeleteMember(member);
                            setOpenDeleteMemberDialog(true);
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#del_member_modal"
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </section>
      <Footer />
      {/* Add Member Modal */}
      {openAddMemberDialog && (
        <Dialog
          open={openAddMemberDialog}
          onClose={() => setOpenAddMemberDialog(false)}
        >
          <div className="modal-content">
            <div className="modal-body">
              <p>Add Member</p>
              <input
                type="text"
                onChange={(e) => {
                  setNewMember(e.target.value);
                }}
                placeholder="Enter member name here..."
                className="form-control"
              />
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => setOpenAddMemberDialog(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={handleAdd}>
                Add
              </button>
              <p>
                If Member is already present in the list then added member will
                be ignored
              </p>
            </div>
          </div>
        </Dialog>
      )}

      {/* DELETE MODAL */}
      {openDeleteMemberDialog && (
        <Dialog
          open={openDeleteMemberDialog}
          onClose={() => setOpenDeleteMemberDialog(false)}
        >
          <div className="modal-content">
            <div className="modal-body">
              <p>Are you sure you want to delete {deleteMember}</p>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => setOpenDeleteMemberDialog(false)}
                className="btn btn-danger"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDelete(deleteMember);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Dialog>
      )}

      {/* EDIT MODAL */}
      {openEditMemberDialog && (
        <Dialog
          open={openEditMemberDialog}
          onClose={() => setOpenEditMemberDialog(false)}
        >
          <div className="modal-content">
            <div className="modal-body">
              <p>Edit Member</p>
              <input
                type="text"
                onChange={(e) => {
                  setEditMember(e.target.value);
                }}
                value={editMember}
                placeholder="Enter new name here..."
                className="form-control"
              />
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => setOpenEditMemberDialog(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleEdit(oldName, editMember);
                }}
              >
                Update
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {/* </div>
      </div> */}

      {/* UPLOAD MODAL */}
      {openUploadMemberDialog && (
        <Dialog
          open={openUploadMemberDialog}
          onClose={() => setOpenUploadMemberDialog(false)}
        >
          <div className="modal-content">
            <div className="modal-body">
              <p>Upload List of Members</p>
              <input
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleNewMembersChange}
                className="form-control"
              />
              <p>You can only Excel(.xlsx) extension files</p>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => setOpenUploadMemberDialog(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={handleUploadNew}>
                Upload
              </button>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/vcms-portal-751c0.appspot.com/o/samplelist.xlsx?alt=media&token=2ee2deac-e665-4f37-8495-2a5ef7fe442f"
                download
                className="btn btn-primary"
              >
                <i className="bi bi-cloud-download"></i> Download Sample File
              </a>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Members;
