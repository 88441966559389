import { Password } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SpinnerCircular } from "spinners-react";
import { auth, db } from "../firebase-config/firebase";
import Logo from "../favicon-32x32.png";
import Footer from "./Footer";

const LeaderDashboard = () => {
  const navigate = useNavigate();
  const [membersLength, setMembersLength] = useState();
  const [expenseHeadsLength, setExpenseHeadsLength] = useState();
  const [monthsLength, setMonthsLength] = useState();
  const [defaulterCount, setDefaulterCount] = useState();
  const [loading, setLoading] = useState(false);
  const [internet, setInternet] = useState(true);
  const [speed, setSpeed] = useState();
  const [user, setUser] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [chp, setChp] = useState("");

  const authListener = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        console.log(user.email);
      }
    });
  };

  useEffect(() => {
    authListener();
  }, []);

  useEffect(() => {
    if (sessionStorage.length === 0) {
      navigate("/");
    }
    getDoc(doc(db, `profile/${sessionStorage.getItem("mail")}`)).then((doc) => {
      setChp(doc.data().chapter_name);
    })
  }, []);

  useEffect(() => {
    setLoading(true);
    const colRef1 = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/members`
    );
    onSnapshot(colRef1, (snap) => {
      if (snap.docs.length > 0) {
        setMembersLength(snap.docs.length);
      } else {
        setMembersLength(0);
      }
    });
    const colRef2 = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/expenseheads`
    );
    onSnapshot(colRef2, (snap) => {
      if (snap.docs.length > 0) {
        setExpenseHeadsLength(snap.docs.length);
      } else {
        setExpenseHeadsLength(0);
      }
    });
    const colRef3 = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/months`
    );
    onSnapshot(colRef3, (snap) => {
      if (snap.docs.length > 0) {
        setMonthsLength(snap.docs.length);
      } else {
        setMonthsLength(0);
      }
    });

    // onSnapshot(
    //   collection(db, `profile/${sessionStorage.getItem("mail")}/months`),
    //   (snap) => {
    //     let i = 0;
    //     snap.forEach((item) => {
    //       i += item.data().defaulters;
    //     });
    //     setDefaulterCount(i);
    //   }
    // );
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/months`
    );
    onSnapshot(colRef, (snap) => {
      let arr = [];
      let a1 = 0;
      let d1 = 0;
      if (snap.docs.length > 0) {
        snap.docs.forEach((doc) => {
          const collectionRef = collection(
            db,
            `profile/${sessionStorage.getItem("mail")}/months/${doc.id}/members`
          );
          let actualCount = 0;
          let defaulterCount = 0;
          const colRef = collection(
            db,
            `profile/${sessionStorage.getItem("mail")}/months/${doc.id}/dates`
          );
          let e1 = 0;
          getDocs(colRef).finally(() => {
            getDocs(collectionRef).then((members) => {
              members.forEach((doc) => {
                if (
                  doc.data().paid_coll < doc.data().amount ||
                  doc.data().paid_coll === undefined ||
                  doc.data().paid_penalty < doc.data().expected_penalty
                ) {
                  defaulterCount += 1;
                }
              });
              arr.push({
                ...doc.data(),
                actualCount: actualCount,
                expenses: e1,
                defaulterCount: defaulterCount,
              });
              a1 = actualCount;
              d1 = defaulterCount;

              if (arr.length === snap.docs.length) {
                let sum = 0;
                arr.forEach((item) => {
                  sum += item.defaulterCount;
                });
                setDefaulterCount(sum);
                setLoading(false);
              }
            });
          });
        });
      } else {
        setDefaulterCount(0);
        setLoading(false);
      }
    });
  }, [db]);

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate("/");
    });
  };
  const handleOpenProfile = () => {
    getDoc(doc(db, `profile/${sessionStorage.getItem("mail")}`)).then((doc) => {
      if (doc.data().business_forum_name === "") {
        navigate(`/create-profile/${sessionStorage.getItem("mail")}`);
      } else {
        navigate(`/edit-profile/${sessionStorage.getItem("mail")}`);
      }
    });
  };

  setInterval(() => {
    if (navigator.onLine === true) {
      setInternet(true);
    } else {
      setInternet(false);
    }
  }, 3000);

  useEffect(() => {
    var file_size = 127,
      start = performance.now(),
      end = 0,
      time = 0,
      xmlhttp = new XMLHttpRequest(),
      result = 0;
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        end = performance.now();
        time = (end - start) / 1000;
        result = ((file_size / time / 1000) * 8).toFixed(2);
        setSpeed(result);
      }
    };
    xmlhttp.open(
      "GET",
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg",
      true
    );
    xmlhttp.send();
  }, [internet]);

  const handleRegister = (e) => {
    e.preventDefault();
    if (email !== "" && password !== "") {
      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          console.log("Account Created");
          const docRef = doc(db, `profile/${email}`);
          setDoc(docRef, {
            role: "leader",
            business_forum_name: "",
          }).then(() => {
            setEmail("");
            setPassword("");
            toast.success("Account Created!!");
            signOut(auth).then(() => {
              console.log("Logged out");
              navigate("/login");
            });
          });
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              toast.error("Email already in use");
              break;
            case "auth/invalid-email":
              toast.error("Invalid Email");
              break;
            case "auth/weak-password":
              toast.error("Weak Password. Please set a strong password");
              break;
            case "auth/wrong-password":
              toast.error("Wrong Password");
              break;
            case "auth/user-not-found":
              toast.error("User Not Found");
              break;
            case "auth/user-disabled":
              toast.error("User Disabled by Admin");
              break;
            default:
              console.log("");
          }
        });
    } else {
      toast.error("Please fill out all fields!!");
    }
  };

  const generatePassword = () => {
    // Set the password length
    const length = 8;

    // Set the allowed characters for the password
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=";

    let password = "";

    // Generate the password
    for (let i = 0; i < length; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setPassword(password);
  };
  return (
    <>
      <div className="nav-container">
        <nav className="navbar navbar-expand-md navbar-custom">
          <div className="container">
            <div
              className="navbar-brand"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <img src={Logo} alt="logo" /> Venue Fair Bahi Khata
            </div>
            <div className="ov">
              <span className="user-tag">
                {chp}
              </span>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar_content"
              aria-controls="navbar_content"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="bi bi-list"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar_content">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <div className="nav-link">
                    <Tooltip
                      title={
                        internet === true
                          ? `You are connected to the internet approx. ${speed} mbps`
                          : "Please connect to the internet"
                      }
                    >
                      <i
                        style={{ fontSize: "20px" }}
                        className={
                          internet === true ? "bi bi-wifi" : "bi bi-wifi-off"
                        }
                      ></i>
                    </Tooltip>
                  </div>
                </li>
                <li className="nav-item mt-1">
                  <a
                    onClick={handleLogout}
                    style={{ cursor: "pointer" }}
                    className="nav-link"
                  >
                    <i className="bi bi-box-arrow-left"></i> Logout
                  </a>
                </li>
                <li className="nav-item mt-1">
                  <a
                    href="https://fairshare.tech/contact-details-of-software-company-pune/"
                    target="_blank"
                    className="nav-link"
                  >
                    <i className="bi bi-send-fill"></i> Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {user?.email === "info@fairshare.tech" ? (
        <>
          <section className="row" id="superadmin">
            <div className="col-10 col-lg-8 mx-auto">
              <div className="row">
                <div className="form-card col-10 col-sm-8 col-md-6 col-lg-5 mx-auto">
                  <form onSubmit={handleRegister}>
                    <legend className="border-bottom">
                      Register A New Leader
                    </legend>
                    <br />
                    <br />
                    <div className="form-group">
                      <label htmlFor="registeremail">Enter Email Address</label>
                      <input
                        className="form-control"
                        type="email"
                        name="Email"
                        value={email}
                        id="registeremail"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <br />
                      <label htmlFor="registerpassword">Enter Password</label>
                      <div className="d-flex">
                        <input
                          type="text"
                          name="Password"
                          id="registerpassword"
                          autoComplete=""
                          value={password}
                          required
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                        />
                        <Tooltip title="Generate New Password">
                          <Button
                            onClick={() => generatePassword()}
                            className="p-2"
                            style={{ cursor: "pointer" }}
                          >
                            <Password />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                    <br />
                    <button type="submit" className="btn btn-orange mt-4">
                      Create Account
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section id="dashboard-content" className="row">
            <div className="col-10 col-lg-10 mx-auto">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                  <button
                    onClick={() =>
                      navigate(
                        `/dashboard/defaulters/${sessionStorage.getItem(
                          "mail"
                        )}`
                      )
                    }
                    className="btn btn-outline-orange btn-lg"
                  >
                    Defaulters{" "}
                    {loading === false ? (
                      <span className="badge rounded-pill bg-orange">
                        {defaulterCount}
                      </span>
                    ) : (
                      <div className="badge rounded-pill bg-orange">
                        {/* <RotatingLines
                      strokeColor="white"
                      visible
                      strokeWidth="4"
                      width="15"
                    /> */}
                        <SpinnerCircular
                          size={20}
                          thickness={150}
                          speed={130}
                          color="rgba(255,255,255,1)"
                          secondaryColor="rgba(23, 70, 162, 1)"
                        />
                      </div>
                    )}
                  </button>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <button
                    className="btn btn-outline-orange btn-lg"
                    onClick={() =>
                      navigate(
                        `/dashboard/members/${sessionStorage.getItem("mail")}`
                      )
                    }
                  >
                    Maintain Members{" "}
                    {loading === false ? (
                      <span className="badge rounded-pill bg-orange">
                        {membersLength}
                      </span>
                    ) : (
                      <div className="badge rounded-pill bg-orange">
                        {/* <RotatingLines
                      strokeColor="white"
                      visible
                      strokeWidth="4"
                      width="15"
                    /> */}
                        <SpinnerCircular
                          size={20}
                          thickness={150}
                          speed={130}
                          color="rgba(255,255,255,1)"
                          secondaryColor="rgba(23, 70, 162, 1)"
                        />
                      </div>
                    )}
                  </button>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <button
                    onClick={() =>
                      navigate(
                        `/dashboard/months/${sessionStorage.getItem("mail")}`
                      )
                    }
                    className="btn btn-outline-orange btn-lg"
                  >
                    <i className="bi bi-calendar"></i> Months{" "}
                    {loading === false ? (
                      <span className="badge rounded-pill bg-orange">
                        {monthsLength}
                      </span>
                    ) : (
                      <div className="bg-orange badge rounded-pill">
                        {/* <RotatingLines
                      strokeColor="white"
                      visible
                      strokeWidth="4"
                      width="15"
                    /> */}
                        <SpinnerCircular
                          size={20}
                          thickness={150}
                          speed={130}
                          color="rgba(255,255,255,1)"
                          secondaryColor="rgba(23, 70, 162, 1)"
                        />
                      </div>
                    )}
                  </button>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <button
                    className="btn btn-outline-orange btn-lg"
                    onClick={handleOpenProfile}
                  >
                    <i className="bi bi-person-circle"></i> Profile
                  </button>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <button
                    className="btn btn-outline-orange btn-lg"
                    onClick={() => {
                      navigate(
                        `/dashboard/expenseheads/${sessionStorage.getItem(
                          "mail"
                        )}`
                      );
                    }}
                  >
                    Expense Heads{" "}
                    {!loading ? (
                      <span className="badge rounded-pill bg-orange">
                        {expenseHeadsLength}
                      </span>
                    ) : (
                      <div className="bg-orange badge rounded-pill">
                        {/* <RotatingLines
                      strokeColor="white"
                      visible
                      strokeWidth="4"
                      width="15"
                    /> */}
                        <SpinnerCircular
                          size={20}
                          thickness={150}
                          speed={130}
                          color="rgba(255,255,255,1)"
                          secondaryColor="rgba(23, 70, 162, 1)"
                        />
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      <Footer />
    </>
  );
};

export default LeaderDashboard;
