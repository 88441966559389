import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase-config/firebase";
import "../style.css";
import { toast } from "react-toastify";
import NavBar from "./NavBar";
import Footer from "./Footer";

const HomePage = () => {
  const history = useNavigate();
  const authListener = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        let date = new Date();
        let lastSignin = new Date(user.metadata.lastSignInTime);
        let diff = date.getTime() - lastSignin.getTime();
        let dateDiff = diff / (1000 * 60 * 60 * 24);
        if (dateDiff <= 31) {
          sessionStorage.setItem("mail", user.email);
          localStorage.setItem("loggedin", true);
          if (document.referrer !== "") {
            toast.info(
              "Please wait while we redirect you to" +
                localStorage.getItem("ourl")
            );
            window.location.href = localStorage.getItem("ourl");
          } else {
            history(`/leader/dashboard/${user.email}`);
          }
        } else {
          signOut(auth).then(() => {
            history("/");
          });
        }
      }
    });
  };

  useEffect(() => {
    authListener();
  }, []);
  return (
    <>
      <NavBar />
      <section id="hero">
        <h1>Venue Fair Bahi Khata</h1>
        <div className="container">
          <div className="row mb-4">
            <div className="col"></div>
            <div className="col-12 col-md-4 col-lg-3">
              <button
                className="btn btn-outline-orange btn-lg"
                onClick={() => history("/login")}
              >
                <span>
                  <i className="bi bi-box-arrow-in-right"></i> Login as Leader
                </span>
              </button>
            </div>
            <div className="col"></div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-10 col-lg-7 mx-auto">
            <p>Description</p>
          </div>
        </div>
        <small className="text-muted mt-4">
          Note: This service is simply a deterrent against unfair practices and
          not a replacement for legal procedures such as notarization.
        </small>
      </section>
      <Footer />
    </>
  );
};

export default HomePage;
