import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./Components/HomePage";
import LeaderDashboard from "./Components/LeaderDashboard";
import CreateProfile from "./Components/CreateProfile";
import EditProfile from "./Components/EditProfile";
import Login from "./Components/Login";
import ResetPassword from "./Components/ResetPassword";
import Members from "./Components/Members";
import ExpenseHeads from "./Components/ExpenseHeads";
import Months from "./Components/Months";
import Dates from "./Components/Dates";
import Date from "./Components/Date";
import Month from "./Components/Month";
import Expense from "./Components/Expense";
import Defaulters from "./Components/Defaulters";
import GenerateExcelData from "./Components/GenerateExcelData";
import Visitors from "./Components/Visitors";
import OtherIncomes from "./Components/OtherIncomes";
import SuperAdmin from "./Components/SuperAdmin";

const App = () => {

  return (
    <div className="App">
      <Router>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          limit={5}
        />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/leader/dashboard/:id" element={<LeaderDashboard />} />
          <Route path="/create-profile/:id" element={<CreateProfile />} />
          <Route path="/edit-profile/:id" element={<EditProfile />} />
          <Route path="/dashboard/members/*" element={<Members />} />
          <Route path="/dashboard/expenseheads/*" element={<ExpenseHeads />} />
          <Route path="/dashboard/months/*" element={<Months />} />
          <Route path="/dashboard/months/month" element={<Month />} />
          <Route path="/dashboard/months/dates/*" element={<Dates />} />
          <Route path="/dashboard/months/date" element={<Date />} />
          <Route path="/dashboard/months/date/expense" element={<Expense />} />
          <Route path="/dashboard/defaulters/*" element={<Defaulters />} />
          <Route path="/generateData" element={<GenerateExcelData />} />
          <Route path="/dashboard/visitors/*" element={<Visitors />} />
          <Route path="/dashboard/incomes" element={<OtherIncomes />} />
          <Route path="/superadmin" element={<SuperAdmin />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
