import { Dialog } from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  // where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { db } from "../firebase-config/firebase";
import Footer from "./Footer";
import NavBar from "./NavBar";

const Defaulters = () => {
  const [monthsList, setMonthsList] = useState([]);
  const [month, setMonth] = useState("none");
  const [memberName, setMemberName] = useState("none");
  const [defList, setDefList] = useState([]);
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState();
  const [datesLength, setDatesLength] = useState();
  const [memberDateData, setMemberDateData] = useState([]);
  const [loadingMonths, setLoadingMonths] = useState(false);
  const [loadingDefaulters, setLoadingDefaulters] = useState(false);

  useEffect(() => {
    if (window.location.pathname.split("/")[4] === undefined) {
      setMonth("none");
    } else {
      setMonth(window.location.pathname.split("/")[4].replace("%20", " "));
    }
  }, []);
  useEffect(() => {
    setLoadingMonths(true);
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/months`
    );
    const q = query(colRef, orderBy("timestamp", "desc"));
    onSnapshot(q, (snap) => {
      let arr = [];
      if (snap.docs.length === 0) {
        setLoadingMonths(false);
      } else {
        snap.forEach((doc) => {
          // arr.push(docs.data());
          const collectionRef = collection(
            db,
            `profile/${sessionStorage.getItem("mail")}/months/${doc.id}/members`
          );
          let defaulterCount = 0;
          getDocs(collectionRef).then((members) => {
            members.forEach((doc) => {
              if (
                doc.data().paid_coll < doc.data().amount ||
                doc.data().paid_coll === undefined ||
                doc.data().paid_penalty < doc.data().expected_penalty
              ) {
                defaulterCount += 1;
              }
            });
            arr.push({
              ...doc.data(),
              defaulterCount: defaulterCount,
            });

            if (arr.length === snap.docs.length) {
              setMonthsList(arr);
              setLoadingMonths(false);
            }
          });
        });
      }
    });
  }, [db]);

  useEffect(() => {
    setLoadingDefaulters(true);
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/months/${month}/members/`
    );
    onSnapshot(colRef, (snap) => {
      let arr = [];
      snap.forEach((docs) => {
        if (
          docs.data().paid_coll === undefined ||
          docs.data().paid_coll < docs.data().amount ||
          docs.data().paid_penalty < docs.data().expected_penalty
        ) {
          arr.push(docs.data());
        }
      });
      setDefList(arr);
    });
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem("mail")}/months/${month}`
    );
    getDoc(docRef).then((d) => {
      setAmount(d.data().amount);
    });
    const docRef1 = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/months/${month}/dates`
    );
    onSnapshot(docRef1, (snap) => {
      setDatesLength(snap.docs.length);
      setLoadingDefaulters(false);
    });
  }, [month]);

  const showDates = (month, member) => {
    let i = 0;
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/months/${month}/dates/`
    );
    getDocs(colRef).then((dates) => {
      let arr = [];
      // let vArr = [];
      dates.forEach((date) => {
        const docRef = doc(
          db,
          `profile/${sessionStorage.getItem("mail")}/months/${month}/dates/${
            date.id
          }/members/${member}`
        );
        // const collectionRef = collection(
        //   db,
        //   `profile/${sessionStorage.getItem("mail")}/months/${month}/dates/${
        //     date.id
        //   }/members`
        // );
        // const q = query(collectionRef, where("member", "==", member));
        // getDocs(q).then((visitors) => {
        //   visitors.forEach((visitor) => {
        //     console.log(visitor.data(), date.id);
        //     if (visitor.data().pending_coll > 0) {
        //       vArr.push({ ...visitor.data(), date: date.id });
        //     }
        //   });
        // });
        getDoc(docRef)
          .then((d) => {
            arr.push({ ...d.data(), date: date.id });
            i += 1;
          })
          .finally(() => {
            if (i === dates.docs.length) {
              setMemberDateData(arr);
              // setVisitorsList(vArr);
            }
          })
          .finally(() => {
            setOpen(true);
          });
      });
    });
  };


  return (
    <>
      <NavBar />
      <section id="defaulters" className="row">
        {!loadingMonths ? (
          <div
            className="col-10 col-lg-8 col-md-2 col-sm-2 mx-auto text-center mt-2 border"
            style={{ overflowX: "auto" }}
          >
            {monthsList.length > 0 ? (
              <>
                <div className="d-flex justify-content-center">
                  <h3 style={{ marginBottom: "0" }}>Choose Month</h3>
                </div>
                <div className="btn-group p-2">
                  {monthsList.map((item, i) => (
                    <button
                      key={i + 1}
                      onClick={() => {
                        setMonth(item.month);
                      }}
                      className="btn btn-outline-orange rounded-lg-pill"
                    >
                      {item.month}{" "}
                      <span className="rounded-pill bg-orange badge">
                        {item.defaulterCount}
                      </span>
                    </button>
                  ))}
                </div>
                <button
                  disabled={month === "none" ? true : false}
                  onClick={() => setMonth("none")}
                  className="btn btn-danger mb-2"
                >
                  Clear
                </button>
              </>
            ) : (
              "No months added yet"
            )}
          </div>
        ) : (
          <div className="col-10 col-lg-8 col-md-2 col-sm-2 mx-auto text-center p-2 border placeholder-wave">
            {/* <RotatingLines
              strokeColor="black"
              visible
              strokeWidth="4"
              width="30"
            /> */}
            <SpinnerCircular
              size={30}
              thickness={150}
              speed={130}
              color="rgba(0,0,0,1)"
              secondaryColor="rgba(255,255,255, 1)"
            />
          </div>
        )}
        <div className="col-10 h-50 container col-lg-8 mx-auto text-center mt-2 border">
          {!loadingDefaulters ? (
            month !== "none" ? (
              <div>
                <h4>
                  Defaulters for:{" "}
                  <Link
                    style={{ color: "Highlight" }}
                    onClick={() => {
                      sessionStorage.setItem(
                        "month",
                        sessionStorage.getItem("selected_month")
                      );
                    }}
                    to={{ pathname: "/dashboard/months/month" }}
                  >
                    {month}
                  </Link>
                </h4>
                {defList.length > 0 ? (
                  <table className="table table-responsive-sm table-hover table-striped">
                    <thead>
                      <tr>
                        <td>Sr. No.</td>
                        <td>Member Name</td>
                        <td>Pending Amount</td>
                      </tr>
                    </thead>
                    <tbody>
                      {defList.map((members, index) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td
                            style={{
                              cursor:
                                members.amount - members.paid_coll > 0
                                  ? "pointer"
                                  : "default",
                            }}
                            onClick={() => {
                              if (members.amount - members.paid_coll > 0) {
                                setMemberName(members.member_name);
                                // setOpen(true);
                                // sessionStorage.setItem(
                                //   "memberName",
                                //   members.member_name
                                // );
                                showDates(month, members.member_name);
                              } else return;
                            }}
                          >
                            {members.member_name}
                          </td>
                          <td>
                            {members.paid_coll === undefined ? (
                              members.amount
                            ) : members.amount - members.paid_coll === 0 ? (
                              <>
                                {"Penalty: "}
                                {members.expected_penalty}
                              </>
                            ) : (
                              members.amount - members.paid_coll
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h5 style={{ color: "red" }}>No members found!!</h5>
                )}
              </div>
            ) : (
              <span>Please select a month to view defaulters</span>
            )
          ) : (
            <div>
              {/* <RotatingLines
                strokeColor="black"
                visible
                strokeWidth="4"
                width="30"
              /> */}
              <SpinnerCircular
                size={30}
                thickness={150}
                speed={130}
                color="rgba(0,0,0,1)"
                secondaryColor="rgba(255,255,255, 1)"
              />
            </div>
          )}
        </div>
      </section>
      <Footer />
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth
          maxWidth="md"
        >
          <div className="container bordered text-center">
            <h4 className="border-bottom mt-2">
              Details for {memberDateData[0]?.member_name}
            </h4>
            <br />
            <table className="table table-responsive-sm table-hover table-striped bordered">
              <thead>
                <td>Sr. No.</td>
                <td>Date</td>
                <td>Pending Amount</td>
              </thead>
              <tbody>
                {memberDateData.map((item, i) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    <td>{item.date}</td>
                    <td>
                      {item.paid_coll !== 0
                        ? item.paid_coll === amount / datesLength
                          ? 0
                          : item.paid_coll
                        : amount / datesLength}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* {visitorsList.length > 0 ? (
              <>
                <h5>Pending Collection from Visitors</h5>
                <table className="table table-responsive-sm table-hover table-striped table-success">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Visitor Name</th>
                      <th>Date of Visit</th>
                      <th>Pending Collection</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visitorsList.map((visitor, i) => (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{visitor.visitor_name}</td>
                        <td>{visitor.date}</td>
                        <td>{visitor.pending_coll}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : null} */}
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Defaulters;
