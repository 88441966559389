import { Tooltip } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../favicon-32x32.png";
import { db } from "../firebase-config/firebase";

const NavBar = () => {
  const navigate = useNavigate();
  const [internet, setInternet] = useState(false);
  const [chp, setChp] = useState("");

  useEffect(() => {
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/" ||
      window.location.pathname ===
        `/create-profile/${sessionStorage.getItem("mail")}` ||
      window.location.pathname === "/resetpassword"
    ) {
      setChp("");
    } else {
      getDoc(doc(db, `profile/${sessionStorage.getItem("mail")}`)).then(
        (doc) => {
          setChp(doc.data().chapter_name);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine === true) {
      setInternet(true);
    } else {
      setInternet(false);
    }
  }, []);

  setInterval(() => {
    if (navigator.onLine === true) {
      setInternet(true);
    } else {
      setInternet(false);
    }
  }, 3000);
  return (
    <>
      <div className="nav-container">
        <nav className="navbar navbar-expand-md navbar-custom">
          <div className="container">
            <div
              className="navbar-brand"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <img src={Logo} alt="logo" /> Venue Fair Bahi Khata
            </div>
            <span className="user-tag ov">{chp}</span>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar_content"
              aria-controls="navbar_content"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="bi bi-list"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar_content">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <div className="nav-link">
                    <Tooltip
                      title={
                        internet === true
                          ? "You are connected to the internet"
                          : "Please connect to the internet"
                      }
                    >
                      <i
                        style={{ fontSize: "20px" }}
                        className={
                          internet === true ? "bi bi-wifi" : "bi bi-wifi-off"
                        }
                      ></i>
                    </Tooltip>
                  </div>
                </li>
                <li
                  className="nav-item mt-1"
                  hidden={window.location.pathname === "/" ? true : false}
                >
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    type={"button"}
                    onClick={() => navigate(-1)}
                  >
                    <i className="bi bi-box-arrow-left"></i> Back
                  </a>
                </li>
                <li className="nav-item mt-1">
                  <a
                    href="https://fairshare.tech/contact-details-of-software-company-pune/"
                    target="_blank"
                    className="nav-link"
                  >
                    <i className="bi bi-send-fill"></i> Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavBar;
