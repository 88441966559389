import { Delete, DeleteOutline, Edit, InfoOutlined } from "@mui/icons-material";
import { Dialog, Tooltip, Typography } from "@mui/material";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  onSnapshot,
  query,
  where,
  setDoc,
  deleteDoc,
  deleteField,
} from "firebase/firestore";
import Footer from "./Footer";
import NavBar from "./NavBar";
import moment from "moment";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SpinnerCircular } from "spinners-react";
import { db } from "../firebase-config/firebase";

const Date = () => {
  const navigate = useNavigate();
  const [membersList, setMembersList] = useState([]);
  const [membersLength, setMembersLength] = useState();
  const [dppm, setDppm] = useState(parseInt(0));
  const [dcpm, setDcpm] = useState(parseInt(0));
  const [collections, setCollections] = useState(0);
  const [amount, setAmount] = useState(0);
  // const [col, setCol] = useState(0);
  const [max, setMax] = useState(0);
  const [c, setC] = useState(0);
  const [monthMembers, setMonthMembers] = useState(0);
  const [datesLength, setDatesLength] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [getPenalty, setGetPenalty] = useState(parseInt(0));
  const [expenses, setExpenses] = useState(parseInt(0));
  const [open, setOpen] = useState(false);
  const [openPenaltyDialog, setOpenPenaltyDialog] = useState(false);
  const [openVisitorDialog, setOpenVisitorDialog] = useState(false);
  const [summary, setSummary] = useState([]);
  const [penalty, setPenalty] = useState(parseInt(0));
  const [visitorName, setVistorName] = useState("");
  const [expAmtVisitor, setExpAmtVisitor] = useState(0);
  const [paidAmtVisitor, setPaidAmtVisitor] = useState(parseInt(0));
  const [type, setType] = useState("Member");
  const [visitorList, setVisitorList] = useState([]);
  const [visitorCount, setVisitorCount] = useState();
  const [loading, setLoading] = useState(false);
  const [penalty_payment_method, setPenalty_Payment_Method] = useState("Cash");
  const [internet, setInternet] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [memberName, setMemberName] = useState("");
  const [openEditPMDialog, setOpenEditPMDialog] = useState(false);
  const [editPMMember, setEditPMMember] = useState(" ");
  const [isPenaltyPaid, setIsPenaltyPaid] = useState(false);
  const [income, setIncome] = useState();
  const [chp, setChp] = useState("");
  const [openDeletePenaltyDialog, setOpenDeletePenaltyDialog] = useState(false);
  const toastIdCollection = useRef(null);
  const toastId = useRef(null);

  useEffect(() => {
    if (sessionStorage.length === 0) {
      navigate("/");
    }
    // console.log(JSON.parse(sessionStorage.getItem("second")))
    // if (sessionStorage.getItem("datesLength") > 1) {
    //   if (
    //     sessionStorage.getItem("date") ===
    //     JSON.parse(sessionStorage.getItem("second")).date
    //   ) {
    //     setIsSecondDate(true);
    //   }
    // }
    const docRef1 = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}`
    );
    onSnapshot(docRef1, (snap) => {
      setAmount(snap.data().amount);
    });
    const docRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/members`
    );
    onSnapshot(docRef, (snap) => {
      setMonthMembers(snap.docs.length);
    });
    const docRef2 = doc(db, `profile/${sessionStorage.getItem("mail")}`);
    getDoc(docRef2).then((doc) => {
      setChp(doc.data().chapter_name);
      setDcpm(doc.data().def_collection_per_meeting);
    });
  }, []);

  useEffect(() => {
    let v1 = 0;
    setLoading(true);
    const colRef1 = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/dates`
    );
    onSnapshot(colRef1, (dates) => {
      dates.forEach((date) => {
        const colRef = collection(
          db,
          `profile/${sessionStorage.getItem(
            "mail"
          )}/months/${sessionStorage.getItem("selected_month")}/dates/${
            date.id
          }/members`
        );
        getDocs(colRef)
          .then((members) => {
            members.forEach((member) => {
              if (member.data().type === "visitor") {
                v1 += 1;
              }
            });
          })
          .finally(() => {
            setVisitorCount(v1);
          });
      });
    });
  }, [db]);

  useEffect(() => {
    const docRef = doc(db, `profile/${sessionStorage.getItem("mail")}`);
    getDoc(docRef).then((doc) => {
      setCollections(doc.data().def_collection_per_meeting);
      setDppm(doc.data().def_penalty_per_month);
      setExpAmtVisitor(doc.data().def_collection_per_meeting);
    });
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/dates`
    );
    getDocs(colRef).then((dates) => {
      setDatesLength(dates.docs.length);
    });
  }, []);

  useEffect(() => {
    const docRef1 = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/dates/${moment(
        sessionStorage.getItem("date")
      ).format("DD-MMM-YYYY")}/members`
    );
    onSnapshot(docRef1, (members) => {
      let arr = [];
      let paid_penalty = 0;
      members.forEach((member) => {
        const docRef = doc(
          db,
          `profile/${sessionStorage.getItem(
            "mail"
          )}/months/${sessionStorage.getItem("selected_month")}/members/${
            member.data().member_name
          }`
        );
        getDoc(docRef).then((d) => {
          if (d.exists()) {
            setGetPenalty(d.data().paid_penalty);
          }
        });
        arr.push({ ...member.data(), paid_penalty1: getPenalty });
      });
      setMembersList(arr);
    });
  }, [db]);

  useEffect(() => {
    const colRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}`
    );
    let v1 = 0;
    let actualCount = 0;
    const colRef1 = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/dates`
    );
    const colRef2 = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/members`
    );
    getDocs(colRef2).then((members) => {
      setMembersLength(members.docs.length);
    });
    getDocs(colRef1)
      .then((dates) => {
        dates.forEach((date) => {
          const colRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("selected_month")}/dates/${
              date.id
            }/members`
          );
          getDocs(colRef)
            .then((members) => {
              members.forEach((member) => {
                if (member.data().type === "visitor") {
                  v1 += 1;
                  actualCount += member.data().paid_coll;
                }
              });
            })
            .finally(() => {
              setVisitorCount(v1);
            });
        });
      })
      .finally(() => {
        onSnapshot(colRef, (snap) => {
          let arr = [];
          let a1 = 0;
          let e1 = 0;
          let i1 = 0;
          let d1 = 0;
          let v1 = 0;
          // let actualCount = 0;
          let defaulterCount = 0;
          const collectionRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("selected_month")}/members`
          );
          const colRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("selected_month")}/dates`
          );
          getDocs(colRef)
            .then((dates) => {
              dates.forEach((date) => {
                const colRef1 = collection(
                  db,
                  `profile/${sessionStorage.getItem(
                    "mail"
                  )}/months/${sessionStorage.getItem("selected_month")}/dates/${
                    date.id
                  }/expenses`
                );
                const colRef2 = collection(
                  db,
                  `profile/${sessionStorage.getItem(
                    "mail"
                  )}/months/${sessionStorage.getItem("selected_month")}/dates/${
                    date.id
                  }/incomes`
                );
                getDocs(colRef2)
                  .then((income) => {
                    income.forEach((i) => {
                      i1 += parseInt(i.data().amount);
                    });
                  })
                  .finally(() => {
                    setIncome(i1);
                  });
                getDocs(colRef1)
                  .then((expenses) => {
                    expenses.forEach((expense) => {
                      e1 += parseInt(expense.data().expenseAmount);
                    });
                  })
                  .finally(() => {
                    setExpenses(e1);
                  });
              });
            })
            .finally(() => {
              getDocs(collectionRef).then((members) => {
                let p = 0;
                let pp = 0;
                members.forEach((doc) => {
                  if (doc.data().paid_coll !== undefined) {
                    actualCount += doc.data().paid_coll;
                  }
                  if (
                    doc.data().paid_coll < doc.data().amount ||
                    doc.data().paid_coll === undefined ||
                    doc.data().paid_penalty < doc.data().expected_penalty
                  ) {
                    defaulterCount += 1;
                  }
                  if (doc.data().expected_penalty !== undefined) {
                    p += doc.data().expected_penalty;
                  }
                  if (doc.data().paid_penalty !== undefined) {
                    pp += doc.data().paid_penalty;
                  }
                });

                arr.push({
                  ...snap.data(),
                  actualCount: actualCount,
                  defaulterCount: defaulterCount,
                  expenseAmount: parseInt(expenses),
                  expected_penalty: p,
                  paid_penalty: pp,
                  income: parseInt(income),
                  visitors: parseInt(visitorCount),
                });
                a1 = actualCount;
                d1 = defaulterCount;
                setSummary(arr);
                setLoading(false);
              });
            });
        });
      });
  }, []);

  // useEffect(() => {
  //   let arr = [];
  //   let actCol = 0;
  //   let defCount = 0;
  //   let ec = 0;
  //   let vc = 0;
  //   let pp = 0;
  //   let ep = 0;
  //   let carry_forward = 0;
  //   const colRef = collection(
  //     db,
  //     `profile/${sessionStorage.getItem(
  //       "mail"
  //     )}/months/${sessionStorage.getItem("selected_month")}/dates`
  //   );
  //   let colRefPath = colRef.path;
  //   getDocs(colRef).then((dates) => {
  //     dates.docs.forEach((date) => {
  //       // EXPENSES
  //       const colRef1 = collection(db, `${colRefPath}/${date.id}/expenses`);
  //       let colRefPath1 = colRef1.path;
  //       getDocs(colRef1).then((expenses) => {
  //         expenses.forEach((expense) => {
  //           const docRef = doc(db, `${colRefPath1}/${expense.id}`);
  //           getDoc(docRef)
  //             .then((e) => {
  //               console.log(
  //                 "Expense on date " + date.id + ": " + e.data().expenseAmount
  //               );
  //               ec += e.data().expenseAmount;
  //             })
  //             .finally(() => {
  //               setExpenses(ec);
  //             });
  //         });
  //       });

  //       // VISITORS
  //       const colRef2 = collection(db, `${colRefPath}/${date.id}/members`);
  //       let colRef2Path = colRef2.path;
  //       getDocs(colRef2).then((members) => {
  //         members.docs.forEach((member) => {
  //           if (member.data().type === "visitor") {
  //             vc += 1;
  //             actCol += member.data().paid_coll;
  //             console.log(
  //               member.data().type + " on date " + date.id + " " + vc
  //             );
  //           }
  //         });
  //       });
  //     });
  //   });
  //   // ACTUAL COLLECTION
  //   const colRef3 = collection(
  //     db,
  //     `profile/${sessionStorage.getItem(
  //       "mail"
  //     )}/months/${sessionStorage.getItem("selected_month")}/members`
  //   );
  //   let colRef3Path = colRef3.path;
  //   getDocs(colRef3).then((members) => {
  //     members.docs.forEach((member) => {
  //       if (
  //         member.data().paid_coll < member.data().amount ||
  //         member.data().paid_coll === undefined ||
  //         member.data().paid_penalty < member.data().expected_penalty
  //       ) {
  //         defCount += 1;
  //       }
  //       if (member.data().paid_coll !== undefined) {
  //         actCol += member.data().paid_coll;
  //         console.log(
  //           "Collections from " + member.id + ": " + member.data().paid_coll
  //         );
  //       }
  //       if (member.data().paid_penalty !== undefined) {
  //         pp += member.data().paid_penalty;
  //         ep += member.data().expected_penalty;
  //         console.log(
  //           "Penalty from " + member.id + ": " + member.data().paid_penalty
  //         );
  //       }
  //     });
  //     const docRef1 = doc(
  //       db,
  //       `profile/${sessionStorage.getItem(
  //         "mail"
  //       )}/months/${sessionStorage.getItem("selected_month")}`
  //     );
  //     getDoc(docRef1).then((doc) => {
  //       carry_forward = doc.data().carry_forward;
  //       setMembersLength(doc.data().members);
  //     });
  //     console.log(ec);
  //     arr.push({
  //       carry_forward: carry_forward,
  //       actCol: actCol,
  //       defCount: defCount,
  //       paid_penalty: pp,
  //       expected_penalty: ep,
  //       expenses: parseInt(expenses),
  //       visitors: vc,
  //     });
  //     if (arr.length > 0) {
  //       setLoading(false);
  //       setSummary(arr);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/members`
    );
    const q = query(
      colRef,
      where("member", "==", sessionStorage.getItem("member")),
      where("pending_coll", "==", parseInt(expAmtVisitor))
    );
    onSnapshot(q, (snap) => {
      let arr = [];
      snap.forEach((visitor) => {
        arr.push(visitor.data().visitor_name);
      });
      setVisitorList(arr);
    });
  }, [sessionStorage.getItem("member")]);

  const handleAddVisitor = (e) => {
    e.preventDefault();
    if (
      visitorName !== "" &&
      expAmtVisitor >= 0 &&
      paidAmtVisitor >= 0 &&
      paymentMethod !== ""
    ) {
      const docRef = doc(
        db,
        `profile/${sessionStorage.getItem(
          "mail"
        )}/months/${sessionStorage.getItem(
          "selected_month"
        )}/dates/${sessionStorage.getItem("date")}/members/${visitorName}`
      );
      if (parseInt(paidAmtVisitor) === parseInt(expAmtVisitor)) {
        setDoc(docRef, {
          type: "visitor",
          member: sessionStorage.getItem("m"),
          visitor_name: visitorName,
          expAmtVisitor: parseInt(expAmtVisitor),
          paid_coll: parseInt(paidAmtVisitor),
          paymentMethod: paymentMethod,
          pending_coll: 0,
        }).then(() => {
          toast.success("Visitor Added Successfully!!");
          setOpenVisitorDialog(false);
          setVistorName("");
          setPaidAmtVisitor(0);
          setPaymentMethod("Cash");
          setExpAmtVisitor(collections);
        });
      }
      if (parseInt(paidAmtVisitor) === parseInt(0)) {
        setDoc(docRef, {
          type: "visitor",
          member: sessionStorage.getItem("m"),
          visitor_name: visitorName,
          expAmtVisitor: parseInt(expAmtVisitor),
          paid_coll: parseInt(paidAmtVisitor),
          paymentMethod: paymentMethod,
          pending_coll: parseInt(expAmtVisitor),
        }).then(() => {
          // const docRef1 = doc(
          //   db,
          //   `profile/${sessionStorage.getItem(
          //     "mail"
          //   )}/months/${sessionStorage.getItem(
          //     "selected_month"
          //   )}/members/${sessionStorage.getItem("m")}`
          // );
          // getDoc(docRef1).then((d) => {
          //   if (d.data().pending_coll === undefined) {
          //     updateDoc(docRef, {
          //       visitor_pending_coll: parseInt(expAmtVisitor),
          //     });
          //   } else {
          //     updateDoc(docRef, {
          //       visitor_pending_coll:
          //         d.data().pending_coll + parseInt(expAmtVisitor),
          //     });
          //   }
          // });

          const docRef = doc(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem(
              "selected_month"
            )}/dates/${sessionStorage.getItem(
              "date"
            )}/members/${sessionStorage.getItem("m")}`
          );
          getDoc(docRef).then((d) => {
            if (d.data().pending_coll === undefined) {
              updateDoc(docRef, {
                pending_coll: parseInt(expAmtVisitor),
              }).then(() => {
                toast.success("Visitor Added Successfully!!");
                setOpenVisitorDialog(false);
                setVistorName("");
                setPaidAmtVisitor(0);
                setPaymentMethod("Cash");
                setExpAmtVisitor(collections);
              });
            } else {
              updateDoc(docRef, {
                pending_coll: d.data().pending_coll + parseInt(expAmtVisitor),
              }).then(() => {
                toast.success("Visitor Added Successfully!!");
                setOpenVisitorDialog(false);
                setVistorName("");
                setPaidAmtVisitor(0);
                setPaymentMethod("Cash");
                setExpAmtVisitor(collections);
              });
            }
          });
        });
      }
    } else {
      toast.warn("Please fill out all fields correctly");
    }
  };

  // const handleAdd = () => {
  //   const docRef = doc(
  //     db,
  //     `profile/${sessionStorage.getItem(
  //       "mail"
  //     )}/months/${sessionStorage.getItem(
  //       "month"
  //     )}/dates/${sessionStorage.getItem(
  //       "date"
  //     )}/members/${sessionStorage.getItem("member")}`
  //   );
  //   const docRef1 = doc(
  //     db,
  //     `profile/${sessionStorage.getItem(
  //       "mail"
  //     )}/months/${sessionStorage.getItem(
  //       "month"
  //     )}/members/${sessionStorage.getItem("member")}`
  //   );
  //   const docRef2 = doc(
  //     db,
  //     `profile/${sessionStorage.getItem(
  //       "mail"
  //     )}/months/${sessionStorage.getItem("month")}`
  //   );
  //   if (parseInt(c) > parseInt(max)) {
  //     toast.warn("Please enter amount less than " + parseInt(max));
  //   } else if (parseInt(c) === parseInt(max)) {
  //     notifyCollection();
  //     console.log("Equal");
  //     getDoc(docRef).then((doc) => {
  //       updateDoc(docRef, {
  //         paid_coll: doc.data().paid_coll + parseInt(c),
  //         pending: 0,
  //         penalty: false,
  //         payment_method: paymentMethod,
  //       });
  //     });
  //     getDoc(docRef2).then((doc) => {
  //       updateDoc(docRef2, {
  //         collections: doc.data().collections + parseInt(c),
  //       });
  //     });
  //     getDoc(docRef1).then((doc) => {
  //       updateDoc(docRef1, {
  //         paid_coll: doc.data().paid_coll + parseInt(c),
  //       }).then(() => {
  //         setOpen(false);
  //         updateCollection();
  //       });
  //     });
  //   } else {
  //     notifyCollection();
  //     getDoc(docRef).then((doc) => {
  //       updateDoc(docRef, {
  //         paid_coll: doc.data().paid_coll + parseInt(c),
  //         pending: doc.data().pending - parseInt(c),
  //         penalty: true,
  //         payment_method: paymentMethod,
  //       });
  //     });
  //     getDoc(docRef2).then((doc) => {
  //       updateDoc(docRef2, {
  //         collections: doc.data().collections + parseInt(c),
  //       });
  //     });
  //     getDoc(docRef1).then((doc) => {
  //       updateDoc(docRef1, {
  //         paid_coll: doc.data().paid_coll + parseInt(c),
  //       }).then(() => {
  //         setOpen(false);
  //         updateCollection();
  //       });
  //     });
  //   }
  // };

  const handleAdd = () => {
    notifyCollection();
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "month"
      )}/members/${sessionStorage.getItem("member")}`
    );
    getDoc(docRef).then((d) => {
      updateDoc(docRef, {
        paid_coll: d.data().paid_coll + parseInt(c),
        pending_coll: d.data().pending_coll - parseInt(c),
        // paymentMethod: paymentMethod,
      });
    });
    const docRef1 = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "month"
      )}/dates/${sessionStorage.getItem(
        "date"
      )}/members/${sessionStorage.getItem("member")}`
    );
    getDoc(docRef1).then((d) => {
      updateDoc(docRef1, {
        paid_coll: d.data().paid_coll + parseInt(c),
        pending_coll: d.data().pending_coll - parseInt(c),
        payment_method: paymentMethod,
      }).finally(() => {
        updateCollection();
        setOpen(false);
      });
    });
  };

  const addCollectionVisitor = () => {
    notifyAddCollectionVisitor();
    const docRef1 = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem(
        "date"
      )}/members/${sessionStorage.getItem("member")}`
    );
    getDoc(docRef1).then((d) => {
      updateDoc(docRef1, {
        pending_coll: d.data().pending_coll - parseInt(c),
      });
    });

    // const docRef2 = doc(
    //   db,
    //   `profile/${sessionStorage.getItem(
    //     "mail"
    //   )}/months/${sessionStorage.getItem(
    //     "selected_month"
    //   )}/members/${sessionStorage.getItem("member")}`
    // );
    // getDoc(docRef2).then((d) => {
    //   updateDoc(docRef2, {
    //     visitor_pending_coll: d.data().visitor_pending_coll - parseInt(c),
    //   });
    // });

    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/members/${visitorName}`
    );
    getDoc(docRef).then((d) => {
      updateDoc(docRef, {
        paid_coll: parseInt(d.data().paid_coll) + parseInt(c),
        pending_coll: parseInt(d.data().pending_coll) - parseInt(c),
      }).then(() => {
        updateAddCollectionVisitor();
        setOpen(false);
        setType("Member");
      });
    });
  };

  const notifyCollection = () => {
    toastIdCollection.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Adding Collection...
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };

  const updateCollection = () =>
    toast.update(toastIdCollection.current, {
      render: "Collection Added Successfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });

  const notifyPenalty = () => {
    toastIdCollection.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Adding Penalty...
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };

  const updatePenalty = () =>
    toast.update(toastIdCollection.current, {
      render: "Penalty Added Successfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });

  const notifyAddCollectionVisitor = () => {
    toastIdCollection.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Adding Visitor's Collection
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };

  const updateAddCollectionVisitor = () =>
    toast.update(toastIdCollection.current, {
      render: "Visitor's Collection Added Successfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });

  const notifyPenaltyDelete = () => {
    toastId.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Please wait deleting penalty
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };

  const updatePenaltyDelete = () => {
    toast.update(toastId.current, {
      render: "Penalty Deleted Successfully!",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });
  };

  const handlePenaltyAdd = (e) => {
    e.preventDefault();
    // FOR MONTH LEVEL
    if (parseInt(penalty) > parseInt(dppm)) {
      toast.warn("Please enter a value less than " + dppm);
    } else if (parseInt(penalty) === parseInt(dppm)) {
      notifyPenalty();
      const docRef = doc(
        db,
        `profile/${sessionStorage.getItem(
          "mail"
        )}/months/${sessionStorage.getItem(
          "selected_month"
        )}/members/${sessionStorage.getItem("selected_member")}`
      );
      updateDoc(docRef, {
        paid_penalty: parseInt(penalty),
        penalty_payment_method: penalty_payment_method,
      });

      const colRef = collection(
        db,
        `profile/${sessionStorage.getItem(
          "mail"
        )}/months/${sessionStorage.getItem("selected_month")}/dates`
      );
      onSnapshot(colRef, (dates) => {
        var inc = 0;
        dates.forEach((date) => {
          const colRef1 = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("selected_month")}/dates/${
              date.id
            }/members`
          );
          const q = query(
            colRef1,
            where(
              "member_name",
              "==",
              sessionStorage.getItem("selected_member")
            )
          );
          onSnapshot(q, (snap) => {
            snap.forEach((member) => {
              const docRef = doc(
                db,
                `profile/${sessionStorage.getItem(
                  "mail"
                )}/months/${sessionStorage.getItem("selected_month")}/dates/${
                  date.id
                }/members/${member.id}`
              );
              updateDoc(docRef, {
                paid_penalty: parseInt(penalty),
                penalty_payment_method: penalty_payment_method,
              })
                .finally(() => {
                  if (inc === snap.docs.length - 1) {
                    updatePenalty();
                    setOpenPenaltyDialog(false);
                    setPenalty(0);
                  }
                })
                .then(() => {
                  inc += 1;
                });
            });
          });
        });
      });
    }
  };
  setInterval(() => {
    if (navigator.onLine === true) {
      setInternet(true);
    } else {
      setInternet(false);
    }
  }, 3000);

  useEffect(() => {
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/members/${editPMMember}`
    );
    getDoc(docRef).then((doc) => {
      if (doc.data().expected_penalty !== undefined) {
        setIsPenaltyPaid(true);
        setPenalty_Payment_Method(doc.data().penalty_payment_method);
      } else {
        setIsPenaltyPaid(false);
      }
      if (doc.data().payment_method !== undefined) {
        setPaymentMethod(doc.data().payment_method);
      }
      if (doc.data().paymentMethod !== undefined) {
        setPaymentMethod(doc.data().paymentMethod);
      }
      setOpenEditPMDialog(true);
    });
  }, [editPMMember]);

  const handleSaveEditPM = (t) => {
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/members/${editPMMember}`
    );
    if (t === "member") {
      updateDoc(docRef, {
        payment_method: paymentMethod,
      }).then(() => {
        setEditPMMember(" ");
        setOpenEditPMDialog(false);
        toast.success("Payment Method for Member Collection Updated!!");
      });
    } else if (t === "penalty") {
      updateDoc(docRef, {
        penalty_payment_method: penalty_payment_method,
      }).then(() => {
        setEditPMMember(" ");
        setOpenEditPMDialog(false);
        toast.success("Payment Method for Penalty Collection Updated!!");
      });
    } else if (t === "visitor") {
      updateDoc(docRef, {
        paymentMethod: paymentMethod,
      }).then(() => {
        setEditPMMember(" ");
        setOpenEditPMDialog(false);
        toast.success("Payment Method for Visitor Collection Updated!!");
      });
    }
  };

  const handleDeleteVistor = () => {
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/members/${visitorName}`
    );
    getDoc(docRef).then((visitorData) => {
      if (visitorData.data().paid_coll === collections) {
        deleteDoc(docRef).then(() => {
          toast.success("Visitor Deleted Successfully");
          setOpenDeleteDialog(false);
          setVistorName("");
          setMemberName("");
        });
      } else {
        const docRef1 = doc(
          db,
          `profile/${sessionStorage.getItem(
            "mail"
          )}/months/${sessionStorage.getItem(
            "selected_month"
          )}/dates/${sessionStorage.getItem("date")}/members/${memberName}`
        );
        getDoc(docRef1).then((d) => {
          updateDoc(docRef1, {
            pending_coll: d.data().pending_coll - parseInt(collections),
          }).then(() => {
            deleteDoc(docRef).then(() => {
              toast.success("Visitor Deleted Successfully");
              setOpenDeleteDialog(false);
              setVistorName("");
              setMemberName("");
            });
          });
        });
      }
    });
  };

  const handlePenaltyDelete = () => {
    notifyPenaltyDelete();
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/members/${sessionStorage.getItem("member")}`
    );
    updateDoc(docRef, {
      expected_penalty: deleteField(),
      paid_penalty: deleteField(),
      penalty_payment_method: deleteField(),
    }).then(() => {
      const colRef = collection(
        db,
        `profile/${sessionStorage.getItem(
          "mail"
        )}/months/${sessionStorage.getItem("selected_month")}/dates`
      );
      getDocs(colRef)
        .then((col) => {
          col.docs.forEach((doc1) => {
            const docRef1 = doc(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${sessionStorage.getItem("selected_month")}/dates/${
                doc1.id
              }/members/${sessionStorage.getItem("member")}`
            );
            updateDoc(docRef1, {
              expected_penalty: deleteField(),
              paid_penalty: deleteField(),
              penalty_payment_method: deleteField(),
            });
          });
        })
        .finally(() => {
          setOpenDeletePenaltyDialog(false);
          updatePenaltyDelete();
        });
    });
  };

  return (
    <>
      {/* <div className="nav-container">
        <nav className="navbar navbar-expand-md navbar-custom">
          <div className="container">
            <div
              className="navbar-brand"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <img src={Logo} alt="logo" /> Venue Fair Bahi Khata
            </div>
            <span className="user-tag">{sessionStorage.getItem("bfn")}</span>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar_content"
              aria-controls="navbar_content"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="bi bi-list"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar_content">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <div className="nav-link">
                    <Tooltip
                      title={
                        internet === true
                          ? "You are connected to the internet"
                          : "Please connect to the internet"
                      }
                    >
                      <i
                        style={{ fontSize: "20px" }}
                        className={
                          internet === true ? "bi bi-wifi" : "bi bi-wifi-off"
                        }
                      ></i>
                    </Tooltip>
                  </div>
                </li>
                <li className="nav-item mt-1">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    type={"button"}
                    onClick={() =>
                      navigate(
                        `/dashboard/months/dates/${sessionStorage.getItem(
                          "mail"
                        )}`
                      )
                    }
                  >
                    <i className="bi bi-box-arrow-left"></i> Back
                  </a>
                </li>
                <li className="nav-item mt-1">
                  <a
                    href="https://fairshare.tech/contact-details-of-software-company-pune/"
                    target="_blank"
                    className="nav-link"
                  >
                    <i className="bi bi-send-fill"></i> Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div> */}

      <NavBar />
      <section id="date" className="row">
        <div
          style={{ border: "2px solid", overflowX: "auto" }}
          className="col-12 col-lg-10 col-md-8 col-sm-6 mx-auto text-center rounded p-2 mb-1"
        >
          {!loading ? (
            <>
              <legend style={{ color: "Highlight" }} className="p-2">
                <Link
                  style={{ color: "Highlight" }}
                  onClick={() => {
                    sessionStorage.setItem(
                      "month",
                      sessionStorage.getItem("selected_month")
                    );
                  }}
                  to={{ pathname: "/dashboard/months/month" }}
                >
                  Summary for {sessionStorage.getItem("selected_month")}
                </Link>
              </legend>
              <table
                className="table table-bordered table-responsive-sm table-hover"
                style={{ overflow: "scroll" }}
              >
                <thead>
                  <tr>
                    <th>C/F Amount</th>
                    <th>Exp. Collection</th>
                    <th>Actual Collection</th>
                    <th>Exp. Penalty</th>
                    <th>Paid Penalty</th>
                    <th>Members</th>
                    <th>Expenses</th>
                    <th>Income</th>
                    <th style={{ cursor: "pointer" }}>
                      <Link
                        to={{
                          pathname: `/dashboard/visitors/${sessionStorage.getItem(
                            "selected_month"
                          )}`,
                        }}
                      >
                        <u style={{ color: "black" }}>Visitors</u>
                      </Link>
                    </th>
                    <th>
                      <Link
                        style={{ color: "black" }}
                        to={{
                          pathname: `/dashboard/defaulters/${sessionStorage.getItem(
                            "mail"
                          )}/${sessionStorage.getItem("selected_month")}`,
                        }}
                      >
                        <u>Defaulters</u>
                      </Link>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {summary.map((items) => (
                    <tr key={1}>
                      <td>
                        {items.carry_forward === undefined
                          ? 0
                          : items.carry_forward}
                      </td>
                      <td>{amount * membersLength + visitorCount * dcpm}</td>
                      <td>{items.actualCount}</td>
                      <td>{items.expected_penalty}</td>
                      <td>{items.paid_penalty}</td>
                      <td>{membersLength}</td>
                      <td>{items.expenses}</td>
                      <td>{income}</td>
                      <td>{visitorCount}</td>
                      <td>{items.defaulterCount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            // <RotatingLines
            //   strokeColor="black"
            //   visible
            //   strokeWidth="4"
            //   width="30"
            // />
            <SpinnerCircular
              size={30}
              thickness={150}
              speed={130}
              color="rgba(0,0,0,1)"
              secondaryColor="rgba(255,255,255, 1)"
            />
          )}
        </div>
        <div className="col-10 col-lg-10 col-md-8 mx-auto">
          <div className="card">
            <div
              className="btn-toolbar justify-content-lg-between justify-content-md-center justify-content-sm-center m-2"
              role={"toolbar"}
            >
              <div className="input-group">
                <legend className="mb-0 mt-1" style={{ color: "Highlight" }}>
                  Date:{" "}
                  {moment(sessionStorage.getItem("date")).format("DD-MMM-YYYY")}
                </legend>
              </div>
              <div>
                <button
                  className="btn btn-orange m-2"
                  onClick={() => navigate("/dashboard/incomes")}
                >
                  Add Incomes
                </button>
                <button
                  onClick={() => navigate("/dashboard/months/date/expense")}
                  className="btn btn-orange"
                >
                  Add Expenses
                </button>
              </div>
            </div>
            <div className="p-2">
              <Link
                style={{ color: "Highlight" }}
                onClick={() => {
                  sessionStorage.setItem(
                    "month",
                    sessionStorage.getItem("selected_month")
                  );
                }}
                to={{ pathname: "/dashboard/months/month" }}
              >
                Add Monthly Collection for Members?
              </Link>
            </div>
          </div>
          <br />
          <div
            className="mx-auto text-center border"
            style={{
              overflowX: "auto",
            }}
          >
            <table className="table table-responsive-sm table-hover table-striped">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Member Name</th>
                  <th className="col-lg-1">Exp. Collection</th>
                  <th className="col-lg-1">Paid Collection</th>
                  <th className="col-lg-1">Pending Collection</th>
                  <th className="col-lg-1">Exp. Penalty</th>
                  <th className="col-lg-1">Paid Penalty</th>
                  {/* <th className="col-lg-2">Pending Collection from Visitors</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {membersList.map((member, i) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    <td
                      style={{
                        color:
                          member.paid_coll < collections ||
                          member.paid_coll === undefined
                            ? "red"
                            : "black",
                      }}
                    >
                      <p
                        style={{
                          color:
                            member.type === undefined
                              ? member.paid_coll < collections ||
                                member.paid_coll === undefined
                                ? "red"
                                : "black"
                              : "deepskyblue",
                          fontSize: "1.2rem",
                        }}
                      >
                        {member.type === undefined ? (
                          member.member_name
                        ) : (
                          <>
                            {member.visitor_name}
                            {"  "}
                            <Tooltip title={`Member Name: ${member.member}`}>
                              {/* <i className="bi bi-info-circle"></i> */}
                              <InfoOutlined fontSize="small" />
                            </Tooltip>
                          </>
                        )}
                      </p>
                    </td>
                    {/* ------------------EXP Collections-------------------------- */}
                    {/* <td> */}
                    {/* {member.type === undefined
                        ? member.pending_coll !== undefined
                          ? collections + member.pending_coll
                          : collections
                        : collections} */}
                    {/* {collections} */}
                    {/* {member.type === undefined
                        ? member.pending_coll !== undefined
                          ? member.pending_coll === 0
                            ? collections + member.pending_coll
                            : member.pending_coll
                          : collections
                        : collections} */}
                    {/* </td> */}
                    {/* ------------------PAID COLLECTION--------------------------- */}
                    {/* <td>
                      {member.paid_coll !== undefined ? member.paid_coll : 0}
                    </td> */}
                    {/* --------------------PENDING COLLECTION----------------------- */}
                    {/* <td>
                      {member.pending_coll !== undefined
                        ? member.pending_coll
                        : 0}
                    </td> */}
                    {/* <td>
                      {member.paid_coll === 0
                        ? collections
                        : collections - member.paid_coll}
                    </td> */}
                    {/* <td>
                      {member.pending_coll !== undefined
                        ? member.pending_coll
                        : "-"}
                    </td> */}
                    {/* ------------PENDING COLLECTION FROM VISITORS ------------- */}
                    {/* <td>
                      {member.pending_coll > collections
                        ? member.pending_coll - collections
                        : "-"}
                    </td> */}
                    <td>
                      {/* {member.type === undefined
                        ? member.pending_coll === collections ||
                          member.pending_coll === 0
                          ? collections
                          : member.pending_coll + collections
                        : collections} */}
                      {collections}
                    </td>
                    <td>
                      {member.paid_coll !== undefined ? member.paid_coll : 0}
                    </td>
                    <td>
                      {member.pending_coll !== undefined
                        ? member.pending_coll
                        : 0}
                    </td>
                    <td>
                      {member.expected_penalty === undefined
                        ? "-"
                        : member.expected_penalty}
                    </td>
                    <td>
                      {member.paid_penalty === undefined
                        ? "-"
                        : member.paid_penalty}
                    </td>
                    {/* ------------------------------- BUTTONS ------------------- */}
                    <td>
                      {member.type === "visitor" ? (
                        <>
                          <div className="btn-group">
                            <button
                              className="btn btn-outline-orange"
                              onClick={() => {
                                setOpenDeleteDialog(true);
                                setVistorName(member.visitor_name);
                                setMemberName(member.member);
                              }}
                            >
                              <DeleteOutline /> Delete Visitor
                            </button>
                            <Tooltip title="Edit Payment Method">
                              <button
                                onClick={() => {
                                  setType("visitor");
                                  setEditPMMember(member.visitor_name);
                                }}
                                disabled={member.pending_coll > 0}
                                className="btn btn-outline-orange"
                              >
                                <Edit />
                              </button>
                            </Tooltip>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="btn-group">
                            <button
                              disabled={
                                member.pending_coll > 0 || member.pending > 0
                                  ? false
                                  : true
                                // ((collections - member.paid_coll > 0 &&
                                //   member.paid_coll === 0) ||
                                //   member.pending > 0 ||
                                //   member.pending_coll > 0) &&
                                // member.payment_method !== "none"
                                //   ? false
                                //   : true
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#add_coll_modal"
                              type={"button"}
                              onClick={() => {
                                sessionStorage.setItem(
                                  "member",
                                  member.member_name
                                );
                                setMax(collections - member.paid_coll);
                                sessionStorage.setItem(
                                  "max",
                                  collections - member.paid_coll
                                );
                                setOpen(true);
                              }}
                              className="btn btn-outline-orange"
                            >
                              Accept Collection
                            </button>
                            <button
                              className="btn btn-outline-orange"
                              // disabled={
                              //   member.paid_penalty === dppm ||
                              //   member.paid_coll < collections
                              //     ? false
                              //     : true
                              // }
                              // disabled={
                              //   member.type === undefined
                              //     ? member.paid_penalty === dppm ||
                              //       member.paid_coll === collections
                              //       ? true
                              //       : false
                              //     : true
                              // }
                              disabled={
                                member.type === undefined
                                  ? member.expected_penalty === dppm &&
                                    member.paid_penalty === 0
                                    ? false
                                    : true
                                  : true
                              }
                              onClick={() => {
                                sessionStorage.setItem(
                                  "selected_member",
                                  member.member_name
                                );
                                setOpenPenaltyDialog(true);
                              }}
                            >
                              Accept Penalty
                            </button>
                            <button
                              disabled={
                                (member.type === undefined &&
                                  member.paid_coll > 0) ||
                                member.pending_coll > 0
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                setOpenVisitorDialog(true);
                                sessionStorage.setItem("m", member.member_name);
                              }}
                              className="btn btn-outline-orange"
                            >
                              Add Visitor
                            </button>
                            <Tooltip title="Delete Penalty">
                              <button
                                disabled={
                                  member.expected_penalty === undefined
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "member",
                                    member.member_name
                                  );
                                  setOpenDeletePenaltyDialog(true);
                                }}
                                className="btn btn-outline-orange"
                              >
                                <Delete />
                              </button>
                            </Tooltip>
                            <Tooltip title="Edit Payment Methods">
                              <button
                                className="btn btn-outline-orange"
                                disabled={member.paid_coll === 0 ? true : false}
                                onClick={() => {
                                  setEditPMMember(member.member_name);
                                  setType("member");
                                }}
                              >
                                <Edit />
                              </button>
                            </Tooltip>
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <Footer />
      {open && (
        <Dialog
          maxWidth={"sm"}
          open={open}
          onClose={() => {
            setOpen(false);
            sessionStorage.removeItem("member");
          }}
        >
          <div className="modal-body">
            <div>
              <h4 className="modal-title">Add Collection</h4>

              <label htmlFor="type">Select Type</label>
              <select
                name="type"
                id="type"
                className="form-control"
                onChange={(e) => setType(e.target.value)}
              >
                <option value="Member" selected>
                  Member
                </option>
                <option value="Visitor">Visitor</option>
              </select>
              <br />
              {visitorList.length > 0 && type === "Visitor" ? (
                <>
                  <label htmlFor="visitors">Select A Visitor</label>
                  <br />
                  <select
                    className="form-control"
                    name="visitors"
                    id="visitors"
                    onChange={(e) => setVistorName(e.target.value)}
                    placeholder="Select A Visitor"
                  >
                    <option value="null">Select A Visitor</option>
                    {visitorList.map((visitor, i) => (
                      <option value={visitorList[i]}>{visitor}</option>
                    ))}
                  </select>
                  <br />
                </>
              ) : null}
              <label
                htmlFor="coll"
                hidden={
                  type === "Member"
                    ? false
                    : visitorList.length === 0
                    ? true
                    : false
                }
              >
                Enter Collection Amount
              </label>
              {type === "Member" ? (
                <>
                  {/* <input
                    type={"number"}
                    min={0}
                    // max={
                    //   sessionStorage.getItem("max") === "NaN"
                    //     ? collections
                    //     : sessionStorage.getItem("max")
                    // }
                    onChange={(e) => setC(e.target.value)}
                    id="coll"
                    className="form-control"
                  /> */}
                  <select
                    name="coll"
                    className="form-control"
                    id="coll"
                    onChange={(e) => setC(e.target.value)}
                  >
                    <option value="-1">Select an Amount</option>
                    <option value={parseInt(0)}>0</option>
                    <option value={parseInt(collections)}>{collections}</option>
                  </select>
                </>
              ) : (
                <>
                  {visitorList.length > 0 ? (
                    <select
                      onChange={(e) => setC(e.target.value)}
                      className="form-control"
                      name="paid"
                      id="paid"
                    >
                      <option value="Select Amount" selected>
                        Select Amount
                      </option>
                      <option value={parseInt(0)}>0</option>
                      <option value={parseInt(expAmtVisitor)}>
                        {expAmtVisitor}
                      </option>
                    </select>
                  ) : (
                    <p style={{ color: "red" }}>
                      Sorry you dont have any visitors added yet!!
                    </p>
                  )}
                </>
              )}
            </div>
            <br />
            {type === "Member" ? (
              <>
                <label htmlFor="payment">Select Payment Method</label>
                <div className="d-flex">
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      defaultChecked
                      onClick={() => setPaymentMethod("Cash")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault1"
                    >
                      Cash
                    </label>
                  </div>
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={() => setPaymentMethod("Online")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault2"
                    >
                      Online
                    </label>
                  </div>
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      onClick={() => setPaymentMethod("Other")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault3"
                    >
                      Other
                    </label>
                  </div>
                </div>
              </>
            ) : visitorList.length > 0 ? (
              <>
                <label htmlFor="payment">Select Payment Method</label>
                <div className="d-flex">
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      defaultChecked
                      onClick={() => setPaymentMethod("Cash")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault1"
                    >
                      Cash
                    </label>
                  </div>
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={() => setPaymentMethod("Online")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault2"
                    >
                      Online
                    </label>
                  </div>
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      onClick={() => setPaymentMethod("Other")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault3"
                    >
                      Other
                    </label>
                  </div>
                </div>
              </>
            ) : null}
            {type === "Member" ? (
              <p>
                Please Note:
                <br /> Maximum Collection Amount is:{" "}
                {sessionStorage.getItem("max") === "NaN"
                  ? collections
                  : sessionStorage.getItem("max")}
              </p>
            ) : null}
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-danger"
              onClick={() => {
                setOpen(false);
                setType("Member");
                sessionStorage.removeItem("member");
              }}
              type={"button"}
            >
              Cancel
            </button>
            {type === "Visitor" ? (
              <button onClick={addCollectionVisitor} class="btn btn-primary">
                Add Collection for Visitor
              </button>
            ) : (
              <button onClick={handleAdd} className="btn btn-primary">
                Add Collection for Member
              </button>
            )}
          </div>
        </Dialog>
      )}
      {openPenaltyDialog && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={openPenaltyDialog}
          onClose={() => setOpenPenaltyDialog(false)}
        >
          <div className="modal-content">
            <div className="modal-header h5">
              Add Penalty for {sessionStorage.getItem("selected_member")}
            </div>
            <div className="modal-body">
              <form className="form">
                <div className="form-group">
                  <label htmlFor="penalty">Enter Penalty Amount</label>
                  {/* <input
                    min={0}
                    max={dppm}
                    placeholder="Enter amount here..."
                    type={"number"}
                    value={penalty}
                    onChange={(e) => setPenalty(e.target.value)}
                    className="form-control"
                  /> */}
                  <select
                    name="penalty"
                    className="form-control"
                    onChange={(e) => setPenalty(e.target.value)}
                    id="penalty"
                    value={penalty}
                  >
                    <option selected value={-1}>
                      Select An Amount
                    </option>
                    <option value={0}>0</option>
                    <option value={dppm}>{dppm}</option>
                  </select>
                  <br />
                  <label htmlFor="payment">Select Payment Method</label>
                  <div className="d-flex">
                    <div class="form-check m-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        defaultChecked
                        onClick={() => setPenalty_Payment_Method("Cash")}
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        class="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Cash
                      </label>
                    </div>
                    <div class="form-check m-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        onClick={() => setPenalty_Payment_Method("Online")}
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        class="form-check-label"
                        for="flexRadioDefault2"
                      >
                        Online
                      </label>
                    </div>
                    <div class="form-check m-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault3"
                        onClick={() => setPenalty_Payment_Method("Other")}
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        class="form-check-label"
                        for="flexRadioDefault3"
                      >
                        Other
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => setOpenPenaltyDialog(false)}
              >
                Cancel
              </button>
              <button
                type={"submit"}
                className="btn btn-primary"
                onClick={handlePenaltyAdd}
              >
                Add
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {openVisitorDialog && (
        <Dialog
          open={openVisitorDialog}
          onClose={() => setOpenVisitorDialog(false)}
          maxWidth="sm"
        >
          <div className="modal-content">
            <div className="modal-header">
              <span className="">
                Add Visitor for {sessionStorage.getItem("m")}
              </span>
            </div>
            <form onSubmit={handleAddVisitor} className="form">
              <div className="modal-body">
                <label htmlFor="name">Enter Visitor Name</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  id="name"
                  onChange={(e) => setVistorName(e.target.value)}
                  placeholder="Enter Name"
                />
                <br />
                <label htmlFor="amount">Enter Expected Amount</label>
                <br />
                {/* <input
                  className="form-control"
                  type="number"
                  name="amount"
                  value={expAmtVisitor}
                  id="amount"
                  onChange={(e) => setExpAmtVisitor(e.target.value)}
                  placeholder="Enter Expected Amount"
                  min={0}
                  max={collections}
                /> */}
                <input
                  type={"number"}
                  value={expAmtVisitor}
                  disabled
                  className="form-control"
                />
                <br />
                <label htmlFor="paid">Enter Paid Amount</label>
                {/* <input
                  type="number"
                  onChange={(e) => setPaidAmtVisitor(e.target.value)}
                  name="paid"
                  id="paid"
                  placeholder="Enter Paid Amount"
                  min={0}
                  max={collections}
                  className="form-control"
                /> */}
                <select
                  name="paid"
                  id="paid"
                  onChange={(e) => setPaidAmtVisitor(e.target.value)}
                  className={"form-control"}
                >
                  <option value="-1">Select An Amount</option>
                  <option value="0">0</option>
                  <option value={expAmtVisitor}>{expAmtVisitor}</option>
                </select>
                <br />
                <label htmlFor="payment">Select Payment Method</label>
                <div className="d-flex">
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      defaultChecked
                      onClick={() => setPaymentMethod("Cash")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault1"
                    >
                      Cash
                    </label>
                  </div>
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={() => setPaymentMethod("Online")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault2"
                    >
                      Online
                    </label>
                  </div>
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      onClick={() => setPaymentMethod("Other")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault3"
                    >
                      Other
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => setOpenVisitorDialog(false)}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                <button
                  type={"submit"}
                  onClick={handleAddVisitor}
                  className="btn btn-orange"
                >
                  Add Visitor
                </button>
              </div>
            </form>
          </div>
        </Dialog>
      )}
      {openDeleteDialog && (
        <Dialog
          open={openDeleteDialog}
          onClose={() => {
            setOpenDeleteDialog(false);
            setMemberName("");
            setVistorName("");
          }}
          maxWidth="md"
          fullWidth
        >
          <div className="modal-content">
            <div className="modal-body">
              <h5 style={{ color: "red" }}>
                Are you sure you want to delete Visitor {visitorName}?
              </h5>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => {
                  setMemberName("");
                  setVistorName("");
                  setOpenDeleteDialog(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => handleDeleteVistor()}
                className="btn btn-orange"
              >
                Delete
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {openEditPMDialog && (
        <Dialog
          open={openEditPMDialog}
          onClose={() => {
            setOpenEditPMDialog(false);
            setEditPMMember(" ");
            setType("");
          }}
          fullWidth
          maxWidth="sm"
        >
          <div className="modal-content">
            <div className="modal-header">
              <Typography>Edit Payment Method for {editPMMember}</Typography>
            </div>
            <div className="modal-body">
              <select
                className="form-control col-lg-4"
                name="type"
                id="type"
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                <option value="-1" selected>
                  Select A Type
                </option>
                {type === "visitor" ? (
                  <option value="visitor">Vistor Collection</option>
                ) : (
                  <>
                    <option value="member">Member Collection</option>
                    {isPenaltyPaid === true ? (
                      <option value="penalty">Penalty Collection</option>
                    ) : null}
                  </>
                )}
              </select>
              <br />
              {type === "member" && (
                <>
                  <label htmlFor="payment">Select Payment Method</label>
                  <br />
                  <select
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className="form-control"
                    name="payment"
                    id="payment"
                  >
                    <option
                      value="Cash"
                      selected={paymentMethod === "Cash" ? true : false}
                    >
                      Cash
                    </option>
                    <option
                      selected={paymentMethod === "Online" ? true : false}
                      value="Online"
                    >
                      Online
                    </option>
                    <option
                      selected={paymentMethod === "Other" ? true : false}
                      value="Other"
                    >
                      Other
                    </option>
                  </select>
                </>
              )}
              {type === "penalty" && (
                <>
                  <label htmlFor="paymentVisitor">Select Payment Method</label>
                  <br />
                  <select
                    onChange={(e) => setPenalty_Payment_Method(e.target.value)}
                    className="form-control"
                    name="paymentVisitor"
                    id="paymentVisitor"
                  >
                    <option
                      selected={
                        penalty_payment_method === "Cash" ? true : false
                      }
                      value="Cash"
                    >
                      Cash
                    </option>
                    <option
                      selected={
                        penalty_payment_method === "Online" ? true : false
                      }
                      value="Online"
                    >
                      Online
                    </option>
                    <option
                      selected={
                        penalty_payment_method === "Other" ? true : false
                      }
                      value="Other"
                    >
                      Other
                    </option>
                  </select>
                </>
              )}
              {type === "visitor" && (
                <>
                  <label htmlFor="visitorPayment">Select Payment Method</label>
                  <br />
                  <select
                    className="form-control"
                    name="visitorPayment"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    id="visitorPayment"
                  >
                    <option
                      selected={paymentMethod === "Cash" ? true : false}
                      value="Cash"
                    >
                      Cash
                    </option>
                    <option
                      selected={paymentMethod === "Online" ? true : false}
                      value="Online"
                    >
                      Online
                    </option>
                    <option
                      selected={paymentMethod === "Other" ? true : false}
                      value="Other"
                    >
                      Other
                    </option>
                  </select>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                onClick={() => {
                  setOpenEditPMDialog(false);
                  setEditPMMember(" ");
                  setType("");
                }}
                className="btn btn-danger"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSaveEditPM(type)}
                className="btn btn-orange"
              >
                Save Changes
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {/* DELETE PENALTY DIALOG */}
      {openDeletePenaltyDialog && (
        <Dialog
          open={openDeletePenaltyDialog}
          onClose={() => setOpenDeletePenaltyDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <div className="modal-header">
            Delete Penalty for {sessionStorage.getItem("member")}
          </div>
          <div className="modal-body">
            <p>
              Are you sure you want to Delete Penalty for{" "}
              {sessionStorage.getItem("member")}?
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => setOpenDeletePenaltyDialog(false)}
              className="btn btn-outline-danger"
            >
              No
            </button>
            <button
              onClick={() => handlePenaltyDelete()}
              className="btn btn-outline-success"
            >
              Yes
            </button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Date;
