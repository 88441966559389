import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase-config/firebase";
import Footer from "./Footer";
import NavBar from "./NavBar";

const EditProfile = () => {
  const navigate = useNavigate();
  const [bfn, setBfn] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [dcpMeeting, setDcpMeeting] = useState(0);
  const [dppMonth, setDppMonth] = useState(0);

  useEffect(() => {
    if (sessionStorage.length === 0) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getDoc(doc(db, `profile/${sessionStorage.getItem("mail")}`)).then(
      (user) => {
        setBfn(user.data().business_forum_name);
        setChapterName(user.data().chapter_name);
        setDcpMeeting(user.data().def_collection_per_meeting);
        setDppMonth(user.data().def_penalty_per_month);
      }
    );
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    updateDoc(doc(db, `profile/${sessionStorage.getItem("mail")}`), {
      business_forum_name: bfn,
      chapter_name: chapterName,
      def_collection_per_meeting: parseInt(dcpMeeting),
      def_penalty_per_month: parseInt(dppMonth),
    })
      .then(() => {
        toast.success("Profile Updated!", {
          autoClose: 1000,
        });
        navigate(`/leader/dashboard/${sessionStorage.getItem("mail")}`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  return (
    <>
      <NavBar />
      <section id="login-content" className="row">
        <div className="col-10 col-lg-8 mx-auto">
          <div className="row">
            <div className="form-card col-10 col-sm-8 col-md-6 col-lg-5 mx-auto">
              <form onSubmit={handleSubmit}>
                <legend className="border-bottom text-center">
                  Edit Your Profile
                </legend>
                <br />
                <div className="form-group">
                  <label>
                    Business Forum Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={bfn}
                    onChange={(e) => {
                      setBfn(e.target.value);
                    }}
                  />
                  <label>
                    Chapter Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={chapterName}
                    onChange={(e) => {
                      setChapterName(e.target.value);
                    }}
                  />
                  <label>
                    Default Collection Per Meeting
                    <span style={{ color: "red" }}>*</span>
                    {"  "}(in ₹)
                  </label>
                  <input
                    className="form-control"
                    type={"number"}
                    value={dcpMeeting}
                    onChange={(e) => {
                      setDcpMeeting(e.target.value);
                    }}
                  />
                  <label>
                    Default Penalty Per Month
                    <span style={{ color: "red" }}>*</span>
                    {"  "}(in ₹)
                  </label>
                  <input
                    type="number"
                    value={dppMonth}
                    className="form-control"
                    onChange={(e) => setDppMonth(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-orange mt-4">
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default EditProfile;
