import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase-config/firebase";
import { toast } from "react-toastify";
import { doc, getDoc } from "firebase/firestore";
import NavBar from "./NavBar";
import Footer from "./Footer";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [flag, setFlag] = useState(false);
  const [user, setUser] = useState();
  const [internet, setInternet] = useState(true);
  const [state, setState] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    if (email !== "" && password !== "") {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          sessionStorage.setItem("mail", email);
          const docRef = doc(db, `profile/${email}`);
          getDoc(docRef).then((user) => {
            if (user.data().role === "superadmin") {
              // toast.success("SuperAdmin!!");
            } else if (user.data().role === "leader") {
              if (user.data().business_forum_name === "") {
                navigate(`/create-profile/${email}`);
              }
              if (user.data().business_forum_name !== "") {
                navigate(`/leader/dashboard/${email}`);
              }
            } else {
              toast.error("User not found!!", {
                autoClose: 1000,
              });
            }
          });
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              toast.error("Email already in use", {
                autoClose: 1000,
              });
              break;
            case "auth/invalid-email":
              toast.error("Invalid Email", {
                autoClose: 1000,
              });
              break;
            case "auth/weak-password":
              toast.error("Weak Password. Please set a strong password", {
                autoClose: 1000,
              });
              break;
            case "auth/wrong-password":
              toast.error("Wrong Password", {
                autoClose: 1000,
              });
              break;
            case "auth/user-not-found":
              toast.error("User Not Found", {
                autoClose: 1000,
              });
              break;
            default:
              console.log("");
          }
        });
    } else {
      toast.error("Please fill out all fields!!");
    }
  };
  const handleRegister = (e) => {
    e.preventDefault();
    if (email !== "" && password !== "") {
      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          toast.success("User created!!");
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              toast.error("Email already in use");
              break;
            case "auth/invalid-email":
              toast.error("Invalid Email");
              break;
            case "auth/weak-password":
              toast.error("Weak Password. Please set a strong password");
              break;
            case "auth/wrong-password":
              toast.error("Wrong Password");
              break;
            case "auth/user-not-found":
              toast.error("User Not Found");
              break;
            case "auth/user-disabled":
              toast.error("User Disabled by Admin");
              break;
            default:
              console.log("");
          }
        });
    } else {
      toast.error("Please fill out all fields!!");
    }
  };

  const resetPassword = () => {
    navigate("/resetpassword");
  };

  const toggle = () => {
    if (state) {
      document.getElementById("loginpassword").setAttribute("type", "password");
      setState(false);
    } else {
      document.getElementById("loginpassword").setAttribute("type", "text");
      setState(true);
    }
  };
  return (
    <>
      <NavBar />
      <section id="login-content" className="row">
        <div className="col-10 col-lg-8 mx-auto">
          <div className="row">
            <div className="form-card col-10 col-sm-8 col-md-6 col-lg-5 mx-auto">
              <form
                style={{ display: !flag ? "block" : "none" }}
                onSubmit={handleLogin}
              >
                <legend className="border-bottom">Login as Leader</legend>
                <br />
                <br />
                <div className="form-group">
                  <label htmlFor="loginemail">Enter Email Address</label>
                  <input
                    className="form-control"
                    type="email"
                    name="Email"
                    id="loginemail"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <br />
                  <label htmlFor="loginpassword">Enter Password</label>
                  <div className="d-flex">
                    <input
                      type="password"
                      name="Password"
                      id="loginpassword"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      autoComplete=""
                      className="form-control"
                    />
                    <i
                      style={{ fontSize: "15px", color: "grey" }}
                      onClick={() => toggle()}
                      className={
                        state === true
                          ? "mt-1 p-2 bi bi-eye-slash-fill"
                          : "mt-1 p-2 bi bi-eye-fill"
                      }
                    ></i>
                  </div>
                </div>
                <br />
                <div className="text-center">
                  <small
                    onClick={() => navigate("/resetpassword")}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>Forgot Password?</u>
                  </small>
                </div>
                <button type="submit" className="btn btn-orange mt-4">
                  Login
                </button>
              </form>
              <form
                style={{ display: !flag ? "none" : "block" }}
                onSubmit={handleRegister}
              >
                <legend className="border-bottom">Register as Leader</legend>
                <br />
                <br />
                <div className="form-group">
                  <label htmlFor="registeremail">Enter Email Address</label>
                  <input
                    className="form-control"
                    type="email"
                    name="Email"
                    id="registeremail"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <br />
                  <label htmlFor="registerpassword">Enter Password</label>
                  <input
                    type="password"
                    name="Password"
                    id="registerpassword"
                    autoComplete=""
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                  />
                </div>
                <br />
                <div className="text-center">
                  <button onClick={resetPassword}>Forgot Password?</button>
                </div>
                <button type="submit" className="btn btn-orange mt-4">
                  Register
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Login;
