import { Dialog } from "@mui/material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import NavBar from "./NavBar";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase-config/firebase";
import Footer from "./Footer";

const Expense = () => {
  const [flag, setFlag] = useState(false);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [src, setSrc] = useState("");
  const [expenseHeadList, setExpenseHeadList] = useState([]);
  const [expenseHead, setExpenseHead] = useState("");
  const [expenseAmount, setExpenseAmount] = useState(0);
  const toastIdExpenses = useRef(null);
  const toastIdExpensesUpdate = useRef(null);
  const [expensesList, setExpensesList] = useState([]);
  const [oldExpense, setOldExpense] = useState([]);

  useEffect(() => {
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/expenses`
    );
    getDocs(colRef).then((doc) => {
      if (doc.docs.length > 0) {
        setFlag(true);
      }
    });
    const colRef1 = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/expenseheads`
    );
    getDocs(colRef1).then((expenseheads) => {
      let arr = [];
      expenseheads.forEach((doc) => {
        arr.push(doc.id);
      });
      setExpenseHeadList(arr);
    });
  }, []);

  useEffect(() => {
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/expenses`
    );
    onSnapshot(colRef, (snap) => {
      let arr = [];
      snap.forEach((doc) => {
        arr.push({ ...doc.data(), id: doc.id });
      });
      setExpensesList(arr);
    });
  }, [db]);

  let base64url = "";

  const handleImage = (e) => {
    const files = e.target.files;
    if (Math.round(files.item(0).size / 1024) < 1025) {
      if (files.length === 0) return;
      else {
        const file = files[0];
        getBase64(file);
      }
    } else {
      toast.warning("Please upload an image of size less than 1MB!!");
      setImage("");
      document.querySelector("#image").value = "";
    }
  };
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      base64url = reader.result;
      setImage(base64url);
    };
  };
  const handleExpenseSubmit = (e) => {
    e.preventDefault();
    notifyExpenses();
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/expenses/`
    );
    addDoc(colRef, {
      expenseHead: expenseHead,
      expenseAmount: parseInt(expenseAmount),
      description: description,
      image: image,
    })
      .then(() => {
        getDoc(
          doc(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("selected_month")}`
          )
        ).then((d) => {
          updateDoc(
            doc(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${sessionStorage.getItem("selected_month")}`
            ),
            {
              expenses: d.data().expenses + parseInt(expenseAmount),
            }
          );
        });
      })
      .finally(() => {
        setOpen(false);
        setFlag(true);
        setDescription("");
        setExpenseHead("");
        setExpenseAmount(0);
        setImage("");
        updateExpenses();
      });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    notifyExpensesUpdate();
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem(
        "date"
      )}/expenses/${sessionStorage.getItem("expid")}`
    );
    updateDoc(docRef, {
      expenseHead: expenseHead,
      expenseAmount: parseInt(expenseAmount),
      description: description,
      image: image,
    }).then(() => {
      getDoc(
        doc(
          db,
          `profile/${sessionStorage.getItem(
            "mail"
          )}/months/${sessionStorage.getItem("selected_month")}`
        )
      )
        .then((d) => {
          if (parseInt(expenseAmount) > parseInt(oldExpense)) {
            updateDoc(
              doc(
                db,
                `profile/${sessionStorage.getItem(
                  "mail"
                )}/months/${sessionStorage.getItem("selected_month")}`
              ),
              {
                expenses:
                  parseInt(d.data().expenses) +
                  (parseInt(expenseAmount) - parseInt(oldExpense)),
              }
            );
          }
          if (parseInt(expenseAmount) < parseInt(oldExpense)) {
            updateDoc(
              doc(
                db,
                `profile/${sessionStorage.getItem(
                  "mail"
                )}/months/${sessionStorage.getItem("selected_month")}`
              ),
              {
                expenses:
                  parseInt(d.data().expenses) -
                  (parseInt(oldExpense) - parseInt(expenseAmount)),
              }
            );
          }
        })
        .finally(() => {
          updateExpensesUpdate();
          setOpenEdit(false);
          setExpenseAmount(0);
          setExpenseHead("");
          setDescription("");
          setImage("");
        });
    });
  };

  const handleDelete = () => {
    notifyExpensesDelete();
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/expenses/`
    );
    getDocs(colRef).then((docs) => {
      docs.forEach((d) => {
        if (d.id === sessionStorage.getItem("expid")) {
          deleteDoc(
            doc(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${sessionStorage.getItem(
                "selected_month"
              )}/dates/${sessionStorage.getItem("date")}/expenses/${d.id}`
            )
          )
            .then(() => {
              getDoc(
                doc(
                  db,
                  `profile/${sessionStorage.getItem(
                    "mail"
                  )}/months/${sessionStorage.getItem("selected_month")}`
                )
              ).then((d) => {
                updateDoc(
                  doc(
                    db,
                    `profile/${sessionStorage.getItem(
                      "mail"
                    )}/months/${sessionStorage.getItem("selected_month")}`
                  ),
                  {
                    expenses:
                      d.data().expenses -
                      parseInt(sessionStorage.getItem("expa")),
                  }
                );
              });
            })
            .finally(() => {
              updateExpensesDelete();
              setOpenDelete(false);
            });
        }
      });
    });
    // deleteDoc(docRef)
    //   .then(() => {
    //     getDoc(
    //       doc(
    //         db,
    //         `profile/${sessionStorage.getItem(
    //           "mail"
    //         )}/months/${sessionStorage.getItem("selected_month")}`
    //       )
    //     ).then((d) => {
    //       updateDoc(
    //         doc(
    //           db,
    //           `profile/${sessionStorage.getItem(
    //             "mail"
    //           )}/months/${sessionStorage.getItem("selected_month")}`
    //         ),
    //         {
    //           expenses:
    //             d.data().expenses - parseInt(sessionStorage.getItem("expa")),
    //         }
    //       );
    //     });
    //   })
    //   .finally(() => {
    //     updateExpensesDelete();
    //     setOpenDelete(false);
    //   });
  };

  const notifyExpenses = () => {
    toastIdExpenses.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Adding Expense
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };

  const updateExpenses = () =>
    toast.update(toastIdExpenses.current, {
      render: "Expense Added Successfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });

  const notifyExpensesDelete = () => {
    toastIdExpenses.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Deleting Expense
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };

  const updateExpensesDelete = () =>
    toast.update(toastIdExpenses.current, {
      render: "Expense Deleted Successfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });
  const notifyExpensesUpdate = () => {
    toastIdExpensesUpdate.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Updating Expense
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };
  const updateExpensesUpdate = () =>
    toast.update(toastIdExpensesUpdate.current, {
      render: "Expense Updated Successfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });

  return (
    <>
      <NavBar />
      <section id="expense" style={{ marginRight: "0rem" }}>
        {flag === true ? (
          <div>
            <div className="col-10 col-lg-6 mx-auto text-center">
              <div className="d-flex justify-content-between border-bottom p-1">
                <h3>Manage Expense for {sessionStorage.getItem("date")}</h3>
                <button
                  className="btn btn-outline-orange"
                  onClick={() => setOpen(true)}
                >
                  Add Expense
                </button>
              </div>
              <div>
                <Link
                  to={{
                    pathname: `https://venue.fairbahikhata.com/dashboard/expenseheads/${sessionStorage.getItem(
                      "mail"
                    )}`,
                  }}
                  target="_blank"
                  onClick={() => {
                    localStorage.setItem(
                      "ourl",
                      `https://venue.fairbahikhata.com/dashboard/expenseheads/${sessionStorage.getItem(
                        "mail"
                      )}`
                    );
                  }}
                >
                  Want to add a new Expense Head?
                </Link>
              </div>
              <br />
              <div style={{ overflowX: "auto" }}>
                <table className="table table-responsive-sm table-hover table-striped">
                  <thead>
                    <td>Sr. No.</td>
                    <td>Expense Head Name</td>
                    <td>Amount</td>
                    <td>Description</td>
                    <td>Image</td>
                    <td>Actions</td>
                  </thead>
                  <tbody>
                    {expensesList.map((expenses, i) => (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{expenses.expenseHead}</td>
                        <td>{expenses.expenseAmount}</td>
                        <td>{expenses.description}</td>
                        <td>
                          <button
                            onClick={() => {
                              setSrc(expenses.image);
                              setOpenImage(true);
                            }}
                            className="btn btn-outline-orange"
                          >
                            <i className="bi bi-box-arrow-up-right"></i>
                          </button>
                        </td>
                        <td className="m-2">
                          <div className="btn-group">
                            <button
                              onClick={() => {
                                setDescription(expenses.description);
                                setExpenseAmount(expenses.expenseAmount);
                                setExpenseHead(expenses.expenseHead);
                                setImage(expenses.image);
                                setOldExpense(expenses.expenseAmount);
                                sessionStorage.setItem("expid", expenses.id);
                                setOpenEdit(true);
                              }}
                              className="btn btn-outline-orange"
                            >
                              <i className="bi bi-pencil"></i>
                            </button>
                            <button
                              onClick={() => {
                                sessionStorage.setItem(
                                  "exp",
                                  expenses.expenseHead
                                );
                                sessionStorage.setItem(
                                  "expa",
                                  expenses.expenseAmount
                                );
                                sessionStorage.setItem("expid", expenses.id);
                                setOpenDelete(true);
                              }}
                              className="btn btn-outline-orange"
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="container bordered mt-2">
              <h4 className="border-bottom text-center">Add Expenses</h4>
              <form
                onSubmit={handleExpenseSubmit}
                className="form offset-lg-4 col-lg-4 mb-4"
              >
                <div className="form-group">
                  <label htmlFor="expenseHead">Choose Expense Head</label>
                  <select
                    className="form-control"
                    name="expenseHead"
                    value={expenseHead}
                    id="expenseHead"
                    onChange={(e) => setExpenseHead(e.target.value)}
                  >
                    <option defaultChecked value="def">
                      Select Expense Head
                    </option>
                    {expenseHeadList.map((expenseHead) => (
                      <option value={expenseHead}>{expenseHead}</option>
                    ))}
                  </select>
                  <br />
                  <label htmlFor="expense">Enter Expense Amount</label>
                  <input
                    type={"number"}
                    value={expenseAmount}
                    min={0}
                    className="form-control"
                    placeholder="Enter amount"
                    onChange={(e) => setExpenseAmount(e.target.value)}
                  />
                  <br />
                  <label htmlFor="desc">Enter Description</label>
                  <textarea
                    cols={50}
                    rows={3}
                    value={description}
                    type="text"
                    className="form-control"
                    placeholder="Enter Short Description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <br />
                  <label htmlFor="image">Upload Image</label>
                  <input
                    type="file"
                    name="img"
                    id="image"
                    className="form-control"
                    accept="image/png, image/jpeg"
                    onChange={handleImage}
                  />
                  <p style={{ color: "red", fontSize: "14px" }}>
                    Accepted File Formats: PNG & JPG only <br /> Maximum size of
                    image: 1MB
                  </p>
                </div>
                <div className="m-2">
                  <button
                    type={"submit"}
                    className="btn form-control m-2 btn-outline-orange"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </section>
      <Footer />
      {/* Image */}
      {openImage && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={openImage}
          onClose={() => setOpenImage(false)}
        >
          <img
            src={src}
            className="rounded"
            alt="No Image Added"
            style={{ height: "500px" }}
          />
        </Dialog>
      )}
      {/* Add expense Dialog */}
      {open && (
        <Dialog
          fullWidth
          maxWidth={"md"}
          open={open}
          onClose={() => setOpen(false)}
        >
          <div className="container bordered mt-2">
            <h4 className="border-bottom text-center">Add Expense</h4>
            <form
              onSubmit={handleExpenseSubmit}
              className="form offset-lg-4 col-lg-4 mb-4"
            >
              <div className="form-group">
                <label htmlFor="expenseHead">Choose Expense Head</label>
                <select
                  className="form-control"
                  name="expenseHead"
                  value={expenseHead}
                  id="expenseHead"
                  onChange={(e) => setExpenseHead(e.target.value)}
                >
                  <option defaultChecked value="def">
                    Select Expense Head
                  </option>
                  {expenseHeadList
                    // .filter(
                    //   (item) =>
                    //     !expensesList.some((t) => item.includes(t.expenseHead))
                    // )
                    .map((expenseHead) => (
                      <option value={expenseHead}>{expenseHead}</option>
                    ))}
                </select>
                <br />
                <label htmlFor="expense">Enter Expense Amount</label>
                <input
                  type={"number"}
                  value={expenseAmount}
                  min={0}
                  className="form-control"
                  placeholder="Enter amount"
                  onChange={(e) => setExpenseAmount(e.target.value)}
                />
                <br />
                <label htmlFor="desc">Enter Description</label>
                <textarea
                  cols={50}
                  rows={3}
                  value={description}
                  type="text"
                  className="form-control"
                  placeholder="Enter Short Description"
                  onChange={(e) => setDescription(e.target.value)}
                />
                <br />
                <label htmlFor="image">Upload Image</label>
                <input
                  type="file"
                  name="img"
                  id="image"
                  className="form-control"
                  accept="image/png, image/jpeg"
                  onChange={handleImage}
                />
                <p style={{ color: "red", fontSize: "14px" }}>
                  Accepted Formats: PNG & JPEG/JPG ONLY <br />
                  Maximum Size of Image: 1MB
                </p>
              </div>
              <div className="d-flex m-2">
                <button
                  type={"button"}
                  onClick={() => setOpen(false)}
                  className="btn form-control m-2 btn-danger"
                >
                  Cancel
                </button>
                <button
                  type={"submit"}
                  className="btn form-control m-2 btn-outline-orange"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Dialog>
      )}
      {/* Delete expense Dialog */}
      {openDelete && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openDelete}
          onClose={() => setOpenDelete(false)}
        >
          <div class="modal-content">
            <div class="modal-body">
              <p>
                Are you sure you want to delete {sessionStorage.getItem("exp")}
              </p>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-outline-danger"
                onClick={() => setOpenDelete(false)}
              >
                Cancel
              </button>
              <button
                class="btn btn-outline-orange"
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {/* Edit expense Dialog */}
      {openEdit && (
        <Dialog
          fullWidth
          maxWidth={"md"}
          open={openEdit}
          onClose={() => setOpenEdit(false)}
        >
          <div className="container bordered mt-2">
            <h4 className="border-bottom text-center">Edit Expense</h4>
            <form
              onSubmit={handleEdit}
              className="form offset-lg-4  col-lg-4 mb-4"
            >
              <div className="form-group">
                <label htmlFor="expenseHead">Choose Expense Head</label>
                <select
                  className="form-control"
                  name="expenseHead"
                  value={expenseHead}
                  id="expenseHead"
                  onChange={(e) => setExpenseHead(e.target.value)}
                >
                  <option defaultChecked value="def">
                    Select Expense Head
                  </option>
                  {expenseHeadList
                    // .filter(
                    //   (item) =>
                    //     !expensesList.some((t) => item.includes(t.expenseHead))
                    // )
                    .map((expenseHead) => (
                      <option value={expenseHead}>{expenseHead}</option>
                    ))}
                </select>
                <br />
                <label htmlFor="expense">Enter Expense Amount</label>
                <input
                  type={"number"}
                  value={expenseAmount}
                  min={0}
                  className="form-control"
                  placeholder="Enter amount"
                  onChange={(e) => setExpenseAmount(e.target.value)}
                />
                <br />
                <label htmlFor="desc">Enter Description</label>
                <textarea
                  cols={50}
                  rows={3}
                  value={description}
                  type="text"
                  className="form-control"
                  placeholder="Enter Short Description"
                  onChange={(e) => setDescription(e.target.value)}
                />
                <br />
                <label htmlFor="image">Upload Image</label>
                <input
                  type="file"
                  name="img"
                  id="image"
                  className="form-control"
                  accept="image/png, image/jpeg"
                  onChange={handleImage}
                />
                <p style={{ color: "red", fontSize: "14px" }}>
                  Accepted Formats: PNG & JPEG/JPG ONLY <br />
                  Maximum Size of Image: 1MB
                </p>
              </div>
              <div className="d-flex m-2">
                <button
                  type={"button"}
                  onClick={() => {
                    setOpenEdit(false);
                    setExpenseAmount(0);
                    setDescription("");
                    setImage("");
                    setExpenseHead("");
                  }}
                  className="btn form-control m-2 btn-outline-orange"
                >
                  Cancel
                </button>
                <button
                  type={"submit"}
                  className="btn form-control m-2 btn-outline-orange"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Expense;
