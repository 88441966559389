import { Delete, Edit } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { db } from "../firebase-config/firebase";
import Footer from "./Footer";
import NavBar from "./NavBar";

const OtherIncomes = () => {
  const [amount, setAmount] = useState();
  const [description, setDescription] = useState("");
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [oldIncome, setOldIncome] = useState();
  const [incomesList, setIncomesList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("Other");

  const toastIdExpenses = useRef(null);
  const toastIdExpensesUpdate = useRef(null);

  useEffect(() => {
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/incomes`
    );
    let arr = [];
    onSnapshot(colRef, (snap) => {
      if (snap.docs.length === 0) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    });
  }, []);

  useEffect(() => {
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/incomes`
    );
    onSnapshot(colRef, (snap) => {
      let arr = [];
      snap.forEach((doc) => {
        arr.push({ ...doc.data(), id: doc.id });
      });
      setIncomesList(arr);
    });
  }, [db]);

  const handleIncomeSubmit = (e) => {
    e.preventDefault();
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/incomes`
    );
    addDoc(colRef, {
      amount: parseInt(amount),
      description: description,
      paymentMethod: paymentMethod,
    }).then(() => {
      toast.success("Income Added Successfully");
      window.location.reload();
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    notifyExpensesUpdate();
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem(
        "date"
      )}/incomes/${sessionStorage.getItem("incid")}`
    );
    updateDoc(docRef, {
      amount: parseInt(amount),
      description: description,
    })
      .then(() => {
        getDoc(
          doc(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("selected_month")}`
          )
        );
      })
      .finally(() => {
        updateExpensesUpdate();
        setOpenEdit(false);
      });
  };

  const handleDelete = () => {
    notifyExpensesDelete();
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${sessionStorage.getItem("date")}/incomes/`
    );
    getDocs(colRef).then((docs) => {
      docs.forEach((d) => {
        if (d.id === sessionStorage.getItem("incid")) {
          deleteDoc(
            doc(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${sessionStorage.getItem(
                "selected_month"
              )}/dates/${sessionStorage.getItem("date")}/incomes/${d.id}`
            )
          ).finally(() => {
            updateExpensesDelete();
            setOpenDelete(false);
          });
        }
      });
    });
  };

  const notifyExpenses = () => {
    toastIdExpenses.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Adding Income
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };

  const updateExpenses = () =>
    toast.update(toastIdExpenses.current, {
      render: "Income Added Successfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });

  const notifyExpensesDelete = () => {
    toastIdExpenses.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Deleting Income
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };

  const updateExpensesDelete = () =>
    toast.update(toastIdExpenses.current, {
      render: "Income Deleted Successfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });
  const notifyExpensesUpdate = () => {
    toastIdExpensesUpdate.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Updating Income
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };
  const updateExpensesUpdate = () =>
    toast.update(toastIdExpensesUpdate.current, {
      render: "Income Updated Successfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });

  return (
    <>
      <NavBar />
      <section className="row" id="incomes">
        {flag === false ? (
          <>
            <div>
              <div className="container bordered mt-2">
                <h4 className="border-bottom text-center">Add Income</h4>
                <form
                  onSubmit={handleIncomeSubmit}
                  className="form offset-lg-4 col-lg-4 mb-4"
                >
                  <div className="form-group">
                    <label htmlFor="expense">Enter Income Amount</label>
                    <input
                      type={"number"}
                      value={amount}
                      min={0}
                      className="form-control"
                      placeholder="Enter amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <br />
                    <label htmlFor="desc">Enter Description</label>
                    <textarea
                      cols={50}
                      rows={3}
                      value={description}
                      type="text"
                      className="form-control"
                      placeholder="Enter Short Description"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <label htmlFor="payment">Select Payment Method</label>
                    <div className="d-flex">
                      <div class="form-check m-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          onClick={() => setPaymentMethod("Cash")}
                        />
                        <label
                          style={{ cursor: "pointer" }}
                          class="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Cash
                        </label>
                      </div>
                      <div class="form-check m-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          onClick={() => setPaymentMethod("Online")}
                        />
                        <label
                          style={{ cursor: "pointer" }}
                          class="form-check-label"
                          for="flexRadioDefault2"
                        >
                          Online
                        </label>
                      </div>
                      <div class="form-check m-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          defaultChecked
                          onClick={() => setPaymentMethod("Other")}
                        />
                        <label
                          style={{ cursor: "pointer" }}
                          class="form-check-label"
                          for="flexRadioDefault3"
                        >
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="m-2">
                    <button
                      type={"submit"}
                      className="btn form-control m-2 btn-outline-orange"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : (
          <div>
            <div className="col-10 col-lg-6 mx-auto text-center">
              <div className="d-flex justify-content-between border-bottom p-1">
                <h3>Manage Incomes for {sessionStorage.getItem("date")}</h3>
                <button
                  className="btn btn-outline-orange"
                  onClick={() => setOpen(true)}
                >
                  Add Income
                </button>
              </div>
              <div style={{ overflowX: "auto" }}>
                <table className="table table-responsive-sm table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Amount</th>
                      <th>Description</th>
                      <th>Payment Method</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {incomesList.map((income, i) => (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{income.amount}</td>
                        <td>{income.description}</td>
                        <td>
                          {income.paymentMethod === undefined
                            ? "Other"
                            : income.paymentMethod}
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              onClick={() => {
                                setDescription(income.description);
                                setAmount(income.amount);
                                setOldIncome(income.amount);
                                setPaymentMethod(income.paymentMethod);
                                sessionStorage.setItem("incid", income.id);
                                setOpenEdit(true);
                              }}
                              className="btn btn-outline-orange"
                            >
                              <Edit />
                            </button>
                            <button
                              onClick={() => {
                                sessionStorage.setItem("incid", income.id);
                                setOpenDelete(true);
                              }}
                              className="btn btn-outline-orange"
                            >
                              <Delete />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
      {open && (
        <Dialog
          fullWidth
          maxWidth={"md"}
          open={open}
          onClose={() => setOpen(false)}
        >
          <div className="container bordered mt-2">
            <h4 className="border-bottom text-center">Add New Income</h4>
            <form
              onSubmit={handleIncomeSubmit}
              className="form offset-lg-4 col-lg-4 mb-4"
            >
              <div className="form-group">
                <label htmlFor="expense">Enter Income Amount</label>
                <input
                  type={"number"}
                  value={amount}
                  min={0}
                  className="form-control"
                  placeholder="Enter amount"
                  onChange={(e) => setAmount(e.target.value)}
                />
                <br />
                <label htmlFor="desc">Enter Description</label>
                <textarea
                  cols={50}
                  rows={3}
                  value={description}
                  type="text"
                  className="form-control"
                  placeholder="Enter Short Description"
                  onChange={(e) => setDescription(e.target.value)}
                />
                <br />
                <label htmlFor="payment">Select Payment Method</label>
                <div className="d-flex">
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      onClick={() => setPaymentMethod("Cash")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault1"
                    >
                      Cash
                    </label>
                  </div>
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={() => setPaymentMethod("Online")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault2"
                    >
                      Online
                    </label>
                  </div>
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      defaultChecked
                      onClick={() => setPaymentMethod("Other")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault3"
                    >
                      Other
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex m-2">
                <button
                  type={"button"}
                  onClick={() => setOpen(false)}
                  className="btn form-control m-2 btn-danger"
                >
                  Cancel
                </button>
                <button
                  type={"submit"}
                  className="btn form-control m-2 btn-outline-orange"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Dialog>
      )}
      {/* Delete handleIncomeSubmit Dialog */}
      {openDelete && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openDelete}
          onClose={() => setOpenDelete(false)}
        >
          <div class="modal-content">
            <div class="modal-body">
              <p>Are you sure you want to delete this income?</p>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-outline-danger"
                onClick={() => setOpenDelete(false)}
              >
                Cancel
              </button>
              <button
                class="btn btn-outline-orange"
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {/* Edit income Dialog */}
      {openEdit && (
        <Dialog
          fullWidth
          maxWidth={"md"}
          open={openEdit}
          onClose={() => setOpenEdit(false)}
        >
          <div className="container bordered mt-2">
            <h4 className="border-bottom text-center">Edit Income</h4>
            <form
              onSubmit={handleEdit}
              className="form offset-lg-4  col-lg-4 mb-4"
            >
              <div className="form-group">
                <label htmlFor="expense">Enter Income Amount</label>
                <input
                  type={"number"}
                  value={amount}
                  min={0}
                  className="form-control"
                  placeholder="Enter amount"
                  onChange={(e) => setAmount(e.target.value)}
                />
                <br />
                <label htmlFor="desc">Enter Description</label>
                <textarea
                  cols={50}
                  rows={3}
                  value={description}
                  type="text"
                  className="form-control"
                  placeholder="Enter Short Description"
                  onChange={(e) => setDescription(e.target.value)}
                />
                <label htmlFor="payment">Select Payment Method</label>
                <div className="d-flex">
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      defaultChecked={paymentMethod === "Cash" ? true : false}
                      onClick={() => setPaymentMethod("Cash")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault1"
                    >
                      Cash
                    </label>
                  </div>
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      defaultChecked={paymentMethod === "Online" ? true : false}
                      onClick={() => setPaymentMethod("Online")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault2"
                    >
                      Online
                    </label>
                  </div>
                  <div class="form-check m-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      defaultChecked={paymentMethod === "Other" ? true : false}
                      onClick={() => setPaymentMethod("Other")}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      class="form-check-label"
                      for="flexRadioDefault3"
                    >
                      Other
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex m-2">
                <button
                  type={"button"}
                  onClick={() => {
                    setOpenEdit(false);
                    setAmount(0);
                    setDescription("");
                  }}
                  className="btn form-control m-2 btn-outline-orange"
                >
                  Cancel
                </button>
                <button
                  type={"submit"}
                  className="btn form-control m-2 btn-outline-orange"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default OtherIncomes;
