import { Dialog, Tooltip } from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase-config/firebase";
import Footer from "./Footer";
import NavBar from "./NavBar";

const ExpenseHeads = () => {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false);
  const [expenseHead, setExpenseHead] = useState("");
  const [expenseHeadList, setExpenseHeadList] = useState([]);
  const [deleteExpense, setDeleteExpense] = useState("");
  const [newExpense, setNewExpense] = useState("");
  const [oldExpense, setOldExpense] = useState("");
  const [editExpense, setEditExpense] = useState("");
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (sessionStorage.length === 0) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const collectionRef = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/expenseheads`
    );
    onSnapshot(collectionRef, (snap) => {
      if (snap.docs.length === 0) {
        setFlag(true);
      }
    });
  }, []);

  useEffect(() => {
    const collectionRef = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/expenseheads`
    );
    onSnapshot(collectionRef, (snap) => {
      let arr = [];
      snap.docs.forEach((doc) => {
        arr.push(doc.data().expenseHead);
      });
      setExpenseHeadList(arr);
    });
  }, [db]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem("mail")}/expenseheads/${expenseHead}`
    );
    setDoc(docRef, {
      expenseHead: expenseHead,
    })
      .then(() => {
        toast.success("Expense Head Saved", {
          autoClose: 1000,
        });
        setFlag(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleUpdate = () => {
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem("mail")}/expenseheads/${oldExpense}`
    );
    deleteDoc(docRef);
    setDoc(
      doc(
        db,
        `profile/${sessionStorage.getItem("mail")}/expenseheads/${editExpense}`
      ),
      {
        expenseHead: editExpense,
      }
    )
      .then(() => {
        toast.success("Edited successfully", {
          autoClose: 1000,
        });
        setNewExpense("");
        setOldExpense("");
        setOpenEditDialog(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleDelete = () => {
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem("mail")}/expenseheads/${deleteExpense}`
    );
    deleteDoc(docRef)
      .then(() => {
        toast.success("Expense Head Deleted!",{
          autoClose: 1000,
        });
        setOpenDeleteDialog(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleAdd = () => {
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem("mail")}/expenseheads/${newExpense}`
    );
    setDoc(docRef, {
      expenseHead: newExpense,
    })
      .then(() => {
        toast.success("New Expense Head Added!!", {
          autoClose: 1000,
        });
        setNewExpense("");
        setOpenNewDialog(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <>
      <NavBar />
      <section id="expense_heads" className="row">
        {flag === true ? (
          <>
            <div className="col-10 col-lg-10 mx-auto">
              <h4 className="text-center">Expense Heads</h4>
              <form
                onSubmit={handleSubmit}
                className="form-card border p-3 col-10 col-sm-8 col-md-6 col-lg-5 mx-auto"
              >
                <legend className="border-bottom">
                  Enter Expense Head Name
                </legend>
                <br />
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setExpenseHead(e.target.value)}
                  placeholder="Enter text here..."
                />
                <br />
                <button type={"submit"} className="btn btn-orange">
                  Save
                </button>
              </form>
            </div>
          </>
        ) : (
          <div className="col-10 col-lg-6 mx-auto text-center">
            <table className="table table-responsive-sm table-hover table-striped">
              <thead>
                <tr>
                  <th className="pb-3">Sr. No.</th>
                  <th className="pb-3">Expense Head</th>
                  <th>
                    <div className="btn-group">
                      <button
                        onClick={() => setOpenNewDialog(true)}
                        type="button"
                        className="btn btn-outline-orange"
                      >
                        New
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {expenseHeadList.map((expense, i) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    <td>{expense}</td>
                    <td>
                      <div className="btn-group" role={"group"}>
                        <button
                          type="button"
                          className="btn btn-outline-orange"
                          onClick={() => {
                            setOldExpense(expense);
                            setEditExpense(expense);
                            setOpenEditDialog(true);
                          }}
                        >
                          <i className="bi bi-pencil"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-orange"
                          onClick={() => {
                            setDeleteExpense(expense);
                            setOpenDeleteDialog(true);
                          }}
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </section>
      <Footer />
      {/* NEW EXPENSE */}
      {openNewDialog && (
        <Dialog
          open={openNewDialog}
          onClose={() => setOpenNewDialog(false)}
          fullWidth
          maxWidth="sm"
        >
          <div className="modal-content">
            <div className="modal-body">
              <p>New Expense Head</p>
              <input
                type="text"
                value={newExpense}
                onChange={(e) => {
                  setNewExpense(e.target.value);
                }}
                placeholder="Enter expense head here..."
                className="form-control"
              />
            </div>
            <div className="modal-footer">
              <button
                onClick={() => setOpenNewDialog(false)}
                className="btn btn-danger"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={handleAdd}
                data-bs-dismiss="modal"
              >
                Save
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {/* UPDATE EXPENSE */}
      {openEditDialog && (
        <Dialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          fullWidth
          maxWidth="sm"
        >
          <div className="modal-content">
            <div className="modal-body">
              <p>Edit Expense</p>
              <input
                type="text"
                onChange={(e) => {
                  setEditExpense(e.target.value);
                }}
                value={editExpense}
                placeholder="Enter new name here..."
                className="form-control"
              />
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => setOpenEditDialog(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleUpdate();
                }}
              >
                Update
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {/* DELETE EXPENSE */}
      {openDeleteDialog && (
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          fullWidth
          maxWidth="sm"
        >
          <div className="modal-content">
            <div className="modal-body">
              <p>Are you sure you want to delete {deleteExpense}</p>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => setOpenDeleteDialog(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ExpenseHeads;
