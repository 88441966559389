import { Dialog, Tooltip } from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SpinnerCircular } from "spinners-react";
import { db } from "../firebase-config/firebase";
import Logo from "../favicon-32x32.png";
import Footer from "./Footer";
import NavBar from "./NavBar";

const Months = () => {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false);
  const [month, setMonth] = useState("");
  const [membersLength, setMembersLength] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [monthsList, setMonthsList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const toastId = useRef(null);
  const [summary, setSummary] = useState([]);
  const [visitorCount, setVisitorCount] = useState(0);
  const [expenses, setExpenses] = useState(parseInt(0));
  const [loading, setLoading] = useState(false);
  const [opencfd, setOpenCFD] = useState(false);
  const [cfa, setCFA] = useState(0);
  const [collections, setCollections] = useState(0);
  const [monthName, setMonthName] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openVisitorsDialog, setOpenVisitorsDialog] = useState(false);
  const [visitorsList, setVisitorsList] = useState([]);
  const [internet, setInternet] = useState(true);
  const [openNewMonthDialog, setOpenNewMonthDialog] = useState(false);

  useEffect(() => {
    if (sessionStorage.length === 0) {
      navigate("/");
    }
    const docRef = doc(db, `profile/${sessionStorage.getItem("mail")}`);
    getDoc(docRef).then((doc) => {
      setCollections(doc.data().def_collection_per_meeting);
    });
  }, []);

  //COLLECTION
  // useEffect(() => {
  //   const colRef = collection(
  //     db,
  //     `profile/${sessionStorage.getItem("mail")}/months/`
  //   );
  //   const q = query(colRef, orderBy("timestamp", "desc"));
  //   let count = 0;
  //   getDocs(q).then((months) => {
  //     months.docs.forEach((month) => {
  //       const collectionRef = collection(
  //         db,
  //         `profile/${sessionStorage.getItem("mail")}/months/${month.id}/members`
  //       );
  //       getDocs(collectionRef)
  //         .then((member) => {
  //           let actualCount = 0;
  //           let defaulterCount = 0;
  //           let obj = {};
  //           member.forEach((doc) => {
  //             if (doc.data().paid_coll !== undefined) {
  //               actualCount += doc.data().paid_coll;
  //             }
  //             if (
  //               doc.data().paid_coll < sessionStorage.getItem("col") ||
  //               doc.data().paid_coll === undefined
  //             ) {
  //               defaulterCount += 1;
  //             }
  //           });
  //           obj["collection"] = actualCount;
  //           obj["defaulterCount"] = defaulterCount;
  //           obj["id"] = month.id;
  //           console.log(obj);
  //           sessionStorage.setItem(`defaulter_${month.id}`, defaulterCount);
  //           sessionStorage.setItem(`collection_${month.id}`, actualCount);
  //           if (
  //             sessionStorage.getItem(`defaulter_${month.id}`).length === 1 &&
  //             sessionStorage.getItem(`collection_${month.id}`).length === 1
  //           ) {
  //             console.log(
  //               sessionStorage.getItem(`defaulter_${month.id}`).length
  //             );
  //             count = count + 1;
  //           }
  //         })
  //         .finally(() => {
  //           if (count === monthsList.length) {
  //             setShow(true);
  //             console.log(true);
  //           } else {
  //             console.log(false);
  //           }
  //         });
  //     });
  //   });
  // }, []);

  useEffect(() => {
    getDocs(
      collection(db, `profile/${sessionStorage.getItem("mail")}/months`)
    ).then((docs) => {
      if (docs.docs.length === 0) {
        setFlag(true);
      }
    });
    getDocs(
      collection(db, `profile/${sessionStorage.getItem("mail")}/members`)
    ).then((snap) => {
      let arr = [];
      snap.docs.forEach((doc) => {
        arr.push(doc.data().member_name);
      });
      setMembersList(arr);
      setMembersLength(snap.docs.length);
    });
  }, []);

  // const fetchVisitors = (month) => {
  //   if (month !== "") {
  //     const colRef = collection(
  //       db,
  //       `profile/${sessionStorage.getItem("mail")}/months/${month}/dates`
  //     );
  //     console.log(colRef.path);
  //     // const q = query(colRef, orderBy("timestamp", "asc"));
  //     onSnapshot(colRef, (dates) => {
  //       let arr = [];
  //       dates.forEach((date) => {
  //         const colRef = collection(
  //           db,
  //           `profile/${sessionStorage.getItem("mail")}/months/${month}/dates/${
  //             date.id
  //           }/members`
  //         );
  //         console.log(colRef.id);
  //         getDocs(colRef)
  //           .then((members) => {
  //             members.forEach((member) => {
  //               console.log(member.id);
  //               if (member.data().type === "visitor") {
  //                 arr.push({ ...member.data(), date: date.id });
  //                 console.log(member.data());
  //               }
  //             });
  //           })
  //           .finally(() => {
  //             console.log(arr);
  //             setVisitorsList(arr);
  //             setOpenVisitorsDialog(true);
  //           });
  //       });
  //     });
  //   }
  // };

  const fetchMonths = () => {
    setLoading(true);
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/months`
    );
    const q = query(colRef, orderBy("timestamp", "desc"));
    let v1 = 0;
    getDocs(q).then((snap) => {
      let arr = [];
      let a1 = 0;
      let d1 = 0;
      let p1 = 0;
      let p2 = 0;
      snap.forEach((doc) => {
        let v1 = 0;
        let visitorColl = 0;
        let e1 = 0;
        let i1 = 0;
        let actualCount = 0;
        let defaulterCount = 0;
        const collectionRef = collection(
          db,
          `profile/${sessionStorage.getItem("mail")}/months/${doc.id}/members`
        );
        const colRef = collection(
          db,
          `profile/${sessionStorage.getItem("mail")}/months/${doc.id}/dates`
        );
        getDocs(colRef)
          .then((dates) => {
            dates.forEach((date) => {
              const colRef1 = collection(
                db,
                `profile/${sessionStorage.getItem("mail")}/months/${
                  doc.id
                }/dates/${date.id}/expenses`
              );
              getDocs(colRef1)
                .then((expenses) => {
                  expenses.forEach((expense) => {
                    e1 += parseInt(expense.data().expenseAmount);
                    // e1 += expense.data().expenseAmount;
                  });
                })
                .finally(() => {
                  setExpenses(e1);
                });
              const colRef2 = collection(
                db,
                `profile/${sessionStorage.getItem("mail")}/months/${
                  doc.id
                }/dates/${date.id}/incomes`
              );
              getDocs(colRef2).then((incomes) => {
                incomes.forEach((i) => {
                  i1 += parseInt(i.data().amount);
                });
              });
            });
            dates.forEach((date) => {
              const colRef = collection(
                db,
                `profile/${sessionStorage.getItem("mail")}/months/${
                  doc.id
                }/dates/${date.id}/members`
              );
              getDocs(colRef).then((members) => {
                members.forEach((member) => {
                  if (member.data().type === "visitor") {
                    v1 += 1;
                    actualCount += member.data().paid_coll;
                  }
                });
              });
            });
          })
          .finally(() => {
            getDocs(collectionRef).then((members) => {
              let p = 0;
              let pp = 0;
              members.forEach((doc) => {
                if (doc.data().paid_coll !== undefined) {
                  actualCount += doc.data().paid_coll;
                }
                if (
                  doc.data().paid_coll < doc.data().amount ||
                  doc.data().paid_coll === undefined ||
                  doc.data().paid_penalty < doc.data().expected_penalty
                ) {
                  defaulterCount += 1;
                }
                if (doc.data().expected_penalty !== undefined) {
                  p += doc.data().expected_penalty;
                }
                if (doc.data().paid_penalty !== undefined) {
                  pp += doc.data().paid_penalty;
                }
              });
              arr.push({
                ...doc.data(),
                actualCount: actualCount,
                expenses: e1,
                income: i1,
                defaulterCount: defaulterCount,
                expected_penalty: p,
                paid_penalty: pp,
                // members: membersLength,
                visitorCount: v1,
              });
              a1 = actualCount;
              d1 = defaulterCount;

              if (arr.length === snap.docs.length) {
                setMonthsList(arr);
                setLoading(false);
              }
            });
          });
      });
    });
  };

  useEffect(() => {
    fetchMonths();
    setTimeout(() => {
      fetchMonths();
      setLoading(false);
    })
  }, []);

  const handleAdd = () => {
    notify();
    var month =
      startDate.toLocaleString("default", { month: "long" }) +
      " " +
      startDate.getFullYear();
    setMonth(month);
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem("mail")}/months/${month}`
    );
    setDoc(docRef, {
      month: month,
      collections: 0,
      expenses: 0,
      members: membersLength,
      visitors: 0,
      defaulters: membersLength,
      carry_forward: 0,
      timestamp: serverTimestamp(),
    }).then(() => {
      var inc = 0;
      membersList.forEach((document) => {
        const docRef = doc(
          db,
          `profile/${sessionStorage.getItem(
            "mail"
          )}/months/${month}/members/${document}`
        );
        setDoc(docRef, {
          member_name: document,
          paid_coll: 0,
        })
          .then(() => {
            inc = inc + 1;
          })
          .finally(() => {
            if (inc === membersList.length - 1) {
              // toast.success("All members added");
              setOpenNewMonthDialog(false);
              update();
              fetchMonths();
            }
          });
      });
      // toast.success("Month created!!");
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    notify();
    var month =
      startDate.toLocaleString("default", { month: "long" }) +
      " " +
      startDate.getFullYear();
    setMonth(month);
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem("mail")}/months/${month}`
    );
    setDoc(docRef, {
      month: month,
      collections: 0,
      expenses: 0,
      members: membersLength,
      visitors: 0,
      defaulters: membersLength,
      timestamp: serverTimestamp(),
    }).then(() => {
      var inc = 0;
      membersList.forEach((document) => {
        const docRef = doc(
          db,
          `profile/${sessionStorage.getItem(
            "mail"
          )}/months/${month}/members/${document}`
        );
        setDoc(docRef, {
          member_name: document,
          paid_coll: 0,
        })
          .then(() => {
            inc = inc + 1;
          })
          .finally(() => {
            if (inc === membersList.length - 1) {
              // toast.success("All members added");
              update();
              fetchMonths();
              setFlag(false);
            }
          });
      });
      // toast.success("Month created!!");
    });
  };

  const notify = () =>
    (toastId.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Creating New Month
        </div>
      ),
      { autoClose: false, icon: false }
    ));

  const update = () => {
    toast.update(toastId.current, {
      render: "Created New Month!!",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });
  };
  const notifyDelete = () =>
    (toastId.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Deleting Month
        </div>
      ),
      { autoClose: false, icon: false }
    ));
  const updateDelete = () => {
    toast.update(toastId.current, {
      render: "Deleted Month!!",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });
  };

  const handleDelete = (monthName) => {
    notifyDelete();
    const datesRef = collection(
      db,
      `profile/${sessionStorage.getItem("mail")}/months/${monthName}/dates`
    );
    getDocs(datesRef)
      .then((dates) => {
        if (dates.docs.length > 0) {
          dates.forEach((date) => {
            const expRef = collection(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${monthName}/dates/${date.id}/expenses`
            );
            getDocs(expRef).then((expenses) => {
              expenses.forEach((expense) => {
                deleteDoc(
                  doc(
                    db,
                    `profile/${sessionStorage.getItem(
                      "mail"
                    )}/months/${monthName}/dates/${date.id}/expenses/${
                      expense.id
                    }`
                  )
                );
              });
            });
            const dateMemRef = collection(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${monthName}/dates/${date.id}/members`
            );
            getDocs(dateMemRef)
              .then((dateMembers) => {
                dateMembers.forEach((dateMember) => {
                  const docRef = doc(
                    db,
                    `profile/${sessionStorage.getItem(
                      "mail"
                    )}/months/${monthName}/dates/${date.id}/members/${
                      dateMember.id
                    }`
                  );
                  deleteDoc(docRef);
                });
              })
              .finally(() => {
                const docRef = doc(
                  db,
                  `profile/${sessionStorage.getItem(
                    "mail"
                  )}/months/${monthName}/dates/${date.id}/`
                );
                deleteDoc(docRef);
              });
          });
        } else {
          const monthMemRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${monthName}/members`
          );
          getDocs(monthMemRef).then((monthMembers) => {
            monthMembers.forEach((monthMember) => {
              const docRef = doc(
                db,
                `profile/${sessionStorage.getItem(
                  "mail"
                )}/months/${monthName}/members/${monthMember.id}`
              );
              deleteDoc(docRef).finally(() => {
                const docRef = doc(
                  db,
                  `profile/${sessionStorage.getItem(
                    "mail"
                  )}/months/${monthName}`
                );
                deleteDoc(docRef).then(() => {
                  updateDelete();
                  setOpenDeleteDialog(false);
                });
              });
            });
          });
        }
      })
      .finally(() => {
        const monthMemRef = collection(
          db,
          `profile/${sessionStorage.getItem(
            "mail"
          )}/months/${monthName}/members`
        );
        getDocs(monthMemRef).then((monthMembers) => {
          monthMembers.forEach((monthMember) => {
            const docRef = doc(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${monthName}/members/${monthMember.id}`
            );
            deleteDoc(docRef).finally(() => {
              const docRef = doc(
                db,
                `profile/${sessionStorage.getItem("mail")}/months/${monthName}`
              );
              deleteDoc(docRef).then(() => {
                updateDelete();
                setOpenDeleteDialog(false);
              });
            });
          });
        });
      });
  };

  const handleSaveCFA = () => {
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}`
    );
    updateDoc(docRef, {
      carry_forward: parseInt(cfa),
    }).then(() => {
      toast.success("Carry Forward Amount Saved Successfully");
      setOpenCFD(false);
      sessionStorage.removeItem("month");
    });
  };

  setInterval(() => {
    if (navigator.onLine === true) {
      setInternet(true);
    } else {
      setInternet(false);
    }
  }, 3000);

  return (
    <>
      {/* <div className="nav-container">
        <nav className="navbar navbar-expand-md navbar-custom">
          <div className="container">
            <div
              className="navbar-brand"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <img src={Logo} alt="logo" /> Venue Fair Bahi Khata
            </div>
            <span className="user-tag">{sessionStorage.getItem("bfn")}</span>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar_content"
              aria-controls="navbar_content"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="bi bi-list"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar_content">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <div className="nav-link">
                    <Tooltip
                      title={
                        internet === true
                          ? "You are connected to the internet"
                          : "Please connect to the internet"
                      }
                    >
                      <i
                        style={{ fontSize: "20px" }}
                        className={
                          internet === true ? "bi bi-wifi" : "bi bi-wifi-off"
                        }
                      ></i>
                    </Tooltip>
                  </div>
                </li>
                <li className="nav-item mt-1">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    type={"button"}
                    onClick={() =>
                      navigate(
                        `/leader/dashboard/${sessionStorage.getItem("mail")}`
                      )
                    }
                  >
                    <i className="bi bi-box-arrow-left"></i> Back
                  </a>
                </li>
                <li className="nav-item mt-1">
                  <a
                    href="https://fairshare.tech/contact-details-of-software-company-pune/"
                    target="_blank"
                    className="nav-link"
                  >
                    <i className="bi bi-send-fill"></i> Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div> */}
      <NavBar />
      <section id="months" className="row">
        {flag === true ? (
          <div className="col-10 col-lg-10 mx-auto">
            <form
              onSubmit={handleSubmit}
              className="form-card border p-2 col-10 col-sm-8 col-md-6 col-lg-5 mx-auto"
            >
              <legend htmlFor="months" className="border-bottom">
                Add Month
              </legend>
              <br />
              <DatePicker
                selected={startDate}
                className="form-control"
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
              />
              <br />
              <br />
              <button type={"submit"} className="btn btn-orange">
                Add Month
              </button>
            </form>
          </div>
        ) : (
          <div className="col-10 col-lg-10 col-md-8 mx-auto m-2 text-center">
            <legend className="p-2 border-bottom text-center">
              Months{" "}
              <button
                onClick={() => setOpenNewMonthDialog(true)}
                type="button"
                className="btn btn-outline-orange"
              >
                New Month
              </button>
            </legend>
            {!loading ? (
              <div
                className="mx-auto text-center"
                style={{ overflowX: "auto" }}
              >
                {" "}
                <table
                  style={{ overflow: "scroll" }}
                  className="table table-responsive-sm table-hover table-striped table-bordered"
                >
                  <thead className="text-center">
                    <tr>
                      <th>Sr. No.</th>
                      <th>Month</th>
                      <th>C/F Amount</th>
                      <th>Exp. Collections</th>
                      <th>Collections</th>
                      <th>Exp. Penalty</th>
                      <th>Paid Penalty</th>
                      <th>Expenses</th>
                      <th>Income</th>
                      <th>Members</th>
                      <th>Defaulters</th>
                      <th>Visitors</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {monthsList.map((month, i) => (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td
                          style={{ cursor: "pointer " }}
                          onClick={() => {
                            sessionStorage.setItem("month", month.month);
                            navigate("/dashboard/months/month");
                          }}
                        >
                          <u>{month.month}</u>
                        </td>
                        <td>
                          {month.carry_forward === undefined
                            ? 0
                            : month.carry_forward}
                        </td>
                        <td>
                          {month.amount > 0
                            ? month.amount * month.members +
                              month.visitorCount * collections
                            : 0}
                        </td>
                        <td>{month.actualCount}</td>
                        <td>{month.expected_penalty}</td>
                        <td>{month.paid_penalty}</td>
                        <td>{month.expenses}</td>
                        <td>{month.income}</td>
                        <td>{month.members}</td>
                        <td>
                          <Link
                            style={{ color: "black" }}
                            to={{
                              pathname: `/dashboard/defaulters/${sessionStorage.getItem(
                                "mail"
                              )}/${month.month}`,
                            }}
                          >
                            <u>{month.defaulterCount}</u>
                          </Link>
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          <Link
                            to={{
                              pathname: `/dashboard/visitors/${month.month}`,
                            }}
                          >
                            <u style={{ color: "black" }}>
                              {month.visitorCount}
                            </u>
                          </Link>
                        </td>
                        <td>
                          <div className="btn-group">
                            {/*//Dates button*/}
                            <button
                              className="btn p-2 btn-outline-orange"
                              onClick={() => {
                                sessionStorage.setItem(
                                  "selected_month",
                                  month.month
                                );
                                navigate(`/dashboard/months/dates`);
                              }}
                            >
                              Dates
                            </button>
                            {/* // Delete Month button */}
                            <button
                              onClick={() => {
                                setMonthName(month.month);
                                setOpenDeleteDialog(true);
                              }}
                              className="btn p-2 btn-outline-orange"
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                            {/* // CFD button */}
                            <button
                              onClick={() => {
                                setOpenCFD(true);
                                sessionStorage.setItem("month", month.month);
                                if (month.carry_forward === undefined) {
                                  setCFA(0);
                                } else {
                                  setCFA(month.carry_forward);
                                }
                              }}
                              className="btn p-2 btn-outline-orange"
                            >
                              <i className="bi bi-pencil"></i>
                            </button>
                            {/* Generate Excel Report */}
                            <button
                              className="btn btn-outline-orange"
                              onClick={() => {
                                sessionStorage.setItem("month", month.month);
                                navigate("/generateData");
                              }}
                            >
                              <i className="bi bi-file-spreadsheet"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="col-10 col-lg-8 col-md-8 mx-auto text-center border p-2 h-100">
                {/* <RotatingLines
                  strokeColor="black"
                  visible
                  strokeWidth="4"
                  width="30"
                /> */}
                <SpinnerCircular
                  size={30}
                  thickness={150}
                  speed={130}
                  color="rgba(0,0,0,1)"
                  secondaryColor="rgba(255,255,255, 1)"
                />
              </div>
            )}
          </div>
        )}
      </section>
      <Footer />
      {openNewMonthDialog && (
        <Dialog
          open={openNewMonthDialog}
          fullWidth
          maxWidth="sm"
          onClose={() => setOpenNewMonthDialog(false)}
        >
          <div className="modal-content">
            <div className="modal-body">
              <p>Add Month</p>
              <DatePicker
                selected={startDate}
                className="form-control"
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => setOpenNewMonthDialog(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={handleAdd}>
                Add Month
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {opencfd && (
        <Dialog maxWidth="sm" open={opencfd} onClose={() => setOpenCFD(false)}>
          <div className="modal-content">
            <div className="modal-header">Edit Carry Forward Amount</div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="cfa">Enter C/F Amount</label>
                <input
                  id="cfa"
                  className="form-control"
                  type="number"
                  value={cfa}
                  onChange={(e) => setCFA(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => setOpenCFD(false)}
                className="btn btn-danger"
              >
                Cancel
              </button>
              <button className="btn btn-orange" onClick={handleSaveCFA}>
                Save
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {openDeleteDialog && (
        <Dialog
          maxWidth="sm"
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <div className="modal-content">
            <div className="modal-body">
              <h5>Do you really want to delete {monthName}?</h5>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                onClick={() => setOpenDeleteDialog(false)}
              >
                No
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(monthName)}
              >
                Yes
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {/* {openVisitorsDialog && (
        <Dialog
          open={openVisitorsDialog}
          onClose={() => setOpenVisitorsDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <div className="modal-header">
            <h5>Visitors for {monthName}</h5>
            <div className="justify-content-end">
              <i
                onClick={() => setOpenVisitorsDialog(false)}
                className="btn-danger btn bi bi-x-lg"
              ></i>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-body">
              {visitorsList.length > 0 ? (
                <table className="table table-responsive-sm table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Visitor Name</th>
                      <th>Invitee</th>
                      <th>Date of Visit</th>
                      <th>Paid Collection</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visitorsList.map((visitors, i) => (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{visitors.visitor_name}</td>
                        <td>{visitors.member}</td>
                        <td>
                          {visitors.paid_coll === 0 ? (
                            <Link
                              to={{ pathname: "/dashboard/months/date" }}
                              onClick={() => {
                                sessionStorage.setItem(
                                  "selected_month",
                                  monthName
                                );
                                sessionStorage.setItem("date", visitors.date);
                              }}
                            >
                              {visitors.date}
                            </Link>
                          ) : (
                            visitors.date
                          )}
                        </td>
                        <td>{visitors.paid_coll}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center">
                  <h4>No Visitors Added Yet!</h4>
                </div>
              )}
            </div>
          </div>
        </Dialog>
      )} */}
    </>
  );
};

export default Months;
