import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { auth } from "../firebase-config/firebase";
import NavBar from "./NavBar";
import Footer from "./Footer";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const resetPassword = async (e) => {
    e.preventDefault();
    if (email !== "") {
      return await sendPasswordResetEmail(auth, email)
        .then(() => {
          toast.success(`Reset Link sent to ${email}`);
          navigate("/login");
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              toast.error("Email already in use");
              break;
            case "auth/invalid-email":
              toast.error("Invalid Email");
              break;
            case "auth/weak-password":
              toast.error("Weak Password. Please set a strong password");
              break;
            case "auth/wrong-password":
              toast.error("Wrong Password");
              break;
            case "auth/user-not-found":
              toast.error("User Not Found");
              break;
            case "auth/user-disabled":
              toast.error("User Disabled by Admin");
              break;
            default:
              console.log("");
          }
        });
    } else {
      toast.error("Please fill out all fields");
    }
  };

  return (
    <>
      <NavBar />
      <section id="resetPassword">
        <div className="col-10 col-lg-8 mx-auto">
          <div className="row">
            <div className="form-card col-10 col-sm-8 col-md-6 col-lg-5 mx-auto">
              <form onSubmit={resetPassword}>
                <legend className="border-bottom">Reset Password</legend>
                <br />
                <br />
                <label htmlFor="email">Enter Email Address</label>
                <input
                  className="form-control"
                  type={"email"}
                  name="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <button type={"submit"} className="btn btn-orange mt-4">
                  Send Password Reset Mail
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ResetPassword;
