import { Password } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { auth, db } from "../firebase-config/firebase";

const SuperAdmin = () => {
  const navigate = useNavigate();
  const [internet, setInternet] = useState(false);
  const [speed, setSpeed] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  setInterval(() => {
    if (navigator.onLine === true) {
      setInternet(true);
    } else {
      setInternet(false);
    }
  }, 3000);

  useEffect(() => {
    var file_size = 127,
      start = performance.now(),
      end = 0,
      time = 0,
      xmlhttp = new XMLHttpRequest(),
      result = 0;
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        end = performance.now();
        time = (end - start) / 1000;
        result = ((file_size / time / 1000) * 8).toFixed(2);
        setSpeed(result);
      }
    };
    xmlhttp.open(
      "GET",
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg",
      true
    );
    xmlhttp.send();
  }, [internet]);

  const handleRegister = (e) => {
    e.preventDefault();
    if (email !== "" && password !== "") {
      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          const docRef = doc(db, `profile/${email}`);
          setDoc(docRef, {
            role: "leader",
          }).then(() => {
            setEmail("");
            setPassword("");
            toast.success("User created!!");
          });
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              toast.error("Email already in use");
              break;
            case "auth/invalid-email":
              toast.error("Invalid Email");
              break;
            case "auth/weak-password":
              toast.error("Weak Password. Please set a strong password");
              break;
            case "auth/wrong-password":
              toast.error("Wrong Password");
              break;
            case "auth/user-not-found":
              toast.error("User Not Found");
              break;
            case "auth/user-disabled":
              toast.error("User Disabled by Admin");
              break;
            default:
              console.log("");
          }
        });
    } else {
      toast.error("Please fill out all fields!!");
    }
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate("/");
    });
  };

  const generatePassword = () => {
    // Set the password length
    const length = 8;

    // Set the allowed characters for the password
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=";

    let password = "";

    // Generate the password
    for (let i = 0; i < length; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setPassword(password);
  };

  return (
    <>
      <div className="nav-container">
        <nav className="navbar navbar-expand-md navbar-custom">
          <div className="container">
            <div
              className="navbar-brand"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              Venue Fair Bahi Khata
            </div>
            <span className="user-tag">{sessionStorage.getItem("mail")}</span>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar_content"
              aria-controls="navbar_content"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="bi bi-list"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar_content">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <div className="nav-link">
                    <Tooltip
                      title={
                        internet === true
                          ? `You are connected to the internet approx. ${speed} mbps`
                          : "Please connect to the internet"
                      }
                    >
                      <i
                        style={{ fontSize: "20px" }}
                        className={
                          internet === true ? "bi bi-wifi" : "bi bi-wifi-off"
                        }
                      ></i>
                    </Tooltip>
                  </div>
                </li>
                <li className="nav-item mt-1">
                  <a
                    onClick={handleLogout}
                    style={{ cursor: "pointer" }}
                    className="nav-link"
                  >
                    <i className="bi bi-box-arrow-left"></i> Logout
                  </a>
                </li>
                <li className="nav-item mt-1">
                  <a
                    href="https://fairshare.tech/contact-details-of-software-company-pune/"
                    target="_blank"
                    className="nav-link"
                  >
                    <i className="bi bi-send-fill"></i> Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <section className="row" id="superadmin">
        <div className="col-10 col-lg-8 mx-auto">
          <div className="row">
            <div className="form-card col-10 col-sm-8 col-md-6 col-lg-5 mx-auto">
              <form onSubmit={handleRegister}>
                <legend className="border-bottom">Register A New Leader</legend>
                <br />
                <br />
                <div className="form-group">
                  <label htmlFor="registeremail">Enter Email Address</label>
                  <input
                    className="form-control"
                    type="email"
                    name="Email"
                    value={email}
                    id="registeremail"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <br />
                  <label htmlFor="registerpassword">Enter Password</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      name="Password"
                      id="registerpassword"
                      autoComplete=""
                      value={password}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control"
                    />
                    <Tooltip title="Generate New Password">
                      <Button
                        onClick={() => generatePassword()}
                        className="p-2"
                        style={{ cursor: "pointer" }}
                      >
                        <Password />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <br />
                <button type="submit" className="btn btn-orange mt-4">
                  Create Account
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer mt-auto">
        <section id="contact" className="row">
          <div className="col-10 col-lg-8 mx-auto">
            <h3>Have a question?</h3>
            <div className="row">
              <a
                type="button"
                role="button"
                className="btn btn-orange col-10 col-sm-3 mx-auto"
                href="https://fairshare.tech/contact-details-of-software-company-pune/"
                target="_blank"
              >
                <i className="bi bi-send-fill"></i> Contact Us
              </a>
            </div>
          </div>
        </section>
        <a
          href="https://fairshare.tech"
          routerlinkactive="active"
          target="_blank"
          className="mt-4"
        >
          Powered by https://fairshare.tech
        </a>
      </footer>
    </>
  );
};

export default SuperAdmin;
