// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyAdUdz0s6HN0F2IKEbIv-z6kXKVdtQWNdU",
  authDomain: "vcms-portal-751c0.firebaseapp.com",
  projectId: "vcms-portal-751c0",
  storageBucket: "vcms-portal-751c0.appspot.com",
  messagingSenderId: "95332913592",
  appId: "1:95332913592:web:e1ba826e013254c7407f4d",
  measurementId: "G-HD8YSY13RM",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();
const auth = getAuth();

export default app;
export { db, auth, analytics };
