import { collection, getDocs, onSnapshot } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { db } from "../firebase-config/firebase";
import Footer from "./Footer";
import NavBar from "./NavBar";

const Visitors = () => {
  var month = window.location.pathname.split("/")[3].replace("%20", " ");
  const [visitorsList, setVisitorsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchVisitors = (month) => {
    if (month !== "") {
      setLoading(true);
      const colRef = collection(
        db,
        `profile/${sessionStorage.getItem("mail")}/months/${month}/dates`
      );
      onSnapshot(colRef, (dates) => {
        let arr = [];
        dates.forEach((date) => {
          const colRef = collection(
            db,
            `profile/${sessionStorage.getItem("mail")}/months/${month}/dates/${
              date.id
            }/members`
          );
          getDocs(colRef)
            .then((members) => {
              members.forEach((member) => {
                if (member.data().type === "visitor") {
                  arr.push({ ...member.data(), date: date.id });
                }
              });
            })
            .finally(() => {
              setVisitorsList(arr);
              setLoading(false);
            });
        });
      });
    }
  };
  useEffect(() => {
    fetchVisitors(month);
  }, [month]);

  return (
    <>
      <NavBar />  
      <section className="row" id="v">
        <div className="col-10 col-lg-10 mx-auto">
          <h3>Visitors for {month}</h3>
          {!loading ? (
            <>
              {visitorsList.length > 0 ? (
                <table className="table table-responsive-sm table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Visitor Name</th>
                      <th>Invitee</th>
                      <th>Date of Visit</th>
                      <th>Paid Collection</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visitorsList.map((v, i) => (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{v.visitor_name}</td>
                        <td>{v.member}</td>
                        <td>
                          {v.paid_coll === 0 ? (
                            <Link
                              to={{ pathname: "/dashboard/months/date" }}
                              onClick={() => {
                                sessionStorage.setItem("selected_month", month);
                                sessionStorage.setItem("date", v.date);
                              }}
                            >
                              {v.date}
                            </Link>
                          ) : (
                            v.date
                          )}
                        </td>
                        <td>{v.paid_coll}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h4>No Visitors</h4>
              )}
            </>
          ) : (
            <>
              <SpinnerCircular
                size={30}
                thickness={150}
                speed={130}
                color="rgba(0,0,0,1)"
                secondaryColor="rgba(255,255,255, 1)"
              />
            </>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Visitors;
