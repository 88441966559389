import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DT from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import opacity from "react-element-popper/animations/opacity";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase-config/firebase";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { RotatingLines } from "react-loader-spinner";
import { Dialog, Tooltip } from "@mui/material";
import { SpinnerCircular } from "spinners-react";
import Logo from "../favicon-32x32.png";
import NavBar from "./NavBar";
import Footer from "./Footer";

const Dates = () => {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false);
  const [summary, setSummary] = useState([]);
  const [expenses, setExpenses] = useState(parseInt(0));
  const [actual, setActual] = useState(0);
  const [visitorCount, setVisitorCount] = useState(0);
  const [dcpm, setDcpm] = useState();
  const format = "DD/MMM/YYYY";
  const [startDate, setStartDate] = useState(
    sessionStorage.length > 0
      ? new Date(
          `${
            "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(
              sessionStorage.getItem("selected_month").substring(0, 3)
            ) /
              3 +
            1
          }/01/${sessionStorage.getItem("selected_month").split(" ")[1]}`
        )
      : new Date()
  );
  const [dates, setDates] = useState([new DateObject().setDate(startDate)]);
  const [finalDates, setFinalDates] = useState([]);
  const [datesList, setDatesList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [collections, setCollections] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dateName, setDateName] = useState("");
  const [month, setMonthName] = useState("");
  const [internet, setInternet] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openNewDateDialog, setOpenNewDateDialog] = useState(false);
  const toastId = useRef(null);

  useEffect(() => {
    if (sessionStorage.length === 0) {
      navigate("/");
    }
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}`
    );
    onSnapshot(docRef, (snap) => {
      setCollections(snap.data().amount);
      sessionStorage.setItem("col", snap.data().amount);
    });
    const docRef1 = doc(db, `profile/${sessionStorage.getItem("mail")}`);
    getDoc(docRef1).then((doc) => {
      setDcpm(doc.data().def_collection_per_meeting);
    });
  }, []);

  useEffect(() => {
    let arr = [];
    dates.forEach((date) => {
      console.log(date.format());
      arr.push(moment(date.format()).format("DD-MMM-YYYY"));
    });
    console.log(arr);
    setFinalDates(arr);
  }, [dates]);

  useEffect(() => {
    const docRef1 = doc(db, `profile/${sessionStorage.getItem("mail")}`);
    getDoc(docRef1).then((doc) => {
      sessionStorage.setItem(
        "collections",
        doc.data().def_collection_per_meeting
      );
    });
    const docRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/dates`
    );
    getDocs(docRef).then((snap) => {
      if (snap.docs.length === 0) {
        setFlag(true);
      }
    });
    const collectionRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/members`
    );
    getDocs(collectionRef).then((members) => {
      let arr = [];
      members.docs.forEach((doc) => {
        arr.push(doc.data().member_name);
      });
      setMembersList(arr);
    });
  }, []);

  useEffect(() => {
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/dates`
    );
    const que = query(colRef, orderBy("add_timestamp", "desc"));
    onSnapshot(que, (snap) => {
      let arr = [];
      snap.forEach((docs) => {
        arr.push(docs.data());
      });
      setDatesList(arr);
      sessionStorage.setItem("second", JSON.stringify(arr.at(-2)));
      sessionStorage.setItem("datesLength", snap.docs.length);
    });
  }, [db]);

  useEffect(() => {
    setLoading(true);
    let actualCount = 0;
    const colRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}`
    );
    let v1 = 0;
    const colRef1 = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/dates`
    );
    getDocs(colRef1)
      .then((dates) => {
        dates.forEach((date) => {
          const colRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("selected_month")}/dates/${
              date.id
            }/members`
          );
          getDocs(colRef)
            .then((members) => {
              members.forEach((member) => {
                if (member.data().type === "visitor") {
                  v1 += 1;
                  actualCount += member.data().paid_coll;
                }
              });
            })
            .finally(() => {
              setVisitorCount(v1);
            });
        });
      })
      .finally(() => {
        onSnapshot(colRef, (snap) => {
          let arr = [];
          let a1 = 0;
          let e1 = 0;
          let i1 = 0;
          let d1 = 0;
          const collectionRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("selected_month")}/members`
          );
          // let actualCount = 0;
          let defaulterCount = 0;
          const colRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("selected_month")}/dates`
          );
          getDocs(colRef)
            .then((dates) => {
              dates.forEach((date) => {
                const colRef1 = collection(
                  db,
                  `profile/${sessionStorage.getItem(
                    "mail"
                  )}/months/${sessionStorage.getItem("selected_month")}/dates/${
                    date.id
                  }/expenses`
                );
                const colRef2 = collection(
                  db,
                  `profile/${sessionStorage.getItem(
                    "mail"
                  )}/months/${sessionStorage.getItem("selected_month")}/dates/${
                    date.id
                  }/incomes`
                );
                getDocs(colRef2).then((income) => {
                  income.forEach((i) => {
                    i1 += parseInt(i.data().amount);
                  });
                });
                getDocs(colRef1)
                  .then((expenses) => {
                    expenses.forEach((expense) => {
                      e1 += parseInt(expense.data().expenseAmount);
                    });
                  })
                  .finally(() => {
                    setExpenses(e1);
                  });
              });
            })
            .finally(() => {
              getDocs(collectionRef).then((members) => {
                let p = 0;
                let pp = 0;
                members.forEach((doc) => {
                  if (doc.data().paid_coll !== undefined) {
                    actualCount += doc.data().paid_coll;
                  }
                  if (
                    doc.data().paid_coll < doc.data().amount ||
                    doc.data().paid_coll === undefined ||
                    doc.data().paid_penalty < doc.data().expected_penalty
                  ) {
                    defaulterCount += 1;
                  }
                  if (doc.data().expected_penalty !== undefined) {
                    p += doc.data().expected_penalty;
                  }
                  if (doc.data().paid_penalty !== undefined) {
                    pp += doc.data().paid_penalty;
                  }
                });
                arr.push({
                  ...snap.data(),
                  actualCount: actualCount,
                  defaulterCount: defaulterCount,
                  expected_penalty: p,
                  paid_penalty: pp,
                  income: i1,
                  visitorCount: v1,
                });
                a1 = actualCount;
                d1 = defaulterCount;
                setSummary(arr);
                setLoading(false);
              });
            });
        });
      });
  }, [db]);

  const handleAdd = () => {
    notify();
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/dates/${moment(
        startDate
      ).format("DD-MMM-YYYY")}`
    );
    const docRef1 = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}`
    );
    getDoc(docRef1).then((d) => {
      updateDoc(docRef1, {
        defaulters: d.data().defaulters + membersList.length,
      }).then(() => {
        console.log("First");
      });
    });
    setDoc(docRef, {
      date: moment(startDate).format("DD-MMM-YYYY"),
      add_timestamp: serverTimestamp(),
      members: membersList.length,
      visitors: 0,
      collection: 0,
      expenses: 0,
    }).then(() => {
      var inc = 0;
      updateDoc(
        doc(
          db,
          `profile/${sessionStorage.getItem(
            "mail"
          )}/months/${sessionStorage.getItem("selected_month")}`
        ),
        {
          amount:
            sessionStorage.getItem("datesLength") *
            sessionStorage.getItem("collections"),
        }
      );
      membersList.forEach((document) => {
        const docRef1 = doc(
          db,
          `profile/${sessionStorage.getItem(
            "mail"
          )}/months/${sessionStorage.getItem(
            "selected_month"
          )}/members/${document}`
        );
        updateDoc(docRef1, {
          amount:
            sessionStorage.getItem("datesLength") *
            sessionStorage.getItem("collections"),
        });
        const docRef = doc(
          db,
          `profile/${sessionStorage.getItem(
            "mail"
          )}/months/${sessionStorage.getItem("selected_month")}/dates/${moment(
            startDate
          ).format("DD-MMM-YYYY")}/members/${document}`
        );
        setDoc(docRef, {
          member_name: document,
          paid_coll: 0,
          pending_coll: 0,
          payment_method: "none",
        })
          .then(() => {
            inc = inc + 1;
          })
          .finally(() => {
            if (inc === membersList.length - 1) {
              update();
              setOpenNewDateDialog(false);
            }
          });
      });
    });
  };

  const notify = () =>
    (toastId.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Creating New Dates
        </div>
      ),
      { autoClose: false, icon: false }
    ));

  const update = (date) =>
    toast.update(toastId.current, {
      render: `Created New Date ${date}!!`,
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    notify();
    finalDates.forEach((date) => {
      const docRef = doc(
        db,
        `profile/${sessionStorage.getItem(
          "mail"
        )}/months/${sessionStorage.getItem("selected_month")}/dates/${moment(
          date
        ).format("DD-MMM-YYYY")}`
      );

      setDoc(docRef, {
        date: moment(date).format("DD-MMM-YYYY"),
        add_timestamp: serverTimestamp(),
      }).then(() => {
        var inc = 0;
        updateDoc(
          doc(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("selected_month")}`
          ),
          {
            amount:
              sessionStorage.getItem("datesLength") *
              sessionStorage.getItem("collections"),
          }
        );
        membersList.forEach((document) => {
          const docRef1 = doc(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem(
              "selected_month"
            )}/members/${document}`
          );
          updateDoc(docRef1, {
            amount:
              sessionStorage.getItem("datesLength") *
              sessionStorage.getItem("collections"),
          });
          const docRef = doc(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem(
              "selected_month"
            )}/dates/${moment(date).format("DD-MMM-YYYY")}/members/${document}`
          );
          setDoc(docRef, {
            member_name: document,
            paid_coll: 0,
            payment_method: "none",
            pending_coll: 0,
          })
            .then(() => {
              inc += 1;
            })
            .finally(() => {
              if (inc === membersList.length - 1) {
                // toast.success("  All members added");
                update(date);
                setFlag(false);
              }
            });
        });
      });
    });
    // const docRef = doc(
    //   db,
    //   `profile/${sessionStorage.getItem(
    //     "mail"
    //   )}/months/${sessionStorage.getItem("selected_month")}/dates/${moment(
    //     startDate
    //   ).format("DD-MMM-YYYY")}`
    // );
  };

  const handleDateDelete = (date) => {
    notifyDelete();
    const expRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${date}/expenses`
    );
    const memRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "selected_month"
      )}/dates/${date}/members`
    );
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/dates/${date}`
    );
    getDocs(expRef).then((d) => {
      if (d.docs.length > 0) {
        d.forEach((d1) => {
          deleteDoc(
            doc(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${sessionStorage.getItem(
                "selected_month"
              )}/dates/${date}/expenses/${d1.id}`
            )
          );
        });
      }
    });

    getDocs(memRef).then((d) => {
      if (d.docs.length > 0) {
        d.forEach((d1) => {
          deleteDoc(
            doc(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${sessionStorage.getItem(
                "selected_month"
              )}/dates/${date}/members/${d1.id}`
            )
          );
        });
      }
    });
    deleteDoc(docRef).then(() => {
      updateDelete();
      setOpenDeleteDialog(false);
      if (datesList.length === 0) {
        setFlag(true);
      }
    });
  };

  const notifyDelete = () =>
    (toastId.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Deleting Date
        </div>
      ),
      { autoClose: false, icon: false }
    ));

  const updateDelete = () =>
    toast.update(toastId.current, {
      render: "Deleted Date Successfully!!",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });

  setInterval(() => {
    if (navigator.onLine === true) {
      setInternet(true);
    } else {
      setInternet(false);
    }
  }, 3000);

  return (
    <>
      {/* <div className="nav-container">
        <nav className="navbar navbar-expand-md navbar-custom">
          <div className="container">
            <div
              className="navbar-brand"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <img src={Logo} alt="logo" /> Venue Fair Bahi Khata
            </div>
            <span className="user-tag">{sessionStorage.getItem("bfn")}</span>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar_content"
              aria-controls="navbar_content"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="bi bi-list"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar_content">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <div className="nav-link">
                    <Tooltip
                      title={
                        internet === true
                          ? "You are connected to the internet"
                          : "Please connect to the internet"
                      }
                    >
                      <i
                        style={{ fontSize: "20px" }}
                        className={
                          internet === true ? "bi bi-wifi" : "bi bi-wifi-off"
                        }
                      ></i>
                    </Tooltip>
                  </div>
                </li>
                <li className="nav-item mt-1">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    type={"button"}
                    onClick={() =>
                      navigate(
                        `/dashboard/months/${sessionStorage.getItem("mail")}`
                      )
                    }
                  >
                    <i className="bi bi-box-arrow-left"></i> Back
                  </a>
                </li>
                <li className="nav-item mt-1">
                  <a
                    href="https://fairshare.tech/contact-details-of-software-company-pune/"
                    target="_blank"
                    className="nav-link"
                  >
                    <i className="bi bi-send-fill"></i> Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div> */}
      <NavBar />
      <section id="dates" className="row">
        {flag === true ? (
          <div className="col-10 col-lg-10 mx-auto">
            <form
              onSubmit={handleSubmit}
              className="form-card border p-3 col-10 col-sm-8 col-md-6 col-lg-5 mx-auto"
            >
              <legend htmlFor="months" className="border-bottom">
                Add Date
              </legend>
              <br />
              {/* <DatePicker
                dateFormat="dd/MMM/yyyy"
                className="form-control mb-2"
                placeholderText="Enter first date of the month"
                onChange={(date) => setStartDate(date)}
                selected={startDate}
                minDate={
                  new Date(
                    `${
                      "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(
                        sessionStorage.getItem("selected_month").substring(0, 3)
                      ) /
                        3 +
                      1
                    }/01/${
                      sessionStorage.getItem("selected_month").split(" ")[1]
                    }`
                  )
                }
                maxDate={
                  new Date(
                    sessionStorage.getItem("selected_month").split(" ")[1],
                    "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(
                      sessionStorage.getItem("selected_month").substring(0, 3)
                    ) /
                      3 +
                      1,
                    0
                  )
                }
              /> */}  
              <DatePicker
                value={dates}
                className="form-control"
                onChange={setDates}
                multiple
                minDate={
                  new Date(
                    `${
                      "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(
                        sessionStorage.getItem("selected_month").substring(0, 3)
                      ) /
                        3 +
                      1
                    }/01/${
                      sessionStorage.getItem("selected_month").split(" ")[1]
                    }`
                  )
                }
                maxDate={
                  new Date(
                    sessionStorage.getItem("selected_month").split(" ")[1],
                    "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(
                      sessionStorage.getItem("selected_month").substring(0, 3)
                    ) /
                      3 +
                      1,
                    0
                  )
                }
                sort
                format={format}
                calendarPosition="bottom-center"
                plugins={[<DatePanel />]}
              />
              <br />
              <br />
              <p style={{ color: "red" }}>
                Please Note: All members in the chapter will be saved for{" "}
                {finalDates.map((date) => (
                  <>{moment(date).format(format) + " "}</>
                ))}
                . If any new member is added in between a month then it will be
                considered as a visitor
              </p>
              <button className="btn btn-orange">Save</button>
            </form>
          </div>
        ) : (
          <>
            <div
              style={{ border: "2px solid" }}
              className="col-12 col-lg-10 col-md-8 mx-auto text-center rounded p-2"
            >
              {loading === false ? (
                <>
                  <legend style={{ color: "Highlight" }} className="p-2">
                    <Link
                      style={{ color: "Highlight" }}
                      onClick={() => {
                        sessionStorage.setItem(
                          "month",
                          sessionStorage.getItem("selected_month")
                        );
                      }}
                      to={{ pathname: "/dashboard/months/month" }}
                    >
                      Summary for {sessionStorage.getItem("selected_month")}
                    </Link>
                  </legend>
                  <table
                    className="table table-bordered table-responsive-sm table-hover"
                    style={{ overflow: "scroll" }}
                  >
                    <thead>
                      <tr>
                        <th>C/F Amount</th>
                        <th>Exp. Collection</th>
                        <th>Actual Collection</th>
                        <th>Exp. Penalty</th>
                        <th>Actual Penalty</th>
                        <th>Members</th>
                        <th>Expenses</th>
                        <th>Income</th>
                        <th style={{ cursor: "pointer", textAlign: "center" }}>
                          <Link
                            to={{
                              pathname: `/dashboard/visitors/${sessionStorage.getItem(
                                "selected_month"
                              )}`,
                            }}
                          >
                            <u style={{ color: "black" }}>Visitors</u>
                          </Link>
                        </th>
                        <th>
                          <Link
                            style={{ color: "black" }}
                            to={{
                              pathname: `/dashboard/defaulters/${sessionStorage.getItem(
                                "mail"
                              )}/${sessionStorage.getItem("selected_month")}`,
                            }}
                          >
                            <u>Defaulters</u>
                          </Link>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {summary.map((items) => (
                        <tr key={1}>
                          <td>
                            {items.carry_forward === undefined
                              ? 0
                              : items.carry_forward}
                          </td>
                          <td>
                            {collections * membersList.length +
                              items.visitorCount * dcpm}
                          </td>
                          <td>{items.actualCount}</td>
                          <td>{items.expected_penalty}</td>
                          <td>{items.paid_penalty}</td>
                          <td>{membersList.length}</td>
                          <td>{items.expenses}</td>
                          <td>{items.income}</td>
                          <td>{items.visitorCount}</td>
                          <td>{items.defaulterCount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <SpinnerCircular
                    size={30}
                    thickness={150}
                    speed={130}
                    color="rgba(0,0,0,1)"
                    secondaryColor="rgba(255,255,255, 1)"
                  />
                </>
              )}
            </div>
            <div className="col-10 col-lg-4 col-md-8 mx-auto text-center mt-2 border rounded">
              <legend className="p-2 border-bottom text-center">
                Dates for {sessionStorage.getItem("selected_month")}{" "}
                <button
                  onClick={() => setOpenNewDateDialog(true)}
                  type="button"
                  className="btn btn-outline-orange"
                >
                  New Date
                </button>
              </legend>
              <br />
              <br />
              <div
                className="mx-auto text-center"
                style={{ overflowX: "auto" }}
              >
                {" "}
                <table
                  style={{ overflow: "scroll" }}
                  className="table table-responsive-sm table-hover table-striped"
                >
                  <thead>
                    <tr>
                      <th className="pb-3">No.</th>
                      <th className="pb-3">Date</th>
                      <th className="pb-3">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datesList.map((date, i) => (
                      <tr key={i + 1}>
                        <td className="pt-3">{i + 1}</td>
                        <td
                          className="pt-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/dashboard/months/date");
                            sessionStorage.setItem("date", date.date);
                          }}
                        >
                          <u>{date.date}</u>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              setDateName(date.date);
                              setOpenDeleteDialog(true);
                            }}
                            className="btn btn-outline-orange"
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </section>
      <Footer />

      {openNewDateDialog && (
        <Dialog
          open={openNewDateDialog}
          onClose={() => setOpenNewDateDialog(false)}
        >
          <div className="modal-content" style={{ height: "600px" }}>
            <div className="modal-body">
              <p>Add Date</p>
              <div className="form-control">
                <DT
                  dateFormat="dd/MMM/yyyy"
                  className="form-control"
                  onChange={(date) => setStartDate(date)}
                  format={format}
                  selected={startDate}
                  minDate={
                    new Date(
                      `${
                        "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(
                          sessionStorage
                            .getItem("selected_month")
                            .substring(0, 3)
                        ) /
                          3 +
                        1
                      }/01/${
                        sessionStorage.getItem("selected_month").split(" ")[1]
                      }`
                    )
                  }
                  maxDate={
                    new Date(
                      sessionStorage.getItem("selected_month").split(" ")[1],
                      "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(
                        sessionStorage.getItem("selected_month").substring(0, 3)
                      ) /
                        3 +
                        1,
                      0
                    )
                  }
                />
              </div>
              <p style={{ color: "red", marginTop: "320px" }}>
                Please Note: All members in the chapter will be saved for{" "}
                {moment(startDate).format("DD-MMM-YYYY")}. If any new member is
                added in between a month then it will be considered as a visitor
              </p>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => setOpenNewDateDialog(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={handleAdd}>
                Add Date
              </button>
            </div>
          </div>
        </Dialog>
      )}
      {openDeleteDialog && (
        <Dialog
          maxWidth="sm"
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <div className="modal-content">
            <div className="modal-body">
              <h5>Do you really want to delete {dateName}?</h5>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                onClick={() => setOpenDeleteDialog(false)}
              >
                No
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleDateDelete(dateName)}
              >
                Yes
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Dates;
