import { Delete } from "@mui/icons-material";
import { Dialog, Tooltip } from "@mui/material";
import {
  collection,
  doc,
  FieldValue,
  getFirestore,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SpinnerCircular } from "spinners-react";
import { db } from "../firebase-config/firebase";
import Footer from "./Footer";
import NavBar from "./NavBar";

const Month = () => {
  const navigate = useNavigate();
  const [membersList, setMembersList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [col, setCollection] = useState(1);
  const [dcpm, setDcpm] = useState(0);
  const [datesList, setDatesList] = useState([]);
  const [collections, setCollections] = useState(0);
  const [summary, setSummary] = useState([]);
  const [expenses, setExpenses] = useState(parseInt(0));
  const [open, setOpen] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [defList, setDefList] = useState([]);
  const [dppm, setDppm] = useState(parseInt(0));
  const [visitorCount, setVisitorCount] = useState(0);
  const [numbersList, setNumbersList] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");
  const [month, setMonthName] = useState("");
  const [openVisitorsDialog, setOpenVisitorsDialog] = useState(false);
  const [visitorsList, setVisitorsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeletePenaltyDialog, setOpenDeletePenaltyDialog] = useState(false);
  const toastId = useRef(null);

  useEffect(() => {
    if (sessionStorage.length === 0) {
      navigate("/");
    }
    const docRef1 = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}`
    );
    onSnapshot(docRef1, (snap) => {
      setCollections(snap.data().amount);
      sessionStorage.setItem("col", snap.data().amount);
    });
    const docRef = doc(db, `profile/${sessionStorage.getItem("mail")}`);
    getDoc(docRef).then((doc) => {
      setDcpm(doc.data().def_collection_per_meeting);
      setDppm(doc.data().def_penalty_per_month);
    });
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}/dates`
    );
    const que = query(colRef, orderBy("add_timestamp", "asc"));
    onSnapshot(que, (snap) => {
      let arr = [];
      snap.forEach((docs) => {
        arr.push(docs.data());
      });
      setDatesList(arr);
      sessionStorage.setItem("datesLength", snap.docs.length);
    });
  }, []);

  useEffect(() => {
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}/members/`
    );
    onSnapshot(colRef, (snap) => {
      let arr = [];
      snap.forEach((docs) => {
        if (
          docs.data().paid_coll === undefined ||
          docs.data().paid_coll < docs.data().amount
        ) {
          arr.push(docs.data());
        }
      });
      setDefList(arr);
    });
  }, [db]);

  useEffect(() => {
    let arr = [0];
    for (var i = 1; i <= datesList.length; i++) {
      arr.push(parseInt(dcpm) * i);
    }
    setNumbersList(arr);
  }, [sessionStorage.getItem("member")]);

  // const handleAdd = () => {
  //   notifyCollection();
  //   console.log(col);
  //   console.log(datesList);
  //   var c = parseInt(col);
  //   var d = parseInt(dcpm);
  //   const docRef2 = doc(
  //     db,
  //     `profile/${sessionStorage.getItem(
  //       "mail"
  //     )}/months/${sessionStorage.getItem("month")}`
  //   );
  //   if (c === d * datesList.length) {
  //     //4000
  //     datesList.forEach((date) => {
  //       const docRef = doc(
  //         db,
  //         `profile/${sessionStorage.getItem(
  //           "mail"
  //         )}/months/${sessionStorage.getItem("month")}/dates/${
  //           date.date
  //         }/members/${sessionStorage.getItem("member")}`
  //       );
  //       updateDoc(docRef, {
  //         paid_coll: parseInt(dcpm),
  //         payment_method: paymentMethod,
  //         penalty: false,
  //         pending_coll: parseInt(0),
  //       }).then(() => {
  //         console.log(`Updated! ${date.date}`);
  //       });
  //     });
  //     getDoc(docRef2).then((doc) => {
  //       updateDoc(docRef2, {
  //         defaulters: doc.data().defaulters - 1,
  //       }).then(() => {
  //         console.log("upd");
  //         updateCollection();
  //       });
  //     });
  //   }

  //   if (c === d) {
  //     //1000
  //     datesList.forEach((date, i) => {
  //       if (i === 0) {
  //         const docRef = doc(
  //           db,
  //           `profile/${sessionStorage.getItem(
  //             "mail"
  //           )}/months/${sessionStorage.getItem("month")}/dates/${
  //             datesList[0].date
  //           }/members/${sessionStorage.getItem("member")}`
  //         );
  //         updateDoc(docRef, {
  //           paid_coll: parseInt(dcpm),
  //           payment_method: paymentMethod,
  //           penalty: false,
  //           pending_coll: parseInt(0),
  //         }).then(() => {
  //           console.log("Updated ", date.date);
  //         });
  //       } else {
  //         const docRef = doc(
  //           db,
  //           `profile/${sessionStorage.getItem(
  //             "mail"
  //           )}/months/${sessionStorage.getItem("month")}/dates/${
  //             date.date
  //           }/members/${sessionStorage.getItem("member")}`
  //         );
  //         updateDoc(docRef, {
  //           paid_coll: parseInt(0),
  //           payment_method: paymentMethod,
  //           penalty: true,
  //           pending_coll: parseInt(d),
  //         }).then(() => {
  //           console.log("Updated ", date.date);
  //           updateCollection();
  //         });
  //       }
  //     });
  //   }

  //   if (c < d) {
  //     const docRef = doc(
  //       db,
  //       `profile/${sessionStorage.getItem(
  //         "mail"
  //       )}/months/${sessionStorage.getItem("month")}/dates/${
  //         datesList[0].date
  //       }/members/${sessionStorage.getItem("member")}`
  //     );
  //     updateDoc(docRef, {
  //       paid_coll: c,
  //       pending: d - c,
  //       penalty: true,
  //       payment_method: paymentMethod,
  //     }).then(() => {
  //       console.log("updated1! ", datesList[0].date);
  //       updateCollection();
  //     });
  //   }

  //   if (c > d && c < d * datesList.length) {
  //     // 2800
  //     // 2800
  //     // var diff = c;
  //     // datesList.forEach((date, i) => {
  //     //   if (i === 0) {
  //     //     const docRef = doc(
  //     //       db,
  //     //       `profile/${sessionStorage.getItem(
  //     //         "mail"
  //     //       )}/months/${sessionStorage.getItem("month")}/dates/${
  //     //         datesList[0].date
  //     //       }/members/${sessionStorage.getItem("member")}`
  //     //     );
  //     //     updateDoc(docRef, {
  //     //       paid_coll: parseInt(dcpm),
  //     //       paymentMethod: paymentMethod,
  //     //       penalty: false,
  //     //       pending_coll: parseInt(0),
  //     //     }).then(() => {
  //     //       console.log("Updated ", date.date);
  //     //       diff -= d; // 2800-1000 = 1800
  //     //       console.log(diff);
  //     //     });
  //     //   } else {
  //     //     if (diff > 0 && diff > d) {
  //     //       const docRef = doc(
  //     //         db,
  //     //         `profile/${sessionStorage.getItem(
  //     //           "mail"
  //     //         )}/months/${sessionStorage.getItem("month")}/dates/${
  //     //           date.date
  //     //         }/members/${sessionStorage.getItem("member")}`
  //     //       );
  //     //       updateDoc(docRef, {
  //     //         paid_coll: d,
  //     //         paymentMethod: paymentMethod,
  //     //         penalty: false,
  //     //         pending_coll: parseInt(0),
  //     //       }).then(() => {
  //     //         console.log("Updated", date.date);
  //     //         diff -= d; //1800-1000 = 800
  //     //         console.log(diff);
  //     //       });
  //     //     } else if (diff > 0 && diff < d) {
  //     //       const docRef = doc(
  //     //         db,
  //     //         `profile/${sessionStorage.getItem(
  //     //           "mail"
  //     //         )}/months/${sessionStorage.getItem("month")}/dates/${
  //     //           date.date
  //     //         }/members/${sessionStorage.getItem("member")}`
  //     //       );
  //     //       updateDoc(docRef, {
  //     //         paid_coll: diff,
  //     //         paymentMethod: paymentMethod,
  //     //         penalty: true,
  //     //         pending_coll: d - diff,
  //     //       }).then(() => {
  //     //         console.log("Updated", date.date);
  //     //         diff -= d; //1800-1000 = 800
  //     //         console.log(diff);
  //     //       });
  //     //     }
  //     //     if (diff < 0) {
  //     //       const docRef = doc(
  //     //         db,
  //     //         `profile/${sessionStorage.getItem(
  //     //           "mail"
  //     //         )}/months/${sessionStorage.getItem("month")}/dates/${
  //     //           date.date
  //     //         }/members/${sessionStorage.getItem("member")}`
  //     //       );
  //     //       updateDoc(docRef, {
  //     //         paid_coll: parseInt(0),
  //     //         paymentMethod: paymentMethod,
  //     //         penalty: true,
  //     //         pending_coll: parseInt(d),
  //     //       }).then(() => {
  //     //         console.log("Updated ", date.date);
  //     //       });
  //     //     }
  //     //   }
  //     // });
  //     var diff = parseInt(col); //2800-1000 = 1800
  //     console.log("OUTTER DIFF: ", diff);
  //     const docRef = doc(
  //       db,
  //       `profile/${sessionStorage.getItem(
  //         "mail"
  //       )}/months/${sessionStorage.getItem("month")}/dates/${
  //         datesList[0].date
  //       }/members/${sessionStorage.getItem("member")}`
  //     );
  //     updateDoc(docRef, {
  //       paid_coll: parseInt(dcpm), // 1000 in 1st date
  //       penalty: false,
  //       pending: 0,
  //       payment_method: paymentMethod,
  //     }).then(() => {
  //       console.log("1000 saved in first date");
  //       diff -= parseInt(dcpm);
  //       for (var i = 1; i < datesList.length; i++) {
  //         if (diff > 0) {
  //           console.log(diff);
  //           if (diff > parseInt(dcpm)) {
  //             diff -= parseInt(dcpm);
  //             console.log("Calling if");
  //             const docRef = doc(
  //               db,
  //               `profile/${sessionStorage.getItem(
  //                 "mail"
  //               )}/months/${sessionStorage.getItem("month")}/dates/${
  //                 datesList[i].date
  //               }/members/${sessionStorage.getItem("member")}`
  //             );
  //             updateDoc(docRef, {
  //               paid_coll: parseInt(dcpm),
  //               penalty: false,
  //               pending: 0,
  //               payment_method: paymentMethod,
  //             }).then(() => {
  //               console.log("Diff: ", diff);
  //               diff -= parseInt(dcpm);
  //               console.log("Updated1 ");
  //             });
  //           } else if (diff <= parseInt(dcpm) && diff > 0) {
  //             //800
  //             console.log("Diff > 0 && diff<dcpm");
  //             const docRef = doc(
  //               db,
  //               `profile/${sessionStorage.getItem(
  //                 "mail"
  //               )}/months/${sessionStorage.getItem("month")}/dates/${
  //                 datesList[i].date
  //               }/members/${sessionStorage.getItem("member")}`
  //             );
  //             updateDoc(docRef, {
  //               paid_coll: Math.abs(diff),
  //               pending: parseInt(dcpm) - Math.abs(diff),
  //               penalty: true,
  //               payment_method: paymentMethod,
  //             }).then(() => {});
  //             diff -= parseInt(dcpm);
  //             console.log("Updated2 ");
  //             console.log("Diff: ", diff);
  //           } else {
  //             console.log("Diff < 0");
  //           }
  //         } else {
  //           console.log("Diff < 0");
  //           const docRef = doc(
  //             db,
  //             `profile/${sessionStorage.getItem(
  //               "mail"
  //             )}/months/${sessionStorage.getItem("month")}/dates/${
  //               datesList[i].date
  //             }/members/${sessionStorage.getItem("member")}`
  //           );
  //           updateDoc(docRef, {
  //             paid_coll: 0,
  //             pending: parseInt(dcpm),
  //             penalty: true,
  //             payment_method: paymentMethod,
  //           }).then(() => {
  //             console.log("updated3 ");
  //           });
  //         }
  //       }
  //     });
  //   }
  //   getDoc(docRef2).then((doc) => {
  //     updateDoc(docRef2, {
  //       collections: doc.data().collections + parseInt(c),
  //     }).then(() => {
  //       console.log("upd");
  //     });
  //   });

  //   const docRef1 = doc(
  //     db,
  //     `profile/${sessionStorage.getItem(
  //       "mail"
  //     )}/months/${sessionStorage.getItem(
  //       "month"
  //     )}/members/${sessionStorage.getItem("member")}`
  //   );
  //   updateDoc(docRef1, {
  //     paid_coll: parseInt(c),
  //   }).then(() => {
  //     updateCollection();
  //   });
  //   // 2800
  //   // 2800-1000 =1800
  //   // 1800-1000 = 800
  //   // if(800 === dcpm)
  // };

  const handleAdd = () => {
    notifyCollection();
    let arr = datesList.slice(
      numbersList.indexOf(parseInt(col)),
      numbersList.length
    );
    for (var i = 0; i < numbersList.indexOf(parseInt(col)); i++) {
      const docRef = doc(
        db,
        `profile/${sessionStorage.getItem(
          "mail"
        )}/months/${sessionStorage.getItem("month")}/dates/${
          datesList[i].date
        }/members/${sessionStorage.getItem("member")}`
      );
      updateDoc(docRef, {
        paid_coll: parseInt(dcpm),
        payment_method: paymentMethod,
        pending_coll: parseInt(0),
      });
    }
    for (var i = 0; i < arr.length; i++) {
      const docRef = doc(
        db,
        `profile/${sessionStorage.getItem(
          "mail"
        )}/months/${sessionStorage.getItem("month")}/dates/${
          arr[i].date
        }/members/${sessionStorage.getItem("member")}`
      );
      getDoc(docRef).then((d) => {
        updateDoc(docRef, {
          paid_coll: parseInt(0),
          payment_method: paymentMethod,
          pending_coll: parseInt(dcpm),
        });
      });
    }
    const docRef1 = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "month"
      )}/members/${sessionStorage.getItem("member")}`
    );
    updateDoc(docRef1, {
      paid_coll: parseInt(col),
      paymentMethod: paymentMethod,
    }).finally(() => {
      updateCollection();
      setOpenAddDialog(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    let actualCount = 0;
    const colRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}`
    );
    let v1 = 0;
    const colRef1 = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("selected_month")}/dates`
    );
    getDocs(colRef1)
      .then((dates) => {
        dates.forEach((date) => {
          const colRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("selected_month")}/dates/${
              date.id
            }/members`
          );
          getDocs(colRef)
            .then((members) => {
              members.forEach((member) => {
                if (member.data().type === "visitor") {
                  v1 += 1;
                  actualCount += member.data().paid_coll;
                }
              });
            })
            .finally(() => {
              setVisitorCount(v1);
            });
        });
      })
      .finally(() => {
        onSnapshot(colRef, (snap) => {
          let arr = [];
          let a1 = 0;
          let e1 = 0;
          let i1 = 0;
          let d1 = 0;
          const collectionRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("month")}/members`
          );
          // let actualCount = 0;
          let defaulterCount = 0;
          const colRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("month")}/dates`
          );
          getDocs(colRef)
            .then((dates) => {
              dates.forEach((date) => {
                const colRef1 = collection(
                  db,
                  `profile/${sessionStorage.getItem(
                    "mail"
                  )}/months/${sessionStorage.getItem("month")}/dates/${
                    date.id
                  }/expenses`
                );
                const colRef2 = collection(
                  db,
                  `profile/${sessionStorage.getItem(
                    "mail"
                  )}/months/${sessionStorage.getItem("month")}/dates/${
                    date.id
                  }/incomes`
                );
                getDocs(colRef1)
                  .then((expenses) => {
                    expenses.forEach((expense) => {
                      e1 += parseInt(expense.data().expenseAmount);
                    });
                  })
                  .finally(() => {
                    setExpenses(e1);
                  });
                getDocs(colRef2).then((income) => {
                  income.forEach((i) => {
                    i1 += parseInt(i.data().amount);
                  });
                });
              });
            })
            .finally(() => {
              getDocs(collectionRef).then((members) => {
                let p = 0;
                let pp = 0;
                members.forEach((doc) => {
                  if (doc.data().paid_coll !== undefined) {
                    actualCount += doc.data().paid_coll;
                  }
                  if (
                    doc.data().paid_coll < doc.data().amount ||
                    doc.data().paid_coll === undefined ||
                    doc.data().paid_penalty < doc.data().expected_penalty
                  ) {
                    defaulterCount += 1;
                  }
                  if (doc.data().expected_penalty !== undefined) {
                    p += doc.data().expected_penalty;
                  }
                  if (doc.data().paid_penalty !== undefined) {
                    pp += doc.data().paid_penalty;
                  }
                });
                arr.push({
                  ...snap.data(),
                  actualCount: actualCount,
                  defaulterCount: defaulterCount,
                  expenseAmount: parseInt(expenses),
                  income: i1,
                  expected_penalty: p,
                  paid_penalty: pp,
                  visitorCount: v1,
                });
                a1 = actualCount;
                d1 = defaulterCount;
                setSummary(arr);
                // setLastUpdated(serverTimestamp().toDate());
                var time = new Date().toLocaleString();
                setLastUpdated(time.toString());
                setLoading(false);
              });
            });
        });
      });
  }, [db]);

  useEffect(() => {
    const collectionRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}/members`
    );
    onSnapshot(collectionRef, (snap) => {
      let arr = [];
      // let actualCount = 0;
      // let defaulterCount = 0;
      snap.forEach((doc) => {
        arr.push({ ...doc.data(), id: doc.id });
        // if (doc.data().paid_coll !== undefined) {
        //   actualCount += doc.data().paid_coll;
        // }
        // if (
        //   doc.data().paid_coll < sessionStorage.getItem("col") ||
        //   doc.data().paid_coll === undefined
        // ) {
        //   console.log(collections);
        //   defaulterCount += 1;
        // }
      });
      // console.log(actualCount);
      // setDefaulterCount(defaulterCount);
      // setActual(actualCount);
      setMembersList(arr);
    });
  }, [db]);

  const handleAddPenalty = () => {
    notifyPenaltyAdd();
    let inc = 0;
    defList.forEach((def) => {
      const docRef = doc(
        db,
        `profile/${sessionStorage.getItem(
          "mail"
        )}/months/${sessionStorage.getItem("month")}/members/${def.member_name}`
      );
      updateDoc(docRef, {
        expected_penalty: parseInt(dppm),
        paid_penalty: 0,
      })
        .then(() => {
          inc += 1;
        })
        .finally(() => {
          if (inc === defList.length - 1) {
            updatePenaltyAdd();
            setOpen(false);
          }
        });
    });
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}/dates`
    );
    onSnapshot(colRef, (snap) => {
      snap.forEach((date) => {
        defList.forEach((def) => {
          const docRef = doc(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("month")}/dates/${
              date.id
            }/members/${def.member_name}`
          );
          updateDoc(docRef, {
            expected_penalty: parseInt(dppm),
            paid_penalty: 0,
          });
        });
      });
    });
  };

  const handlePenaltyDelete = () => {
    notifyPenaltyDelete();
    const docRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem(
        "month"
      )}/members/${sessionStorage.getItem("member")}`
    );
    updateDoc(docRef, {
      expected_penalty: deleteField(),
      paid_penalty: deleteField(),
      penalty_payment_method: deleteField(),
    }).then(() => {
      const colRef = collection(
        db,
        `profile/${sessionStorage.getItem(
          "mail"
        )}/months/${sessionStorage.getItem("month")}/dates`
      );
      getDocs(colRef)
        .then((col) => {
          col.docs.forEach((doc1) => {
            const docRef1 = doc(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${sessionStorage.getItem("month")}/dates/${
                doc1.id
              }/members/${sessionStorage.getItem("member")}`
            );
            updateDoc(docRef1, {
              expected_penalty: deleteField(),
              paid_penalty: deleteField(),
              penalty_payment_method: deleteField(),
            });
          });
        })
        .finally(() => {
          setOpenDeletePenaltyDialog(false);
          updatePenaltyDelete();
        });
    });
  };

  const notifyCollection = () => {
    toastId.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Adding Collection...
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };

  const updateCollection = () =>
    toast.update(toastId.current, {
      render: "Collection Added Successfully!",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });

  const notifyPenaltyDelete = () => {
    toastId.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Please wait deleting penalty
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };

  const updatePenaltyDelete = () => {
    toast.update(toastId.current, {
      render: "Penalty Deleted Successfully!",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });
  };
  const notifyPenaltyAdd = () => {
    toastId.current = toast.warn(
      () => (
        <div>
          <RotatingLines width="20" strokeWidth="5" strokeColor={"black"} />
          &nbsp;&nbsp;Adding Penalty for defaulters
        </div>
      ),
      { autoClose: false, icon: false }
    );
  };

  const updatePenaltyAdd = () => {
    toast.update(toastId.current, {
      render: "Penalty Added Successfully!",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      icon: true,
      className: "rotateY animated",
    });
  };

  const fetchVisitors = (month) => {
    if (month !== "") {
      const colRef = collection(
        db,
        `profile/${sessionStorage.getItem("mail")}/months/${month}/dates`
      );
      // const q = query(colRef, orderBy("timestamp", "asc"));
      onSnapshot(colRef, (dates) => {
        let arr = [];
        dates.forEach((date) => {
          const colRef = collection(
            db,
            `profile/${sessionStorage.getItem("mail")}/months/${month}/dates/${
              date.id
            }/members`
          );
          getDocs(colRef)
            .then((members) => {
              members.forEach((member) => {
                if (member.data().type === "visitor") {
                  arr.push({ ...member.data(), date: date.id });
                }
              });
            })
            .finally(() => {
              setVisitorsList(arr);
              setOpenVisitorsDialog(true);
            });
        });
      });
    }
  };

  return (
    <>
      <NavBar />
      <section id="date" className="row">
        <div className="col-12 col-lg-10 col-md-8 mx-auto text-centber">
          <div className="d-flex justify-content-between col-lg-12 border-bottom">
            <legend className="mt-3 col-5 text-center">
              Month: {sessionStorage.getItem("month")}
            </legend>
            <button
              disabled={datesList.length >= 2 ? false : true}
              className="btn btn-outline-orange m-3"
              onClick={() => setOpen(true)}
            >
              Demand Penalty
            </button>
          </div>
          <div
            style={{ border: "2px solid", overflowX: "auto" }}
            className="col-12 col-lg-12 col-md-8 text-center rounded p-2 mt-2"
          >
            {!loading ? (
              <>
                <legend style={{ color: "Highlight" }} className="p-2">
                  Summary for {sessionStorage.getItem("month")}
                </legend>
                <table className="table table-bordered table-responsive-sm table-hover">
                  <thead>
                    <tr>
                      <th>C/F Amount</th>
                      <th>Exp. Collection</th>
                      <th>Actual Collection</th>
                      <th>Exp. Penalty</th>
                      <th>Paid Penalty</th>
                      <th>Members</th>
                      <th>Expenses</th>
                      <th>Income</th>
                      <th style={{ cursor: "pointer" }}>
                        <Link
                          to={{
                            pathname: `/dashboard/visitors/${sessionStorage.getItem(
                              "month"
                            )}`,
                          }}
                        >
                          <u style={{ color: "black" }}>Visitors</u>
                        </Link>
                      </th>
                      <th>
                        <Link
                          style={{ color: "black" }}
                          to={{
                            pathname: `/dashboard/defaulters/${sessionStorage.getItem(
                              "mail"
                            )}/${sessionStorage.getItem("month")}`,
                          }}
                        >
                          <u>Defaulters</u>
                        </Link>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {summary.map((items) => (
                      <tr key={1}>
                        <td>
                          {items.carry_forward === undefined
                            ? 0
                            : items.carry_forward}
                        </td>
                        <td>
                          {collections * membersList.length === NaN
                            ? 0
                            : collections * membersList.length +
                              items.visitorCount * dcpm}
                        </td>
                        <td>{items.actualCount}</td>
                        <td>{items.expected_penalty}</td>
                        <td>{items.paid_penalty}</td>
                        <td>{membersList.length}</td>
                        <td>{items.expenses}</td>
                        <td>{items.income}</td>
                        <td>{items.visitorCount}</td>
                        <td>{items.defaulterCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="text-left">
                  Summary Last Updated: {lastUpdated}
                </div>
                <div>
                  <Link
                    style={{ color: "Highlight" }}
                    onClick={() => {
                      sessionStorage.setItem(
                        "selected_month",
                        sessionStorage.getItem("month")
                      );
                    }}
                    to={{ pathname: `/dashboard/months/dates` }}
                  >
                    Go to Dates Page
                  </Link>
                </div>
              </>
            ) : (
              <>
                <SpinnerCircular
                  size={30}
                  thickness={150}
                  speed={130}
                  color="rgba(0,0,0,1)"
                  secondaryColor="rgba(255,255,255, 1)"
                />
              </>
            )}
          </div>
          <br />
          <div
            className="border rounded mx-auto text-center"
            style={{ overflowX: "auto" }}
          >
            <table className="table table-responsive-sm table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Member Name</th>
                  <th>Expected Collection</th>
                  <th>Paid Collection</th>
                  <th>Expected Penalty</th>
                  <th>Paid Penalty</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {membersList.map((member, i) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    <td
                      style={{
                        color:
                          member.paid_coll < member.amount ||
                          member.paid_coll === undefined
                            ? "red"
                            : "black",
                      }}
                    >
                      {member.member_name}
                    </td>
                    <td>{member.amount > 0 ? member.amount : 0}</td>
                    <td>{member.paid_coll > 0 ? member.paid_coll : 0}</td>
                    <td>
                      {/* {member.paid_coll === member.amount ||
                      member.paid_penalty === undefined
                        ? 0
                        : dppm} */}
                      {member.expected_penalty === undefined
                        ? 0
                        : member.expected_penalty}
                    </td>
                    <td>
                      {member.paid_penalty === undefined
                        ? 0
                        : member.paid_penalty}
                    </td>
                    <td>
                      <div className="btn-group">
                        <Tooltip
                          title="Add Collection"
                          arrow
                          followCursor
                          placement="left"
                        >
                          <button
                            disabled={member.paid_coll > 0 ? true : false}
                            type={"button"}
                            onClick={() => {
                              sessionStorage.setItem(
                                "member",
                                member.member_name
                              );
                              setOpenAddDialog(true);
                            }}
                            className="btn btn-outline-orange"
                          >
                            +
                          </button>
                        </Tooltip>
                        <Tooltip
                          title="Delete Penalty"
                          arrow
                          followCursor
                          placement="right"
                        >
                          <button
                            disabled={
                              member.expected_penalty === undefined
                                ? true
                                : false
                            }
                            className="btn btn-outline-orange"
                            onClick={() => {
                              sessionStorage.setItem(
                                "member",
                                member.member_name
                              );
                              setOpenDeletePenaltyDialog(true);
                            }}
                          >
                            <Delete />
                          </button>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <Footer />
      {/* ADD COLLECTION MODAL */}
      {openAddDialog && (
        <Dialog
          open={openAddDialog}
          onClose={() => setOpenAddDialog(false)}
          // fullWidth
          maxWidth="sm"
        >
          <div class="modal-content">
            <div class="modal-body">
              <p>Add Collection</p>
              <label htmlFor="coll">Enter Collection Amount</label>
              {/* <input
                type={"number"}
                min={0}
                onChange={(e) => setCollection(e.target.value)}
                id="coll"
                className="form-control"
              /> */}
              <select
                name="coll"
                id="coll"
                className="form-control"
                onChange={(e) => setCollection(e.target.value)}
              >
                <option value="-1">Select Amount</option>
                {numbersList.length > 0 &&
                  numbersList.map((number) => (
                    <option value={number}>{number}</option>
                  ))}
              </select>
              <br />
              <label htmlFor="payment">Select Payment Method</label>
              <div className="d-flex">
                <div class="form-check m-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    defaultChecked
                    onClick={() => setPaymentMethod("Cash")}
                  />
                  <label
                    style={{ cursor: "pointer" }}
                    class="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Cash
                  </label>
                </div>
                <div class="form-check m-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    onClick={() => setPaymentMethod("Online")}
                  />
                  <label
                    style={{ cursor: "pointer" }}
                    class="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Online
                  </label>
                </div>
                <div class="form-check m-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault3"
                    onClick={() => setPaymentMethod("Other")}
                  />
                  <label
                    style={{ cursor: "pointer" }}
                    class="form-check-label"
                    htmlFor="flexRadioDefault3"
                  >
                    Other
                  </label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-danger"
                onClick={() => {
                  setOpenAddDialog(false);
                  sessionStorage.setItem("member", "none");
                }}
              >
                Cancel
              </button>
              <button onClick={handleAdd} class="btn btn-primary">
                Add Collection
              </button>
            </div>
          </div>
        </Dialog>
      )}

      {/* ADD PENALTY DIALOG */}
      {open && (
        <Dialog
          maxWidth="md"
          fullWidth
          open={open}
          onClose={() => setOpen(false)}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="border-bottom">
                <h4>Add Penalty</h4>
              </div>
              <span style={{ color: "red" }}>
                Penalty of {dppm} will be charged to the below defaulters for{" "}
                {sessionStorage.getItem("month")}
              </span>
              <table className="table mt-1 table-bordered table-responsive-sm table-responsive-md table-hover table-striped">
                <thead>
                  <td>Sr. No.</td>
                  <td>Defaulter Name</td>
                  <td>Pending Amont</td>
                </thead>
                <tbody>
                  {defList.map((def, i) => (
                    <tr key={i + 1}>
                      <td>{i + 1}</td>
                      <td>{def.member_name}</td>
                      <td>
                        {def.paid_coll === undefined
                          ? def.amount
                          : def.amount - def.paid_coll}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                <button
                  onClick={() => setOpen(false)}
                  className="btn btn-orange m-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddPenalty}
                  className="btn btn-orange m-2"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      )}

      {/* VISITORS DIALOG */}
      {openVisitorsDialog && (
        <Dialog
          open={openVisitorsDialog}
          onClose={() => setOpenVisitorsDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <div className="modal-header">
            <h5>Visitors for {month}</h5>
            <div className="justify-content-end">
              <i
                onClick={() => setOpenVisitorsDialog(false)}
                className="btn-danger btn bi bi-x-lg"
              ></i>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-body">
              {visitorsList.length > 0 ? (
                <table className="table table-responsive-sm table-hover table-striped text-center">
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Visitor Name</th>
                      <th>Invitee</th>
                      <th>Date of Visit</th>
                      <th>Paid Collection</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visitorsList.map((visitors, i) => (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{visitors.visitor_name}</td>
                        <td>{visitors.member}</td>
                        <td>
                          {visitors.paid_coll === 0 ? (
                            <Link
                              to={{ pathname: "/dashboard/months/date" }}
                              onClick={() => {
                                sessionStorage.setItem("selected_month", month);
                                sessionStorage.setItem("date", visitors.date);
                              }}
                            >
                              {visitors.date}
                            </Link>
                          ) : (
                            visitors.date
                          )}
                        </td>
                        <td>{visitors.paid_coll}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center">
                  <h4>No Visitors Added Yet!</h4>
                </div>
              )}
            </div>
          </div>
        </Dialog>
      )}

      {/* DELETE PENALTY DIALOG */}
      {openDeletePenaltyDialog && (
        <Dialog
          open={openDeletePenaltyDialog}
          onClose={() => setOpenDeletePenaltyDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <div className="modal-header">
            Delete Penalty for {sessionStorage.getItem("member")}
          </div>
          <div className="modal-body">
            <p>
              Are you sure you want to Delete Penalty for{" "}
              {sessionStorage.getItem("member")}?
            </p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => setOpenDeletePenaltyDialog(false)}
              className="btn btn-outline-danger"
            >
              No
            </button>
            <button
              onClick={() => handlePenaltyDelete()}
              className="btn btn-outline-success"
            >
              Yes
            </button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Month;
