import React from "react";

const Footer = () => {
  return (
    <footer className="footer mt-auto">
      <section id="contact" className="row">
        <div className="col-10 col-lg-8 mx-auto">
          <h3>Have a question?</h3>
          <div className="row">
            <a
              type="button"
              role="button"
              className="btn btn-orange col-10 col-sm-3 mx-auto"
              href="https://fairshare.tech/contact-details-of-software-company-pune/"
              target="_blank"
            >
              <i className="bi bi-send-fill"></i> Contact Us
            </a>
          </div>
          <br />
        </div>
      </section>
      <a
        href="https://fairshare.tech"
        routerlinkactive="active"
        target="_blank"
        className="mt-4"
      >
        Powered by https://fairshare.tech
      </a>
    </footer>
  );
};

export default Footer;
