import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
// import ReactHtmlTableToExcel from "react-html-table-to-excel";
// import { RotatingLines } from "react-loader-spinner";
// import { toast } from "react-toastify";
import { SpinnerCircular } from "spinners-react";
// import { TableExport } from "tableexport";
import * as XLSX from "xlsx";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { db } from "../firebase-config/firebase";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

const GenerateExcelData = () => {
  const [bfn, setBfn] = useState("");
  const [cf, setCF] = useState(0);
  const [datesList, setDatesList] = useState([]);
  const [expArr, setExpArr] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const [penaltyCol, setPenaltyCol] = useState(0);
  const [dcpm, setDcpm] = useState(0);
  const [balance, setBalance] = useState();
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [totalOnline, setTotalOnline] = useState(0);
  const [totalOther, setTotalOther] = useState(0);
  let [mainArr, setMainArr] = useState([]);
  const [summary, setSummary] = useState([]);
  const [visitorCount, setVisitorCount] = useState();
  const [collections, setCollections] = useState();
  const [expenses, setExpenses] = useState();
  const [memberList, setMembersList] = useState([]);
  const [addIncomes, setAddIncomes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateFlag, setDateFlag] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const collectionRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}/members`
    );
    onSnapshot(collectionRef, (snap) => {
      let arr = [];
      snap.forEach((doc) => {
        arr.push({ ...doc.data(), id: doc.id });
      });
      setMembersList(arr);
    });
    const docRef1 = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}`
    );
    onSnapshot(docRef1, (snap) => {
      setCollections(snap.data().amount);
    });
    const colRef = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}`
    );
    let v1 = 0;
    const colRef1 = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}/dates`
    );
    getDocs(colRef1)
      .then((dates) => {
        dates.forEach((date) => {
          const colRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("month")}/dates/${
              date.id
            }/members`
          );
          getDocs(colRef)
            .then((members) => {
              members.forEach((member) => {
                if (member.data().type === "visitor") {
                  v1 += 1;
                }
              });
            })
            .finally(() => {
              setVisitorCount(v1);
            });
        });
      })
      .finally(() => {
        onSnapshot(colRef, (snap) => {
          let arr = [];
          let a1 = 0;
          let e1 = 0;
          let d1 = 0;
          const collectionRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("month")}/members`
          );
          let actualCount = 0;
          let defaulterCount = 0;
          const colRef = collection(
            db,
            `profile/${sessionStorage.getItem(
              "mail"
            )}/months/${sessionStorage.getItem("month")}/dates`
          );
          getDocs(colRef)
            .then((dates) => {
              dates.forEach((date) => {
                const colRef1 = collection(
                  db,
                  `profile/${sessionStorage.getItem(
                    "mail"
                  )}/months/${sessionStorage.getItem("month")}/dates/${
                    date.id
                  }/expenses`
                );
                getDocs(colRef1)
                  .then((expenses) => {
                    expenses.forEach((expense) => {
                      e1 += parseInt(expense.data().expenseAmount);
                    });
                  })
                  .finally(() => {
                    setExpenses(e1);
                  });
                const colRef = collection(
                  db,
                  `profile/${sessionStorage.getItem(
                    "mail"
                  )}/months/${sessionStorage.getItem("month")}/dates/${
                    date.id
                  }/members`
                );
                getDocs(colRef).then((members) => {
                  members.docs.forEach((member) => {
                    if (
                      member.data().type === "visitor" &&
                      member.data().paid_coll === 0
                    ) {
                      defaulterCount += 1;
                    }
                  });
                });
              });
            })
            .finally(() => {
              getDocs(collectionRef).then((members) => {
                let p = 0;
                let pp = 0;
                members.forEach((doc) => {
                  if (doc.data().paid_coll !== undefined) {
                    actualCount += doc.data().paid_coll;
                  }
                  if (
                    doc.data().paid_coll < doc.data().amount ||
                    doc.data().paid_coll === undefined
                  ) {
                    defaulterCount += 1;
                  }
                  if (doc.data().expected_penalty !== undefined) {
                    p += doc.data().expected_penalty;
                  }
                  if (doc.data().paid_penalty !== undefined) {
                    pp += doc.data().paid_penalty;
                  }
                });
                arr.push({
                  ...snap.data(),
                  actualCount: actualCount,
                  defaulterCount: defaulterCount,
                  expenseAmount: parseInt(expenses),
                  expected_penalty: p,
                  paid_penalty: pp,
                });
                a1 = actualCount;
                d1 = defaulterCount;
                setSummary(arr);
              });
            });
        });
      });
  }, []);

  const fetchData = () => {
    setLoading(true);
    let bal = 0;
    const docRef = doc(db, `profile/${sessionStorage.getItem("mail")}`);
    getDoc(docRef).then((d) => {
      setBfn(d.data().business_forum_name);
      setDcpm(d.data().def_collection_per_meeting);
    });
    const docRef1 = doc(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}`
    );
    getDoc(docRef1).then((d) => {
      setCF(d.data().carry_forward);
      bal += d.data().carry_forward;
    });
    const colRef = collection(
      db,
      `profile/${sessionStorage.getItem(
        "mail"
      )}/months/${sessionStorage.getItem("month")}/dates`
    );
    const que = query(colRef, orderBy("add_timestamp", "asc"));
    onSnapshot(que, (snap) => {
      let m = [];
      let arr = [];
      let eArr = [];
      let iArr = [];
      let memCount = 0;
      let itemBal = bal;
      let ac = 0;
      let vArr = [];
      let pp = 0;
      let totE = 0;
      let totI = 0;
      let cash = 0;
      let onl = 0;
      let oth = 0;
      let el = 0;
      let vl = 0;
      let ml = 0;
      let actV = 0;
      let i1 = 0;
      setBalance(itemBal);
      if (snap.docs.length === 0) {
        setDateFlag(true);
        setLoading(false);
      }
      snap.docs.forEach((docs, i) => {
        //INCOME
        const colRef4 = collection(
          db,
          `profile/${sessionStorage.getItem(
            "mail"
          )}/months/${sessionStorage.getItem("month")}/dates/${docs.id}/incomes`
        );
        getDocs(colRef4)
          .then((incomes) => {
            if (incomes.docs.length > 0) {
              let i = 0;
              incomes.forEach((income) => {
                if (income.data().paymentMethod === undefined) {
                  oth += income.data().amount;
                }
                if (income.data().paymentMethod === "Cash") {
                  cash += income.data().amount;
                } else if (income.data().paymentMethod === "Online") {
                  onl += income.data().amount;
                } else if (income.data().paymentMethod === "Other") {
                  oth += income.data().amount;
                }
                m.push({
                  id: income.data().description,
                  income: income.data().amount,
                  date: docs.id,
                  expense: 0,
                  i: i,
                });
                ml++;
                iArr.push({
                  id: income.data().description,
                  income: income.data().amount,
                  date: docs.id,
                  expense: 0,
                });
              });
              setTotalCash(totalCash + cash);
              setTotalOnline(totalOnline + onl);
              setTotalOther(totalOther + oth);
              setAddIncomes(iArr);
            }
          })
          .finally(() => {
            //EXPENSES COLLECTION
            const colRef = collection(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${sessionStorage.getItem("month")}/dates/${
                docs.id
              }/expenses`
            );
            getDocs(colRef)
              .then((expenses) => {
                if (expenses.docs.length > 0) {
                  let e1 = 0;
                  expenses.forEach((expense) => {
                    eArr.push({
                      id: expense.data().expenseHead,
                      expenseAmount: expense.data().expenseAmount,
                      date: docs.id,
                    });
                    m.push({
                      id: expense.data().expenseHead,
                      expense: expense.data().expenseAmount,
                      date: docs.id,
                      income: 0,
                      i: i,
                    });
                    ml++;
                    e1++;
                  });
                  el = e1;
                  setExpArr(eArr);
                }
              })
              .finally(() => {
                m.forEach((i) => {
                  totI += i.income;
                  totE += i.expense;
                });
                setTotalIncome(totI);
                setTotalExpenses(totE);
                setDatesList(arr);
                setTotalCash(cash);
                setTotalOnline(onl);
                setTotalOther(oth);
              });
            //VISITORS COLLECTION
            const colRef1 = collection(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${sessionStorage.getItem("month")}/dates/${
                docs.id
              }/members`
            );
            getDocs(colRef1).then((visitors) => {
              let v = 0;
              let v_length = 0;
              visitors.forEach((visitor) => {
                if (visitor.data().type === "visitor") {
                  v += visitor.data().paid_coll;
                  v_length += 1;
                  if (visitor.data().paymentMethod === "Cash") {
                    cash += visitor.data().paid_coll;
                  } else if (visitor.data().paymentMethod === "Online") {
                    onl += visitor.data().paid_coll;
                  } else if (visitor.data().paymentMethod === "Other") {
                    oth += visitor.data().paid_coll;
                  }
                }
              });
              if (v_length > 0) {
                vArr.push({
                  id: "Visitors x" + v_length,
                  date: docs.id,
                  visitorsAmount: v,
                });
                m.push({
                  id: "Visitors x" + v_length,
                  date: docs.id,
                  income: v,
                  expense: 0,
                  i: i,
                });
                ml++;
                setTotalCash(totalCash + cash);
                setTotalOnline(totalOnline + onl);
                setTotalOther(totalOther + oth);
                vl++;
                setVisitors(vArr);
              }
            });
            // ACTUAL COLLECTION
            const colRef2 = collection(
              db,
              `profile/${sessionStorage.getItem(
                "mail"
              )}/months/${sessionStorage.getItem("month")}/dates/${
                docs.id
              }/members`
            );
            getDocs(colRef2)
              .then((members) => {
                let actualCount = 0;
                memCount = members.docs.length;
                members.forEach((member) => {
                  if (
                    member.data().paid_coll !== undefined &&
                    (member.data().type === "member" ||
                      member.data().type === undefined)
                  ) {
                    actualCount += member.data().paid_coll;
                    ac = actualCount;
                  }
                  if (member.data().payment_method === "Cash") {
                    cash += member.data().paid_coll;
                  } else if (member.data().payment_method === "Online") {
                    onl += member.data().paid_coll;
                  } else if (member.data().payment_method === "Other") {
                    oth += member.data().paid_coll;
                  }
                });
              })
              .finally(() => {
                if (ac > 0) {
                  setTotalCash(totalCash + cash);
                  setTotalOnline(totalOnline + onl);
                  setTotalOther(totalOther + oth);
                }
                arr.push({ date: docs.data().date, actualCount: ac });
                m.push({
                  date: docs.data().date,
                  id: "Members Collection",
                  income: ac,
                  expense: 0,
                  i: i,
                });
                ml++;
                if (ml === m.length) {
                  console.log("Equal");
                  console.log(m);
                  setMainArr(m);
                  // setLoading(false);
                }
              });
          });
      });

      // PENALTY COLLECTION
      const colRef3 = collection(
        db,
        `profile/${sessionStorage.getItem(
          "mail"
        )}/months/${sessionStorage.getItem("month")}/members`
      );
      getDocs(colRef3).then((members) => {
        let pp = 0;
        members.forEach((member) => {
          if (member.data().paid_penalty > 0) {
            pp += member.data().paid_penalty;
            if (member.data().penalty_payment_method === "Cash") {
              cash += member.data().paid_penalty;
            } else if (member.data().penalty_payment_method === "Online") {
              onl += member.data().paid_penalty;
            } else if (member.data().penalty_payment_method === "Other") {
              oth += member.data().paid_penalty;
            } else {
              // console.log(first);
            }
          }
        });
        if (pp > 0) {
          setPenaltyCol(pp);
          setTotalCash(totalCash + cash);
          setTotalOnline(totalOnline + onl);
          setTotalOther(totalOther + oth);
        }
      });
    });
  };

  useEffect(() => {
    fetchData();
    setTimeout(() => {
      fetchData();
      setLoading(false);
      setShowButton(true);
    }, 2000);
  }, []);

  return (
    <>
      <NavBar />
      <section id="generateData" className="row">
        <div className="col-12 col-lg-10 mx-auto col-sm-8 col-md-6">
          {!loading ? (
            <>
              {dateFlag === false ? (
                <>
                  {showButton ? (
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn m-2 btn-success"
                        onClick={() => {
                          const table = document.getElementById("data");
                          htmlToImage
                            .toPng(table, {
                              backgroundColor: "white",
                              quality: 1,
                              width: 830,
                              height:
                                document.getElementById("nda")?.offsetHeight,
                              type: "image/png",
                            })
                            .then((url) => {
                              sessionStorage.setItem("PNG", url);
                              var img = new Image();
                              img.src = url;
                              img.onload = function () {
                                // const imgWidth = 200;
                                // const imgHeight = (image.height * image.width) / 813;
                                var w = img.width;
                                var actw = img.width;
                                var h = img.height;
                                var acth = img.height;
                                var pdf = new jsPDF("p", "mm", "a4", true);
                                var width = pdf.internal.pageSize.width;
                                var maxw = pdf.internal.pageSize.width;
                                var height = pdf.internal.pageSize.height;
                                var maxh = pdf.internal.pageSize.height;
                                var position = 0;
                                if (!maxw) maxw = width;
                                if (!maxh) maxh = height;
                                if (w > maxw) {
                                  w = maxw;
                                  h = Math.round((acth / actw) * maxw);
                                }
                                pdf.addImage(img, "PNG", 0, 0, w, h, "FAST");
                                var count = Math.ceil(h / maxh) - 1;
                                console.log("w: " + w);
                                console.log("h: " + h);
                                console.log("Count: " + count);
                                // var count = Math.ceil(h) / Math.ceil(maxh);
                                // count = Math.ceil(count);
                                for (var i = 1; i <= count; i++) {
                                  position = -maxh * i;
                                  pdf.addPage(img, "PNG", 0, 0, w, h);
                                  pdf.addImage(
                                    img,
                                    "PNG",
                                    0,
                                    position,
                                    w,
                                    h,
                                    "FAST"
                                  );
                                }

                                pdf.save("Balance Sheet.pdf");
                              };
                              // var pdf = new jsPDF("p", "mm", "a4");
                              // pdf.addImage(
                              //   url,
                              //   "PNG",
                              //   0,
                              //   0,
                              //   pdf.internal.pageSize.width,
                              //   pdf.internal.pageSize.height
                              // );
                              // pdf.save("Balance Sheet.pdf");
                            });
                        }}
                      >
                        Export to PDF
                      </button>
                      <button
                        className="btn m-2 btn-success"
                        onClick={() => {
                          const table = document.getElementById("data");
                          var file_name =
                            "Balance Sheet for " +
                            sessionStorage.getItem("month");
                          const ws = XLSX.utils.table_to_sheet(table);
                          const wb = XLSX.utils.book_new();
                          XLSX.utils.book_append_sheet(wb, ws, file_name);
                          // let buffer = XLSX.write(wb, {
                          //   bookType: "csv",
                          //   type: "buffer",
                          // });
                          XLSX.write(wb, { bookType: "csv", type: "binary" });

                          XLSX.writeFile(wb, file_name + ".csv");
                        }}
                      >
                        Export to CSV
                      </button>
                    </div>
                  ) : null}
                  <div className="m-3">
                    <table
                      id="data"
                      className="table table-responsive-sm table-hover table-striped bordered"
                    >
                      <tbody>
                        <tr>
                          <td colSpan={4} className="text-center">
                            INCOME - EXPENSE DATA OF {bfn} FOR{" "}
                            {sessionStorage.getItem("month")}
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <th>Date</th>
                          <th>Item Name</th>
                          <th>Income</th>
                          <th>Expense</th>
                        </tr>
                        {mainArr
                          .sort((a, b) => a.i < b.i)
                          .map((item, i) => (
                            <tr key={i + 1}>
                              <td>
                                {item.date === undefined
                                  ? datesList[0]?.date
                                  : item.date}
                              </td>
                              <td>{item.id}</td>
                              <td>{item.income}</td>
                              <td>{item.expense}</td>
                            </tr>
                          ))}
                        {penaltyCol > 0 ? (
                          <tr key={-1}>
                            <td>{sessionStorage.getItem("month")}</td>
                            <td>Penalty Collection</td>
                            <td>{penaltyCol}</td>
                            <td>{0}</td>
                          </tr>
                        ) : null}
                        <br />
                        <tr>
                          <td colSpan={4}>Overall Summary:</td>
                        </tr>
                        <tr>
                          <td>Carry Forward from Previous Month:</td>
                          <td>{cf}</td>
                        </tr>
                        <tr>
                          <td>Total Income:</td>
                          <td>
                            {mainArr.reduce(
                              (total, obj) => obj.income + total,
                              0
                            ) + penaltyCol}
                          </td>
                        </tr>
                        <tr>
                          <td>Income by Payment Methods</td>
                        </tr>
                        <tr>
                          <td>By Cash:</td>
                          <td>{totalCash}</td>
                        </tr>
                        <tr>
                          <td>By Online:</td>
                          <td>{totalOnline}</td>
                        </tr>
                        <tr>
                          <td>By Other:</td>
                          <td>{totalOther}</td>
                        </tr>
                        <tr>
                          <td>Total Expenses:</td>
                          <td>
                            {mainArr.reduce(
                              (total, obj) => obj.expense + total,
                              0
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Additional Income:</td>
                          <td>
                            {addIncomes.reduce(
                              (total, obj) => obj.income + total,
                              0
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Expected Collections(Members):</td>
                          <td>{collections * memberList.length}</td>
                        </tr>
                        <tr>
                          <td>Actual Collections(Members):</td>
                          <td>{summary[0]?.actualCount}</td>
                        </tr>
                        <tr>
                          <td>Expected Penalty(Members):</td>
                          <td>{summary[0]?.expected_penalty}</td>
                        </tr>
                        <tr>
                          <td>Paid Penalty(Members):</td>
                          <td>{summary[0]?.paid_penalty}</td>
                        </tr>
                        <tr>
                          <td>Expected Collections(Visitors):</td>
                          <td>{visitorCount * dcpm}</td>
                        </tr>
                        <tr>
                          <td>Actual Collections(Visitors):</td>
                          <td>
                            {visitors.reduce(
                              (total, obj) => obj.visitorsAmount + total,
                              0
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Members:</td>
                          <td>{memberList.length}</td>
                        </tr>
                        <tr>
                          <td>Defaulters:</td>
                          <td>{summary[0]?.defaulterCount}</td>
                        </tr>
                        <tr>
                          <td>Visitors:</td>
                          <td>{visitorCount}</td>
                        </tr>
                        <tr>
                          <td>OUTSTANDING AMOUNT:</td>
                          <td>
                            {collections * memberList.length +
                              summary[0]?.expected_penalty +
                              visitorCount * dcpm -
                              (summary[0]?.actualCount +
                                summary[0]?.paid_penalty +
                                visitors.reduce(
                                  (total, obj) => obj.visitorsAmount + total,
                                  0
                                ))}
                          </td>
                        </tr>
                        <tr>
                          <td>Carry Forward To Next Month:</td>
                          <td>
                            {mainArr.reduce(
                              (total, obj) => obj.income + total,
                              cf
                            ) -
                              // +
                              //   addIncomes.reduce(
                              //     (total, obj) => obj.income + total,
                              //     0
                              //   )
                              mainArr.reduce(
                                (total, obj) => obj.expense + total,
                                0
                              ) +
                              penaltyCol}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <h3 className="text-center">
                    Sorry, can't Generate Report!!
                  </h3>
                  <h3 className="text-center">Please add a date</h3>
                </>
              )}
            </>
          ) : (
            <div className="col-12 text-center">
              <SpinnerCircular
                size={50}
                thickness={150}
                speed={130}
                color="rgba(0,0,0,1)"
                secondaryColor="rgba(255,255,255, 1)"
              />
              <br />
              <h5 className="mt-3">
                <b>Generating Report...</b>
              </h5>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default GenerateExcelData;
